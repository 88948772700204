// useDocumentTitle.js
import { useRef, useEffect } from 'react';
import ReactGA from "react-ga4";

const useDocumentTitle = (title, icon, prevailOnUnmount = false) => {
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => () => {
    if (!prevailOnUnmount) {
      document.title = defaultTitle.current;
    }

    if (icon) {
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
        }
        if (icon.includes('https://')) {
            link.href = icon;
        } else {
            link.href = `https://app.gamably.com${icon}`;
        }
    }
  }, [])
}

export function changeDocumentTitle(title, icon) {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
    }
    if (!icon) {   
        link.href = `/icon-192x192.png`;
    } else {
        if (icon.includes('https://')) {
            link.href = icon;
        } else {
            link.href = `https://app.gamably.com${icon}`;
        }
    }
    if (!title ) {
        title = `Gamably | It's Your Game`;
    } 
    document.title = title;
    if (!window.location.host.includes('localhost:')) {
        ReactGA.send({
            hitType: 'pageview',
            page: window.location.pathname,
            title: title 
        })
    }
}

export default useDocumentTitle