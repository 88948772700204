import React, { useEffect } from 'react'
import './privacy-policy.styles.scss';
import UnauthHeader from '@/components/layout-unauth/unauth-head.component';
import Footer from '@/components/layout-unauth/footer/footer.component';
import { changeDocumentTitle } from '@/utils/useDocumentTitle';


const PrivacyPolicy = () => {

    useEffect(() => {
        changeDocumentTitle();
    }, [])

    return (
        <div className='privacy-policy'>
            <UnauthHeader />
            <div className='privacy-container'>
                <div className='g-space-2'></div>
                <div className='info-card g-card'>
                    <div className='card-title'>
                        Privacy Policy
                    </div>
                    <hr />
                    <div className='privacy-content'>
                        <p>For general guidlines for the use of our services, read our <a href="/terms">Terms of Use</a>.</p>
                        
                        <p>Gamably.com ("Gamably") takes the privacy of its users, especially teachers and students seriously. Among other things, we have committed to only collect student information that is needed for authorized education/school purposes, or as authorized by the parent/student, to not sell student personal information, to not use student information for behavioral targeting of advertisements to students and to not retain student personal information beyond the time period required to support the authorized educational/school purposes. </p>

                        <p>We are committed to creating a safe and secure environment for learners of all ages on our websites located at https://gamably.com, other websites, and online services (collectively, our “Website”). Visitors and users of the Website and participants in our gamification management platform are referred to individually as “User” and collectively as “Users”.</p>

                        <p>Our Privacy Policy describes what information we gather from our Users, how we use that information, and what we do to protect it. Your use of the Website and any information you provide on the Website are subject to the terms of this Privacy Policy, which is incorporated into and subject to our <a href="/terms" target="_blank">Terms of Use</a>. Any capitalized terms used but not defined within this Privacy Policy have the meaning given to them in our Terms of Use. By visiting or using our Website, you expressly consent to the information handling practices described in this Privacy Policy. If you have any questions or concerns, please contact Adam at <a href="mailto:adam@gamably.com" target="_blank">adam@gamably.com</a>.</p>

                        {/* <h4>Note: Gamably uses best practices to secure data, but by visiting or using our Website, users will indemnify, defend and hold Gamably harmless from any damages, costs, losses, fines, claims, demands, suits, actions or causes of action in law or in equity, in state or federal court, or before any administrative body that are caused by or arise out of any unauthorized access to Gamably's saved data by a third party.</h4> */}

                        <h3>Data Storage and Transfer</h3>
                        <p>All data saved by Gamably including email/password authentication data, Google SSO authentication data, and all data submitted or uploaded to Gamably is housed in the United States and is managed by Google's Firebase Cloud Storage. For more information on Firebase's Privacy Policies, visit <a href="https://firebase.google.com/support/privacy" target="_blank">this website</a>. For information regarding Firebase's handling of datatransfer including international datatransfer, visit <a href="https://firebase.google.com/support/privacy#international_data_transfers">this website</a>.</p>

                        <h3>Cookies</h3>
                        <p>Cookies are small data files that are commonly stored on your device when you use websites and on-line services. They are widely used to make websites work, or to work more efficiently, as well as to provide reporting information and assist with services or personalization. There are also other technologies that are similar to cookies, which may store small amount of data on your device ("local storage"). Gamably uses cookies only for the purpose of authenticating User accounts through the Firebase Database Authentication system, and this is only for Users who create an account using one of the methods described <a href="#userAccounts">below</a>.</p> 
                        
                        <p>If you wish to disable the use of Cookies, you may do so from your browser preferences menu of your browser software by either turning off Cookies or by using your browser’s privacy mode when using our services.</p>

                        <h3>Collection and Use of Your Information</h3>
                        <p>The sections below describe the ways Gamably collects and uses personal data, which refers to any information that identifies you as an individual (directly or indirectly). This includes things like the name, display name, email address, etc. from Users (“Personal Information”), and persistent cookies or IP addresses (“Persistent Identifiers”). For a specific list of the data we collect, <a href="#collectedData">click here</a>. We collectively refer to any of this data we collect from Users, along with any Personal Information and User-generated content as “User Data.”</p>

                        <p>Our collection, use, and disclosure of User Data are governed by our Privacy Policy and <a href="/terms" target="_blank">Terms of Use</a>, and where applicable, also by the provisions of the Family Educational Rights and Privacy Act (FERPA), the Children's Online Privacy Protection Act (COPPA), the General Data Protection Regulation (GDPR), and other applicable federal and state laws that relate to the collection of User Data, including California’s Student Online Personal Information Protection Act (SOPIPA) and similar state laws. <b>Gamably shall not use User Data for any purposes other than those authorized pursuant to our Terms of Use, Privacy Policy, and any applicable agreement.</b></p>

                        <h4>COPPA Parent Permission</h4>
                        <p>Under the licensing agreement between Gamably and the teachers using the Gamably software, the teachers are responsible for obtaining parental permission should it be required under COPPA. Gamably does provide information for teachers along with a sample parental permission document should they require one. This is available on <a href="/coppa" target="_blank">this page</a>.</p>
                        

                        <h3>Users Without Accounts</h3>
                        <p>Users who create or manage a game (called a "World" or collectively called "Worlds") can also add a User (called an "Inhabitant") or Users (collectively called "Inhabitants") to their game without requiring the User(s) to create an account. Game creators or managers do not have to provide any Personal Information to create a user within their World. When creating a World, game leaders or managers choose which type of rostering system they want to use. The options are 1) to create users who exist in that World only, or 2) allow users to join the World by creating a User account and joining with a join code. This section of the Privacy Policy only pertains to Users created by a game leader within one World (option 1).</p>
                        
                        <p>If a game leader chooses to create Users, he or she can do so by uploading a .CSV of Users or by inputting them one at a time. The only information required to create a user is a name, which can be a username, a number, or the person's actual name. There are inputs for a first name and a last name, but only the first name is required, and that does not need to be an actual name. There is also an input for "Info" which can be any other identifying details about a person, but again, it should not be Personal Information. This "Info" is purely for the benefit of the game leader and the Users in the World. When the User account is created in this manner, an icon will be assigned to the account and a four-digit password will be generated. That sign in information will be provided to the game leader to disseminate however he or she chooses.</p> 

                        <p>Users whose accounts are created this way cannot change their display name or upload a unique icon, they do not have access to join other Worlds, nor can they create their own Worlds. The User information is protected on Google servers in the United States and can only be accessed by Gamably and by the game leader.</p>

                        <a id="userAccounts"></a>
                        <h3>Users Who Create Personal Accounts</h3>
                        <p>Users do have the ability to create an account by providing a valid email address and creating a password, or by signing in using Google's third person Authentication Service through the Firebase Database system. Users can only create worlds if they have created an account within Gamably and are over the age of 16 (according to a User-provided birthdate).</p>

                        <h4>Creating an account with a valid email</h4>
                        <p>Users who create an account by providing a valid email address and password do not have to submit any other Personal Information. They are asked for a first name and last name, but those do not need to be the user's real name. Also, the password provided is stored in Gamably's database system hosted by Google's Firebase servers. Gamably does not have access to User's created passwords, and thus does not share that information or make it public. If users want to change their passwords, they can do so by clicking "Forgot my password" on the sign in page.</p>

                        <h4>Creating an account with Google Authentication</h4>
                        <p>If you decide to register for a Gamably account through Google's Authentication Service, you give Gamably the permission to store and use information already associated with your Authentication Service consistent with this Privacy Policy and the table below. When using third-party authentication systems, Gamably may receive personal data (such as a User's full name or gender) that is not required for use of the Website. However, Gamably uses Google's Authentication Service only to authenticate the User's account and has no access to the User's password or anything else associated with that account aside from the information the user has provided to Google and has made public. Gamably does nothing with extemporaneous information other than the User's email, display name, and icon (for users over 13 years of age according to a User-provided birthdate).</p>
                        
                        <p>You may revoke Gamably’s access to your account on any Authentication Service at any time by updating the appropriate settings in the <a href="https://myaccount.google.com/permissions" target="_blank">Google Account Permissions Preferences</a>. You should check your privacy settings on your Google account to understand and change the information sent to us. Please review Google’s terms of use and privacy policies carefully before using their services and connecting to our Website.</p>

                        <a id="collectedData"></a>
                        <h3>Data Collected by Gamably</h3>
                        <p>Gamably collects the following data from Users:</p>
                        <table class="uk-table uk-table-responsive">
                            <tr>
                                <td className='data-header'>
                                    <p className="bold">IP Address</p>
                                </td>
                                <td>
                                    <p>Collected ONLY when a User creates an account in Gamably through one of the two methods described <a href="#userAccounts">above</a>. This is used to create a User's profile and to give them access to the Worlds they inhabit or have created. It is viewable only to the creator and managers of the World(s) which the User inhabits.</p>
                                    <p>If Users are over 16 years of age, they also have the option to join a Google Group for communication from Gamably and to participate in a discussion board. This option is not available to those who say they are 16 years or younger when providing their birthdate. If they choose to participate in the Google Group discussion, their personal information may be visible to other group members. You can view Google's Privacy Policies <a href="https://policies.google.com/technologies/product-privacy?hl=en-US" target="_blank">here</a>.</p>
                                </td>
                            </tr>
                            <tr>
                                <td className='data-header'>
                                    <p className="bold">Email Address (required)</p>
                                </td>
                                <td>
                                    <p>Collected ONLY when a User creates an account in Gamably through one of the two methods described <a href="#userAccounts">above</a>. This is used to create a User's profile and to give them access to the Worlds they inhabit or have created. It is viewable only to the creator and managers of the World(s) which the User inhabits.</p>
                                    <p>If Users are over 16 years of age, they also have the option to join a Google Group for communication from Gamably and to participate in a discussion board. This option is not available to those who say they are 16 years or younger when providing their birthdate. If they choose to participate in the Google Group discussion, their personal information may be visible to other group members. You can view Google's Privacy Policies <a href="https://policies.google.com/technologies/product-privacy?hl=en-US" target="_blank">here</a>.</p>
                                </td>
                            </tr>
                            <tr>
                                <td className='data-header'>
                                    <p className='bold'>Display Name (required)</p>
                                </td>
                                <td>
                                    <p>Collected ONLY when a User creates an account in Gamably through one of the two methods described <a href="#userAccounts">above</a>. This information can be viewed by World creators and managers and other Inhabitants within the World, and could be made public by a World creator or manager if he or she chooses to include that information on a <a href="leaderboard">leaderboard</a> and then share the link outside of the constraints of the Gamably World.</p>
                                </td>
                            </tr>
                            <tr>
                                <td className='data-header'>
                                    <p className='bold'>Birthdate (required)</p>
                                </td>
                                <td>
                                    <p>Collected ONLY when a User creates an account in Gamably through one of the two methods described <a href="#userAccounts">above</a>. This information is not public, nor is it viewable by World creators or managers. It is used only to regulate the options available to Users. For example, Users  16 years old and under cannot upload a User icon, nor can they join the Google Group, nor can they create their own World.</p>
                                </td>
                            </tr>
                            <tr>
                                <td className='data-header'>
                                    <p className='bold'>School or Organization Name (optional)</p>
                                </td>
                                <td>
                                    <p>Collected ONLY when a User creates an account in Gamably through one of the two methods described <a href="#userAccounts">above</a>. This information is not made public, nor is it viewable by World creators or managers. This is used by Gamably staff only for usage demographics and support.</p>
                                </td>
                            </tr>
                            <tr>
                                <td className='data-header'>
                                    <p className='bold'>Purpose for Using Gamably (optional)</p>
                                </td>
                                <td>
                                    <p>Collected ONLY when a User creates an account in Gamably through one of the two methods described <a href="#userAccounts">above</a>. This information is not made public, nor is it viewable by World creators or managers. This is used by Gamably staff only for usage demographics and support.</p>
                                </td>
                            </tr>
                            <tr>
                                <td className='data-header'>
                                    <p className='bold'>In-App Progress and Earnings</p>
                                </td>
                                <td>
                                    <p>This data is for gameplay purposes only and is not available to Users outside the constraints of their particular World. The only exception to this is through the use of a public leaderboard. For more information about what data is shared through leaderboards, <a href="#leaderboards">click here</a>.</p>
                                </td>
                            </tr>
                            <tr>
                                <td className='data-header'>
                                    <p className='bold'>Files or Data Uploaded to the App</p>
                                </td>
                                <td>
                                    <p>Throughout the use of the app, Users can upload or submit files, links, or text to complete objectives. That submitted data is only available to the User and the game leader. This data is not the property of Gamably and can be removed at any point. To remove submitted data, the game leader can request it be removed by emailing <a href="mailto:adam@gamably.com">adam@gamably.com</a></p>
                                </td>
                            </tr>
                        </table>


                        <p>Collected information is used for the following purposes:</p>
                        <ul><li>To allow Users to create and manage a World;</li>
                        <li>To allow Users to participate and interact within a World;</li>
                        <li>To enable World-related activities such as challenges, quests, and secret codes;</li>
                        <li>To allow World Leaders, managers, and inhabitants to compare individual or group performance against others within each World;</li>
                        <li>For software support purposes, and</li>
                        <li>For billing purposes</li>
                        </ul>

                        <h4>Changing or Deleting Information</h4>
                        <p>Users who create accounts through the methods described <a href="#userAccounts">above</a> can change their Personal Information through the User account or by contacting Gamably at <a href="mailto:adam@gamably.com" target="_blank">adam@gamably.com</a>. To delete an account, please contact Gamably at <a href="mailto:adam@gamably.com" target="_blank">adam@gamably.com</a>.</p>

                        <a id="leaderboards"></a>
                        <h4>Information Displayed on Leaderboards</h4>
                        <p>The only situation in which Personal Information is shared outside of the constraints of a User's World is in the case of leaderboards. Leaderboards are created by the World creators and managers and have the ability to show Users' display names, icons, earned badges, and earned points, currency, or level if those settings are chosen by those creating the leaderboard. The links to created leaderboards do not require authentication to view; however, the links are not shared anywhere on the site, nor are they available to anyone outside of the constraints of the World in which they are created. If World creators or managers choose to share the links to the leaderboards, that is their perogative, and Gamably is not responsible for any information shared in that way.</p>
                        
                        <h4>Information Displayed Within Inhabitant Account Pages</h4>
                        <p>By default, Users can see World events for other Users in their class from within their account. They can see what was earned on what date, but they cannot see more information, such as how the items were earned or the World creator's or manager's comments.</p>

                        <h3>User Access Privileges</h3>
                        <p>World creators have default access to the User Data described above for the Inhabitants in their World(s), but not for any inhabitants in Worlds that they did not create. However, World creators can add managers who are older than 16 years of age, and those managers will have access to the same User Data that the World creators do. They can also have access to interactive elements in the World like distribution and creation of items, badges, levels, etc. Only World creators have the ability to add managers, and a manager cannot add him or herself to a World.</p>
                            
                        <p>Inhabitants are granted access to their personal data within the World, their group game play data, all content created and posted by World leaders and managers, basic information about the progress and earnings of other Inhabitants (not including leader or manager comments, or the way in which items, badges, points and/or currency were earned) as well as any data that is displayed by the teacher in front of the whole class.</p>

                        <h3>Dealing With Records in a Classroom Setting</h3>
                        <p>If Gamably is used in a classroom setting, the following information is considered student records: student game play information, such as XP, currency, items, and/or badges earned, and all related data including User-created content that has been submitted through Challenges and Quests and comments received from World creators or managers while completing said Quests and/or Challenges. This information is the property of the User who submitted it and will not be used by Gamably for anything outside of the constraints of the World in which the data was submitted. This data will not be kept longer than 12 months after the last activity was recorded in the World. Parents and students over the age of 18 can request to have their account, or the account of their child, and related information deleted from the Gamably database by advising Gamably at <a href="mailto:adam@gamably.com" target="_blank">adam@gamably.com</a>.</p>

                        <p>Student records are the property of and under the control of the school and/or school district. Students can access their student records by logging in to their student account. Parents of students under the age of 18 can access their child’s information by logging in using their child’s account, by contacting the teacher or by contacting Gamably at <a href="mailto:adam@gamably.com" target="_blank">adam@gamably.com</a>. Corrections of any erroneous information should first be brought to the attention of the teacher or the school administration. In the event that the teacher or school are unable to make the correction, parents should advise Gamably at <a href="mailto:adam@gamably.com" target="_blank">adam@gamably.com</a> who will then work with the teacher or school to facilitate correction of any erroneous information. Third parties are not granted access to personally identifiable information or educational records or other student records except as provided in this Privacy Policy, or after first obtaining the express written consent of the parent or student over 18 years of age. Should there be any unauthorized disclosure of a student’s records, Gamably will notify the school, teacher, parent and student via email within 3 days following discovery of the unauthorized disclosure.</p>

                        <h4>No Marketing to Students and Parents</h4>
                        <p>Gamably does not share user data with third parties for marketing purposes. Furthermore, Gamably does not use student data for marketing of any kind, including both marketing to student and to parents.</p>

                        <h3>User-created World Content</h3>
                        <p>World creators, managers, and inhabitants may make postings in various sections of Gamably. Common examples of this would be links to videos hosted by YouTube or Vimeo, links to Wikipedia or other reference material, and so on submitted in Challenges or Quests. It is the responsibility of the User submitting the content to ensure that this content is appropriate and free from links to sites which may compromise the privacy of other Users. Gamably does not monitor the classroom content and has no responsibility for this content. Gamably does not actively monitor World content.</p>

                        <h3>Change of Control</h3>
                        <p>In the event that all or a portion of Gamably or its assets are acquired by or merged with a third party, personal information that we have collected from users would be one of the assets transferred to or acquired by that third party. This Privacy Policy will continue to apply to your information, and any acquirer would only be able to handle your personal information as per this policy (unless you give consent to a new policy). We will provide you with notice of an acquisition within thirty (30) days following the completion of such a transaction, by posting on our homepage, or by email to your email address that you provided to us. If you do not consent to the use of your personal information by such a successor company, you may request its deletion from the company. In the unlikely event that Gamably goes out of business, all user information will be securely deleted within 12 months of the date of going out of business.</p>

                        <h3>Disclosure of Information to Satisfy Legal Obligations</h3>
                        <p>Gamably may disclose personal information if we have a good faith belief that doing so is necessary to comply with the law, such as complying with a subpoena or other legal process. We may need to disclose personal information where, in good faith, we think it is necessary to protect the rights, property, or safety of Gamably, our employees, our community, or others, or to prevent violations of our our Terms of Service or other agreements. This includes, without limitation, exchanging information with other companies and organizations for fraud protection or responding to government requests.</p>

                        <h3>Changes and Updates to the Privacy Policy</h3>
                        <p>This Privacy Policy is effective as of March 18, 2021 and is version 1.1. Gamably may, at its sole discretion, update, revise, modify and supplement from time to time this Privacy Policy. Should we make modifications to this policy or any other agreements, we’ll post a notice that you will receive when you login to the game and/or we will send an email directly to you with a copy of the new documents. Gamably's updated Privacy Policy and User Agreements will also be posted on Gamably's public facing website. Gamably asks users to review the updated Privacy Policy and/or agreements before continuing to use our services. If users do not accept the updated Privacy Policy users can remove their information by contacting us at <a href="mailto:adam@gamably.com" target="_blank">adam@gamably.com</a>. The user’s continued use of the services provided by Gamably after the updated Privacy Policy takes effect will constitute user’s acceptance of the updated Privacy Policy.</p>

                        <h3>Consent to the Gathering and Processing of Information</h3>
                        <p>By using Gamably's services and website, Users accept the Terms of Use and Privacy Policy and are expressly giving Gamably a special declaration that Users have decided by their own free will to grant Gamably specific authorization to collect, process and use the personal information stipulated in this Privacy Policy and to use such information in accordance with what is stipulated in this Privacy Policy. Users are further declaring that users are aware of the purpose for Gamably collecting, processing and using such information, how the processing will be conducted, how users’ privacy will be protected and that users are authorized to withdraw their consent.</p>

                        <h3>Contact</h3>
                        <p>Gamably's service to its users and users’ trust is of utmost importance to Gamably. If users have any questions about this Privacy Policy, users should contact Gamably at <a href="mailto:adam@gamably.com" target="_blank">adam@gamably.com</a>.</p>
                    </div>
                </div>
                <div className='g-space-2'></div>
            </div>
            <Footer />
        </div>
    )
}

export default PrivacyPolicy