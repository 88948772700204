import React, { useEffect, useRef, useState } from 'react';
import './join-page.styles.scss';
import { useSelector } from 'react-redux';
import HeadBar from '@/components/layout-auth/head-bar/head-bar.component';
import UnauthHeader from '@/components/layout-unauth/unauth-head.component';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getGameDataFromDb } from '@/utils/getDataFromDb';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import JoinUploadList from './join-upload-list.component';
import SignPlayerInWithCode from './sign-player-in-with-code.component';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import { changeDocumentTitle } from '@/utils/useDocumentTitle';

const JoinPage = () => {

    const currentUser = useSelector(state => state.userData.currentUser);
    const [ inputCode, setInputCode ] = useState(null);
    const [ gameData, setGameData ] = useState(null);
    const [ noGame, setNoGame ] = useState(false);
    const [ playerToSignIn, setPlayerToSignIn ] = useState(null);
    const { joinCode } = useParams();
    const navigate = useNavigate();
    const gameRef = useRef();
    let gameListener;

    useEffect(() => {
        changeDocumentTitle();
        return () => {
            if (gameListener) { gameListener(); }
        }
    }, [])

    useEffect(() => {
        if (!currentUser) return;
        if (!currentUser.provider || currentUser.provider === 'FirestoreAuth') return;
        if (joinCode) {
            navigate(`/home/joingame/${joinCode}`)    
        } else {
            navigate('/home/joingame')
        }
    }, [currentUser, joinCode])

    useEffect(() => {
        if (!joinCode) {return;}
        console.log(joinCode)
        getGameData();
    }, [joinCode])

    function goToApproval() {
        // if (!currentUser) {
        //     navigate('/signin');
        //     return;
        // }
        const inputCode = document.getElementById('join-code-input').value;
        navigate(inputCode.toLowerCase());
    }

    function getGameData() {
        setNoGame(false);
        gameRef.current = null;
        getGameDataFromDb({'joinCode': joinCode, 'status': 'active', 'callback': callback, 'handleListener': handleListener})
        function callback(data) {
            if (!data.enroll_email) {
                // navigate('/signinwithcode');
                // return;
            }
            gameRef.current = data;
            setGameData(gameRef.current);
        }
        function handleListener(unsub) {
            gameListener = unsub;
        }
        setTimeout(() => {
            if (!gameRef.current) {
                setNoGame(true);
            }
        }, 3000)
    }

    return (
        <div className={`join-page ${currentUser && 'auth'}`}>
            <div className='head-content'>
                {
                    (currentUser)
                    ?
                    <HeadBar />
                    :
                    <UnauthHeader />
                }
            </div>
            <div className='join-page-content'>
                {
                    (!joinCode) 
                    ?
                    <div className='join-code-form'>
                        <div className='g-card'>
                            <h2>Join a Game</h2>
                            <hr />
                            <div className='icon-input'>
                                {/* <img src={icon} className='icon' /> */}
                                <div className='field'>
                                    <label>Enter a Game Code</label>
                                    <input 
                                        type='text' 
                                        id='join-code-input' 
                                        value={inputCode ?? ''} 
                                        onChange={(e) => setInputCode(e.target.value.replace(/[^\w-]+/g, '').toLowerCase())} 
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                goToApproval();
                                            }
                                        }} 
                                    />
                                </div>
                                <div>
                                    <button className='g-button primary' onClick={() => goToApproval()}>
                                        Go!
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='g-space-20'></div>
                    </div>
                    :
                    <>
                        {
                            (noGame)
                            ?
                            <div className='join-code-form'>
                                <div className='g-card join-approval-card'>
                                    <div className='no-game'>
                                        <h3>No Game Found. Try again!</h3>
                                        <Link to='/join'>
                                            <button className='g-button'>Ok</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            :
                            (playerToSignIn) 
                            ?
                            <div className='join-code-form'>
                                <div className='g-card join-approval-card'>
                                    <BackButton cancel={() => setPlayerToSignIn(null)} />
                                    <div className="g-space-1"></div>
                                    <div className='game-info'>
                                        <img src={gameData.icon} className='icon' />
                                        <div>
                                            <div className='title'>{gameData.gameName}</div>
                                            <div className='info'>
                                                Creator: {gameData.creatorName}<br />
                                                Join Code: {gameData.joinCode}
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <SignPlayerInWithCode gameData={gameData} playerData={playerToSignIn} />
                                </div>
                            </div>
                            :
                            (gameData && gameData.enroll_upload)
                            ?
                            <div className='player-list'>
                                <JoinUploadList gameData={gameData} setPlayerToSignIn={setPlayerToSignIn} />
                                <div className='g-space-1'></div>
                            </div>
                            :
                            <div className='join-code-form'>
                                <div className='g-card join-approval-card'>
                                    <div className='center-col'>
                                        <ThreeDotsLoader />
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                }
            </div>
        </div>
    )
}

export default JoinPage