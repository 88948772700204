import React, { useEffect, useState } from 'react';
import './prize-packs-list.styles.scss';
import ItemSearchBar from '@/components/SHARED/search-bar/item-search-bar.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import VisibilityButton from '../components/visibility-button.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPie, faPen } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { stripHtml } from '@/utils/sanitize';

const PrizePacksList = ({
    gameElements
}) => {

    const [ availableItems, setAvailableItems ] = useState([]);
    const [ contentToDisplay, setContentToDisplay ] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (!gameElements) {return;}
        const avail = Object.values(gameElements).filter(a => a.opt_itemType === 'prizepack');
        setContentToDisplay([...avail]);
        setAvailableItems([...avail]);
    }, [gameElements])

    return (
        <div className='prize-packs-list'>
            <ItemSearchBar
                availableItems={availableItems}
                setItemsToDisplay={setContentToDisplay}
                noTypes={true}
                itemType='prizePack'
            />
            <div className="g-space-1"></div>
            <div className='pp-list-div'>
                <div className='g-list-item pp-list-head row'>
                    <div></div>
                    <div>Name</div>
                    <div>Description</div>
                    <div># of Prizes</div>
                    <div>Closes</div>
                    <div></div>
                </div>
                <div className='g-space-0-5'></div>
                <div className='pp-list'>
                    {
                        (contentToDisplay.length > 0)
                        ?
                        contentToDisplay.sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                        .map(c => (
                            <div key={c.id} className='g-list-item row'>
                                <div className='item-icon-div'>
                                    <ImageContainer 
                                        src={c.icon} 
                                        alt={`${c.name} item icon`} 
                                        className='item-icon' 
                                    />
                                </div>
                                <div className='item-name'>{c.name}</div>
                                <div className='item-desc meta'>{c.desc && stripHtml(c.desc).substring(0,60)}{c.desc && stripHtml(c.desc).length > 60 ? '...' : ''}</div>
                                <div className='meta'>{c.opt_prizes ? c.opt_prizes.length : 0}</div>
                                <div className='meta'>{c.opt_dateClose ? format(new Date(c.opt_dateClose), 'P') : "Never"}</div>
                                <div className='buttons'>
                                    <VisibilityButton element={c} />
                                    <button 
                                        className='g-button med-btn' 
                                        title='View item data'
                                        onClick={() => navigate(`../data/${c.path}`)}
                                    >
                                        <FontAwesomeIcon icon={faChartPie} />
                                    </button>
                                    <button 
                                        className='g-button med-btn' 
                                        onClick={() => navigate(`edit/${c.path}`)} 
                                        title='Edit this Prize Pack'>
                                        <FontAwesomeIcon icon={faPen} />
                                    </button>
                                </div>
                            </div>
                        ))
                        :
                        <p>No Prize Packs yet...</p>
                    }
                </div>
            </div>
        </div>
    )
}

export default PrizePacksList