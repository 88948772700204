import React, {
    useEffect, 
    useRef, 
    useState 
} from 'react'
import './manage-game.styles.scss';

import { 
    useLocation, 
    useNavigate, 
    useParams 
} from 'react-router-dom';
import { 
    useDispatch, 
    useSelector 
} from 'react-redux';
import ManageHeadBar from '@/components/MANAGE-GAME/manage-layout/manage-game-head-bar/manage-head-bar.component';
import ManageLanding from '@/components/MANAGE-GAME/manage-landing.component';
import ManageGameSidebar from '@/components/MANAGE-GAME/manage-layout/manage-game-sidebar/manage-game-sidebar.component';
import { 
    getGameActivityFromDb, 
    getGameDataFromDb, 
    getPlayerDataFromDb, 
    getGameElementsFromDb, 
    getActionItemsFromDb, 
    getUniqueDocumentFromDb, 
    getLatestGameMessageFromDb, 
    getPlayersFromPlayerDocs, 
    getGameActivityFromRtDb,
    getActionItemsFromRtDb,
    getGameElementsFromRtDb
} from '@/utils/getDataFromDb';
import { 
    ModalAlert, 
    ModalConfirm 
} from '@/components/modal/modal.component';
import { 
    Desktop, 
    LargeTablet, 
    Mobile, 
    Tablet 
} from '@/utils/responsiveQueries';
import ManageMobileHeader from '@/components/MANAGE-GAME/manage-layout/manage-game-head-bar/manage-mobile-head.component';
import { 
    auth, 
    UseCloudFunction 
} from '@/utils/firebase.utils';
import Loader from '@/components/loader/loader.comp';
import { testMemberRestrictions } from '@/utils/membershipRestrictions';
import UpgradeRequiredModal from '@/components/SHARED/upgrade-required-modal/upgrade-required-modal.component';
import { off } from 'firebase/database';
import { changeDocumentTitle } from '@/utils/useDocumentTitle';

const ManageGamePage = ({ 
    orgs,
    managingList
}) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const membership = useSelector(state => state.userData.membership);
    const newPasscode = useSelector(state => state.contentData.newPasscode);
    const { gamePath } = useParams();
    const [ gameId, setGameId ] = useState(null);
    const [ role, setRole ] = useState(null);
    const [ currentGame, setCurrentGame ] = useState(null);
    const [ gameDataRetrieved, setGameDataRetrieved ] = useState(false);
    const [ gameElements, setGameElements ] = useState(null);
    const [ playerList, setPlayerList ] = useState({});
    const [ gameStats, setGameStats ] = useState(null);
    const [ gameActivity, setGameActivity ] = useState({});
    const [ teamMembers, setTeamMembers ] = useState({});
    const [ passcodes, setPasscodes ] = useState(null);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ actionItems, setActionItems ] = useState(null);
    const [ latestMessage, setLatestMessage ] = useState(null);
    const [ showUpgradeModal, setShowUpgradeModal ]  = useState();
    const [ loading, setLoading ] = useState(false);
    const onApprove = useRef();
    const elementsRef = useRef();
    const playersRef = useRef();
    const activityRef = useRef();
    const actionItemsRef = useRef();
    const gettingDataRef = useRef();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    let gameListener;
    let elementsListener;
    let playersListener;
    let playerListListener;
    let activityListener;
    let actionItemsListener;
    let moreActivityListener;
    let statsListener;
    let teamListener;
    let messageListener;
    const accessTimeoutRef = useRef();
    const loggedInTimeoutRef = useRef();

    useEffect(() => {
        loggedInTimeoutRef.current = setTimeout(() => {
            const currentlySignedInUser = auth.currentUser;
            // console.log(currentlySignedInUser);
            if (!currentlySignedInUser) {
                navigate('/logout');
            }
        }, 1000);

        let localStorageSize = function () {
            let _lsTotal = 0,_xLen, _x;
            for (_x in localStorage) {
            if (!localStorage.hasOwnProperty(_x)) continue;
                _xLen = (localStorage[_x].length + _x.length) * 2;
                _lsTotal += _xLen;
            }
            return  (_lsTotal / 1024).toFixed(2);
        }
        console.log(localStorageSize() + 'kb');
        

        return () => {
            if (gameListener) gameListener();
            if (elementsListener) off(elementsListener);
            if (playersListener) playersListener();
            if (playerListListener) off(playerListListener);
            if (activityListener) off(activityListener);
            if (actionItemsListener) off(actionItemsListener);
            if (moreActivityListener) moreActivityListener();
            if (statsListener) statsListener();
            if (teamListener) teamListener();
            if (messageListener) messageListener();
            if (accessTimeoutRef.current) clearTimeout(accessTimeoutRef.current);
            if (loggedInTimeoutRef.current) clearTimeout(loggedInTimeoutRef.current);
        }
    }, [])

    useEffect(() => {
        if (
            !location.pathname.includes('/activity') &&
            !location.pathname.includes('/transaction/')
        ) {
            window.localStorage.removeItem('activity-list-filter');
            localStorage.removeItem('scrollpoint');
            localStorage.removeItem('totalCount');
        }
    }, [location])

    useEffect(() => {
        if (!managingList || !gamePath || !membership || !userProfile) return;
        // if (accessTimeoutRef.current) clearTimeout(accessTimeoutRef.current);
        // accessTimeoutRef.current = setTimeout(() => {
        //     checkAccess();
        // }, 2000);
        
        if (
            Object.values(managingList)
            .filter(g => g.path === gamePath).length > 0 && 
            !gameDataRetrieved && 
            !gettingDataRef.current
        ) {
            getGameData();
            gettingDataRef.current = true;
            setGameDataRetrieved(gettingDataRef.current);
        }
        
    }, [managingList, gamePath, membership, userProfile])

    useEffect(() => {
        if (!newPasscode) return;
        const codes = {...passcodes}
        codes[newPasscode.playerId] = newPasscode.code;
        setPasscodes({...codes});
    }, [newPasscode])

    async function checkAccess() {
        const accessRes = await UseCloudFunction(
            'checkManagerAccess',
            {
                'appId': userProfile.appId,
                'gameId': gameId
            }
        )
        if (!accessRes.success) {
            setConfirmMessage(`Sorry, you don't have access to manage this game.`);
            onApprove.current = () => {
                navigate('/home');
            }
            return;
        }
    }

    function getGameData() {
        getGameDataFromDb({
            'gamePath': gamePath, 
            'status': 'active', 
            'callback': callback, 
            'handleListener': handleListener
        })
        async function callback(data) {
            setCurrentGame(data);
            changeDocumentTitle(`Manage | ${data.gameName}`, data.icon);
            setRole(managingList[data.gameId].role);
            const sortedGames = Object.values(managingList).sort((a,b) => a.lastActivity < b.lastActivity ? 1 : -1);
            let index = 0;
            for (let i=0; i < sortedGames.length; i++) {
                const game = sortedGames[i];
                if (game.path === data.path) {
                    index = i;
                }
            }
            const testedGame = testMemberRestrictions({'gameData': data, 'membership': membership, 'index': index})
            if (testedGame.disabled) {
                setShowUpgradeModal(true);
                return;
            }
            // change var(--primary) to game color
            var r = document.querySelector(':root');
            r.style.setProperty('--primary', data.color);
            r.style.setProperty('--primary-trans', data.color+'a6');
            if (!gameId) setGameId(data.gameId);
            
        }
        function handleListener(unsub) {
            gameListener = unsub;
        }
    }

    useEffect(() => {
        if (!gameId) return;
        if (
            managingList[gameId].role === 'owner' ||
            managingList[gameId].role === 'manager'
        ) {
            getPlayerList(gameId);
            getActionItems(gameId);
            
        }

        if (managingList[gameId].role === 'distributor') {
            getPlayerListFromPlayerDocs(gameId);
        }
        getPasscodes(gamePath);
        getGameElements(gameId);
        getGameActivity(gameId);
        getGameStats(gameId);
        getTeamMembers(gameId);
        getLatestMessage(gameId);
        checkAccess();
    }, [gameId])

    function getGameElements(gameId) {
        getGameElementsFromRtDb({
            'gameId': gameId, 
            'callback': callback, 
            'handleListener': handleListener
        })
        function callback(data) {
            elementsRef.current = {...elementsRef.current, ...data}
            setGameElements(elementsRef.current);
            // console.log(elementsRef.current);
        }
        function handleListener(unsub) {
            elementsListener = unsub;
        }
    }

    function getPlayerListFromPlayerDocs(gameId) {
        getPlayersFromPlayerDocs({
            'gameId': gameId,
            'callback': callback,
            'handleListener': handleListener
        })
        function callback(data) {
            let tempList = {...playersRef.current};
            for (let p of Object.values(data)) {
                if (p.status !== 'active') {
                    if (tempList[p.playerId]) {
                        delete tempList[p.playerId];
                    }
                } else {
                    tempList[p.playerId] = p;
                }
            }
            playersRef.current = {...tempList};
            setPlayerList(playersRef.current);
        }
        function handleListener(unsub) {
            playerListListener = unsub;
        }
    }

    function getPlayerList(gameId) {
        getPlayerDataFromDb({
            'gameId': gameId, 
            'callback': callback, 
            'handleListener': handleListener
        })
        function callback(data) {
            // console.log(data);
            if (data.status !== 'active') {
                let tempList = {...playersRef.current};
                if (tempList[data.playerId]) {
                    delete tempList[data.playerId];
                }
                playersRef.current = {...tempList};
                setPlayerList(playersRef.current);    
            } else {
                playersRef.current = {...playersRef.current, ...{[data.playerId]: data}}
                setPlayerList(playersRef.current);
            }
        }
        function handleListener(unsub) {
            playersListener = unsub;
        }
    }

    function getGameActivity(gameId) {
        getGameActivityFromRtDb({
            'gameId': gameId, 
            'callback': callback, 
            'handleListener': handleListener
        })
        function callback(data) {
            activityRef.current = {...activityRef.current, ...data};
            setGameActivity(activityRef.current);
        }
        function handleListener(unsub) {
            activityListener = unsub;
        }
    }


    function getActionItems(gameId) {
        getActionItemsFromRtDb({
            'gameId': gameId, 
            'callback': callback, 
            'handleListener': handleListener
        })
        function callback(data) {
            actionItemsRef.current = {...actionItemsRef.current, ...data}
            setActionItems(actionItemsRef.current);
        }
        function handleListener(unsub) {
            actionItemsListener = unsub
        }
    }

    function getGameStats(gameId) {
        getUniqueDocumentFromDb({
            "collection": "gameStats", 
            "docId": gameId,
            "callback": callback,
            "handleListener": handleListener
        })
        function callback(data) {
            setGameStats(data)
        }
        function handleListener(unsub) {
            statsListener = unsub;
        }
    }

    function getTeamMembers(gameId) {
        getUniqueDocumentFromDb({
            "collection": "teamMembers",
            "docId": gameId,
            "callback": callback,
            "handleListener": handleListener
        })
        function callback(data) {
            
            setTeamMembers(data);
        }
        function handleListener(unsub) {
            teamListener = unsub;
        }
    }

    async function getPasscodes(gamePath) {
        const res = await UseCloudFunction(
            'getPrintableData',
            {
                'gamePath': gamePath,
                'userProfile': userProfile,
                'codesOnly': true
            }
        )
        if (res.error) {
            setAlertMessage('There was an error fetching passcodes.')
            return;
        }
        setPasscodes(res.passcodes);
    }

    async function getLatestMessage(gameId) {
        getLatestGameMessageFromDb({
            'gameId': gameId,
            'callback': callback,
            'handleListener': handleListener
        })
        function callback(data) {
            setLatestMessage(data)
        }
        function handleListener(unsub) {
            messageListener = unsub;
        }
    }

    return (
        <>
        {
            (currentGame) 
            ?
            <div className='manage-game-page' style={
                (currentGame.bg.type === 'gradient')
                ?
                {'background': `linear-gradient(to bottom, ${currentGame.bg.hexTop}, ${currentGame.bg.hexBottom})`}
                :
                {'background': `url("${currentGame.bg.url}") no-repeat center center fixed`}
            }>
                <Desktop>
                    <ManageHeadBar gameData={currentGame} />
                    <div className='manage-content'>
                        <ManageGameSidebar 
                            gameData={currentGame} 
                            actionItems={actionItems} 
                            gameElements={gameElements}
                            orgs={orgs}
                            role={role}
                        />
                        <ManageLanding 
                            gameData={currentGame} 
                            gameElements={gameElements} 
                            playerList={playerList} 
                            gameActivity={gameActivity} 
                            gameStats={gameStats}
                            actionItems={actionItems}
                            teamMembers={teamMembers}
                            passcodes={passcodes}
                            latestMessage={latestMessage}
                            orgs={orgs}
                            role={role}
                        />
                    </div>
                </Desktop>
                
                <LargeTablet>

                </LargeTablet>

                <Tablet>

                </Tablet>

                <Mobile>
                    <ManageMobileHeader gameData={currentGame} />
                </Mobile>
            </div>
            :
            <div className='loading-page'>
                <Loader />
            </div>
        }
        <ModalAlert show={alertMessage} cancel={() => setAlertMessage(null)} message={alertMessage} />
        <ModalConfirm show={confirmMessage} cancel={() => setConfirmMessage(null)} message={confirmMessage} onApprove={onApprove.current} noCancel={true} />
        <UpgradeRequiredModal 
            show={showUpgradeModal} 
            cancel={() => setShowUpgradeModal(false)} 
            closeButton={false}
            message='Sorry! Because you are using the Free Plan, you are unable to access this game and its content because it exceeds what the plan allows.' 
        />
        </>
    )
}

export default ManageGamePage