import React, { useEffect, useState } from 'react';
import './my-orgs.styles.scss';
import { useSelector } from 'react-redux';
import AgeCheck from '@/components/layout-auth/age-check/age-check.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEarthAmericas } from '@fortawesome/free-solid-svg-icons';
import { Route, Routes, useNavigate } from 'react-router-dom';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import org_placeholder from '@/assets/placeholder-icon-org.jpg';
import MyOrgView from './my-org-view.component';
import { changeDocumentTitle } from '@/utils/useDocumentTitle';

const MyOrgs = ({ orgs }) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const [ orgsToDisplay, setOrgsToDisplay ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const navigate = useNavigate('/quote');

    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoading(false);
        }, 3000)
        changeDocumentTitle('Gamably | My Orgs');
        return () => {
            if (timeout) clearTimeout(timeout);
        }
    }, [])

    useEffect(() => {
        console.log(orgs);
        if (!orgs) return;
        let sortedOrgs = Object.values(orgs)
        .sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        setOrgsToDisplay([...sortedOrgs])
        setLoading(false);
    }, [orgs])
    
    return (
        <div className='my-orgs'>
            <AgeCheck />
            {/* <Layout> */}
            <Routes>
                <Route path=''
                    element={
                    <div className='orgs-content'>
                        <div className='g-card'>
                            <div className='section-title with-icon'>
                                <span>My Organizations</span>
                                {
                                    (userProfile) &&
                                    <button 
                                        className='g-button' 
                                        onClick={() => navigate('/quote')}>
                                        Create a New Org
                                        <FontAwesomeIcon 
                                            icon={faEarthAmericas} 
                                            size="lg" 
                                            className='button-icon' />
                                    </button>
                                }
                            </div>
                            <hr className='no-top-margin' />
                            {
                                (loading) 
                                ?
                                <>
                                    <div></div>
                                    <div className='center'><ThreeDotsLoader /></div>
                                </>
                                :
                                (orgsToDisplay.length > 0)
                                ?
                                <div className='org-list'>
                                    {
                                        orgsToDisplay.map(o => (
                                            <button
                                                key={o.orgId} 
                                                className='org-list-item g-button'
                                                onClick={() => navigate(o.path)}>
                                                <div className='icon'>
                                                    <img 
                                                        src={o.icon ?? org_placeholder}
                                                        className='org-icon' />
                                                </div>
                                                <div className='title'>
                                                    {o.name}
                                                </div>
                                            </button>
                                        ))
                                    }
                                </div>
                                :
                                <div className='center-col'>
                                    <h3>No Organizations Yet...</h3>
                                </div>
                            }
                        </div>
                        <div className="g-space-2"></div>
                    </div>
                    }></Route>
                <Route path=':orgPath'
                    element={
                        <div className='orgs-content'>
                            <MyOrgView orgs={orgs} />
                            <div className='g-space-2'></div>
                        </div>
                    }></Route>
            </Routes>
        </div>
    )
}

export default MyOrgs