import React, { useEffect, useRef, useState } from 'react'
import './challenges-form.styles.scss';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ModalAlert, ModalConfirm } from '@/components/modal/modal.component';
import ManageContentInformation from '../blocks/information.component';
import ManageContentVisibility from '../blocks/visibility.component';
import ManageContentIconCropped from '../blocks/icon.component';
import ManageContentCategories from '../blocks/categories.component';
import ManageContentLimitations from '../blocks/limitations.component';
import ManageContentPrerequisites from '../blocks/prerequisites.component';
import ManageContentDates from '../blocks/dates.component';
import { useSelector } from 'react-redux';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { UseCloudFunction } from '@/utils/firebase.utils';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import ManageContentRewards from '../blocks/rewards.component';
import { RandomString } from '@/utils/randomString';
import { membershipRestrictions, testMemberRestrictions } from '@/utils/membershipRestrictions';
import UpgradeRequiredModal from '@/components/SHARED/upgrade-required-modal/upgrade-required-modal.component';
import FullPageLoader from '@/components/loader/full-page-loader.component';

const ChallengesForm = ({ gameElements, gameData, playerList }) => {

    const membership = useSelector(state => state.userData.membership);
    const userProfile = useSelector(state => state.userData.userProfile);
    const [ formData, setFormData ] = useState({})
    const [ availableItems, setAvailableItems ] = useState(null);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const [ existingDescription, setExistingDescription ] = useState(null);
    const [ clearEditor, setClearEditor ] = useState(false);
    const [ categories, setCategories ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ showUpgradeModal, setShowUpgradeModal ] = useState(null);
    const { challengePath } = useParams();
    const dataRef = useRef();
    const approveRef = useRef();
    const navigate = useNavigate();
    const storage = getStorage();
    const location = useLocation();

    useEffect(() => {
        if (!gameData || !membership || !location) return;
        if (location.pathname.includes('/elements/challenges/edit')) return;
        const res = testMemberRestrictions({'gameData':gameData, 'membership':membership, 'create': true});
        if (res.disabled && res.disabledReason.includes('elements')) {
            const planRest = membershipRestrictions[membership.membership];
            const message = `While using the ${planRest.title}, you are only able to have ${planRest.elementCount} active Game Elements per game.`;
            setShowUpgradeModal(message);
            return;
        }
    }, [gameData, membership, location])

    useEffect(() => {
        if (!challengePath || !gameElements) {
            saveData('opt_visible', true);
            saveData('opt_available', true);
            return;
        };
        dataRef.current = Object.values(gameElements).filter(e => e.path === challengePath)[0];
        setFormData(dataRef.current);
        if (dataRef.current.desc) {
            setExistingDescription(dataRef.current.desc);
        }
    }, [challengePath, gameElements])

    useEffect(() => {
        if (!gameElements) {return;}
        let arr = [];
        for (let c of Object.values(gameElements).filter(c => c.type === 'challenge')) {
            if (c.opt_cat && !arr.includes(c.opt_cat)) {
                arr.push(c.opt_cat)
            }
        }
        setCategories(arr.sort())
        let itemObj = {};
        Object.values(gameElements).filter(e => e.opt_itemType === 'inventory').map(e => itemObj[e.id] = e)
        console.log(itemObj);
        setAvailableItems({...itemObj});
    }, [gameElements])

    function saveData(key, value) {
        dataRef.current = {...dataRef.current, ...{[key]: value}};
        setFormData(dataRef.current);
    }
    
    function cancelCreate() {
        setConfirmMessage('Are you sure you want to cancel? All of your data will be lost.');
        approveRef.current = () => {
            dataRef.current = {};
            setFormData(dataRef.current);
            navigate(-1)
        }
    }

    async function handleSubmit() {
        let tempData = {...dataRef.current}
        const reqs = ['name', 'icon']
        for (let r of reqs) {
            if (!tempData[r]) {
                setAlertMessage('Please include a name and an icon for this challenge before saving.');
                return;
            }
        }
        setConfirmMessage('Are you sure you are ready to save this Challenge? All of these settings can be changed later.')
        approveRef.current = () => {
            finishApproval()
        }
    }

    async function finishApproval() {
        setLoading(true);
        let tempData = {...dataRef.current}
        if (tempData.icon.upload) {
			tempData.icon.croppie.result({
				type:'blob',
				size: {'width': 352, 'height': 352},
                quality: 0.7,
			})
			.then((blob) => {
				uploadImage(tempData.icon, blob)
				return;
			})
		} else if (tempData.icon.url) {
			saveChallenge(tempData.icon.url)
			return;
		} else if (tempData.icon) {
            saveChallenge(tempData.icon)
            return; 
        } else {
			setAlertMessage('Something went wrong with the icon. Please try using a different image.');
            setLoading(false);
            return;
		}
    }

    function uploadImage(icon, blob) {		
		const now = new Date().getTime();
		const iconRef = ref(storage, `${gameData.gameId}/challengeIcons/${now}-${icon.name}`)
		const metadata = {
			contentType: icon.type,
            customMetadata: {
                appId: userProfile.appId,
                email: userProfile.email
            }
		};
		uploadBytes(iconRef, blob, metadata).then((snapshot) => {
			getDownloadURL(iconRef)
			.then((url) => {
				saveChallenge(url)
				return;
			})
		})
	}

    async function saveChallenge(iconUrl) {
        let tempData = {...dataRef.current}
        console.log(tempData);
        const now = new Date().getTime();
        const gameCode = gameData.gameId.substring(gameData.gameId.indexOf('-')-5, gameData.gameId.indexOf('-')+6)
        if (!tempData.id) {
            tempData.appId = userProfile.appId;
            tempData.ts_created = now;
            tempData.gameId = gameData.gameId;
            tempData.id = now + '-' + gameCode + '-challenge';
            tempData.status = 'active';
            tempData.type = 'challenge';
            tempData.path = RandomString(18, 'abcdefghijkmnopqrstuvwxyz-_023456789');
            tempData.public = true;
            tempData.opt_available = true;
        } 
        tempData.ts = now;
        if (!tempData.opt_dateOpen) {
            tempData.opt_dateOpen = now;
        }
        tempData.icon = iconUrl;
        console.log(tempData);
        const res = await UseCloudFunction('saveGameElement', {'appId': userProfile.appId, 'elementData': tempData, 'userName': `${userProfile.fName} ${userProfile.lName}`})
        console.log(res);
        if (res.error) {
            setAlertMessage('Something went wrong. Please try again later. Error: ' + res.error);
            setLoading(false);
            return;
        }
        setAlertMessage('Challenge saved successfully.');
        dataRef.current = {};
        setFormData(dataRef.current);
        setLoading(false);
        navigate(-1);
    }

    return (
        <div className='create-challenge-form'>
            <ManageContentInformation 
                type='challenge' 
                saveData={saveData} 
                formData={formData} 
                existingDescription={existingDescription} 
                clearEditor={clearEditor} 
                setClearEditor={setClearEditor}
                textEditorLabel='Challenge Instructions (optional)'
            />
            <div className='g-space-2'></div>
            <ManageContentIconCropped
                type='challenge' 
                saveData={saveData} 
                formData={formData} 
            />
            <div className='g-space-2'></div>
            
            <ManageContentCategories
                type='challenge' 
                saveData={saveData} 
                formData={formData} 
                categories={categories}
            />
            <div className='g-space-2'></div>
            <ManageContentVisibility 
                type='challenge' 
                saveData={saveData} 
                formData={formData} 
                previewText="Allow players to see this challenge before they are allowed submit a response. If toggled off, players will see a mystery challenge and will not be able to access the challenge's information."
                visibilityText="Turn visibility ON for this Challenge. If toggled off, it will be totally hidden from players."
                availabilityText="This Challenge is able to be accessed and completed by players if it is open according to the dates below and if the player meets any chosen prerequisites. If toggled off, players cannot submit content for this challenge."
            />
            <div className='g-space-2'></div>
            <ManageContentLimitations
                type='challenge' 
                saveData={saveData} 
                formData={formData} 
            />
            <div className='g-space-2'></div>
            <ManageContentPrerequisites
                type='challenge' 
                saveData={saveData} 
                formData={formData}
                gameElements={gameElements} 
                availableItems={availableItems}
                teams={
                    Object.values(playerList)
                    .filter(p => p.team && p.status === 'active')
                    .sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                }
                gameData={gameData}
            />
            <div className='g-space-2'></div>
            <ManageContentRewards
                type='challenge' 
                saveData={saveData} 
                formData={formData} 
                gameElements={gameElements}
                text='completing this challenge'
                gameData={gameData}
            />
            <div className='g-space-2'></div>
            <ManageContentDates
                type='challenge' 
                saveData={saveData} 
                formData={formData} 
                gameElements={gameElements}
                text='Challenges will appear, but they will not be able to be completed before or after these dates.'
            />
            <div className='g-space-2'></div>
            <span className='required'>* = Required</span>
            <div className='buttons'>
                {
                    (loading) 
                    ?
                    <>
                        <button type='button' className='g-button'>Cancel</button>
                        <button type='button' className='g-button'><ThreeDotsLoader /></button>
                    </>
                    :
                    <>
                        <button type='button' className='g-button' onClick={() => cancelCreate()}>Cancel</button>
                        <button type='button' className='g-button submit-button' onClick={() => handleSubmit()}>Save Challenge</button>
                    </>
                }
            </div>
            <ModalAlert show={alertMessage} cancel={() => setAlertMessage(null)} message={alertMessage} />
            <ModalConfirm show={confirmMessage} cancel={() => setConfirmMessage(null)} message={confirmMessage} onApprove={approveRef.current} />
            <UpgradeRequiredModal
                show={showUpgradeModal}
                cancel={() => setShowUpgradeModal(null)}
                message={showUpgradeModal}
            />
            <FullPageLoader show={loading} />
        </div>
    )
}

export default ChallengesForm