import React, { useEffect, useState } from 'react';
import './manage-contributions.styles.scss';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPiggyBank, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import ManageContributionCard from './manage-contribution-card.component';

const ManageContributions = ({
    gameData,
    gameActivity,
    playerList,
    gameStats,
    gameElements
}) => {

    const [ contributionsToDisplay, setContributionsToDisplay ] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (!gameElements) return;
        let contList = Object.values(gameElements)
        .filter(e => 
            e.opt_itemType === 'contribution' &&
            e.status === 'active'
        )
        setContributionsToDisplay([...contList]
            .sort((a,b) => a.name > b.name ? 1 : -1));
    }, [gameElements])

    return (
        <div className='manage-contributions'>
            <div className='g-card contributions-card'>
                <BackButton cancel={() => navigate(`/manage/${gameData.path}`)} />
                <div className='card-title'>
                    <div className='title-with-icon'>
                        <FontAwesomeIcon icon={faPiggyBank} />Manage Contributions
                    </div>
                </div>
                <hr />
                <div className='contribution-list'>
                {
                    (contributionsToDisplay.length > 0)
                    ?
                    contributionsToDisplay.map(c => (
                        <div 
                            key={c.id} 
                            className='contribution-card' >
                            <ManageContributionCard
                                gameData={gameData}
                                item={c}
                                gameStats={gameStats}
                                gameActivity={gameActivity}
                                playerList={playerList} />
                        </div>
                    ))
                    :
                    <h3>No contributions yet...</h3>
                }
                </div>
            </div>
        </div>
    )
}

export default ManageContributions