import React, { useEffect, useRef, useState } from 'react';
import './join-upload-list.styles.scss';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import { useNavigate } from 'react-router-dom';
import { UseCloudFunction } from '@/utils/firebase.utils';
import { ModalAlert } from '@/components/modal/modal.component';
import { sortPlayers } from '@/utils/sortPlayers';
import { useSelector } from 'react-redux';

const JoinUploadList = ({ gameData, setPlayerToSignIn }) => {

    const currentUser = useSelector(state => state.userData.currentUser);
    const [ uploadList, setUploadList ] = useState(null);
    const [ loading, setLoading ] = useState(true);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const navigate = useNavigate();
    const listRef = useRef();
    const intervalRef = useRef();
    let playerListener;

    useEffect(() => {
        if (!gameData) return;
        if (currentUser) {
            const encodedUrl = encodeURI(`/join/${gameData.joinCode}`);
            navigate(`/logout?redirectUrl=${encodedUrl}`);
        }
        getPlayers();

        return () => {
            if (playerListener) playerListener();
            clearInterval(intervalRef.current);
        }
    }, [gameData])

    async function getPlayers() {
        const res = await UseCloudFunction('getPlayerListForSignIn', {'gameId': gameData.gameId})
        console.log(res);
        if (res.error) {
            setAlertMessage(`Sorry! Something went wrong. Please try again later. (Error: ${res.error}`)
            return;
        }
        setUploadList(sortPlayers({
            'playerList': res.players
        }));
        setLoading(false);
    }

    function signInWithCode(playerId) {
        window.localStorage.setItem('signInCode', playerId);
        navigate(`/signinwithcode`);
    }

    return (
        <div className='g-card join-upload-list'>
            {
                (gameData) &&
                <>
                    <div className='game-head'>
                        <ImageContainer src={gameData.icon} alt='game logo' className='icon' />
                        {gameData.gameName}
                    </div>
                    <hr />
                </>
            }
            {
                (loading)
                ?
                <div className='center-col'><ThreeDotsLoader /></div>
                :
                (uploadList && uploadList.length > 0)
                ?
                <div className='player-list'>
                    {
                        (gameData && gameData.enroll_email) &&
                        <button className='g-button' onClick={() => navigate(`/signin/${gameData.joinCode}`)}>
                            Join this game with your email account
                            <FontAwesomeIcon icon={faEnvelope} size="2x" />
                        </button>
                    }
                    {
                        uploadList
                        .filter(p => !p.team)
                        .map(p => (
                            <button 
                                key={p.playerId} 
                                className={`g-button player-button`}
                                onClick={() => (p.enroll_method === 'email' ? navigate(`/signin/${gameData.joinCode}`) : setPlayerToSignIn(p))}
                            >
                                <div className='info-div'>
                                    {
                                        (p.icon)
                                        ?
                                        <ImageContainer src={p.icon} className='icon' alt='group icon' />
                                        :
                                        <div className='gradient' style={{'background': p.gradient}}>{p.fName[0].toUpperCase()}</div>
                                    }
                                    {p.displayName}
                                </div>
                                <div className='sign-in-text'>
                                    {p.enroll_method === 'email' ? <FontAwesomeIcon icon={faEnvelope} /> : 'Sign In'}
                                </div>
                            </button>
                        ))
                    }
                </div>
                :
                <div className='center-col'>
                    <h4>No Uploaded Users</h4>
                </div>
            }        
            <ModalAlert show={alertMessage} cancel={() => setAlertMessage(null)} message={alertMessage} />
        </div>
    )
}

export default JoinUploadList