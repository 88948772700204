import React, { useEffect } from 'react';
import './enrolled-container.styles.scss';
import AgeCheck from '@/components/layout-auth/age-check/age-check.component';
import { Link } from 'react-router-dom';
import EnrolledList from './enrolled-list.component';
import { useSelector } from 'react-redux';
import { changeDocumentTitle } from '@/utils/useDocumentTitle';

const EnrolledContainer = ({ enrolledList }) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const screenSize = useSelector(state => state.contentData.screenSize);

    useEffect(() => {
        changeDocumentTitle(`Gamably | Games I'm Playing`);
    }, [])
    return (
        <div className={`enrolled-container ${screenSize}`}>
            <AgeCheck />
            {/* <Layout> */}
            <div className={`enrolled-content  ${screenSize}`}>
                <div className='g-card'>
                    <div className={`section-title with-icon ${screenSize}`}>
                        <span>Games I'm Playing</span>
                        {
                            (userProfile) &&
                            <Link to='/home/joingame'>
                                <button className='g-button medium-btn'>Join a Game</button>    
                            </Link>
                        }
                    </div>
                    <hr className='no-top-margin' />
                    <EnrolledList enrolledList={enrolledList} />
                    
                </div>
            </div>
            {/* </Layout> */}
        </div>
    )
}

export default EnrolledContainer