import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import './loading-page.styles.scss'

import Loader from '@/components/loader/loader.comp'
import { useDispatch, useSelector } from 'react-redux'
import { UseCloudFunction, auth } from '@/utils/firebase.utils'
import { useNavigate, useParams } from 'react-router-dom'
import { setUserProfile } from '@/state/slices/userSlice'
import { onAuthStateChanged } from 'firebase/auth';
import { setCurrentUser } from '@/state/slices/userSlice';
import { getGameDataFromDb, getPlayerDataFromDb } from '@/utils/getDataFromDb'
import { ModalConfirm } from '@/components/modal/modal.component'
import { changeDocumentTitle } from '@/utils/useDocumentTitle'

const LoadingPage = () => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const currentUser = useSelector(state => state.userData.currentUser);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    // const { refreshProfile } = useParams();
    const { joinCode } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let gameListener;
    let playerListener;
    let timeoutRef;
    let timeoutRef2;
    let playerRef = useRef();
    let approveRef = useRef();  
    const paramSearch = new URLSearchParams(window.location.search)
    const [ joinNew ] = useState(paramSearch.get('join') === 'true');

    useLayoutEffect(() => {
        // change var(--primary) to default color
        changeDocumentTitle();
        var r = document.querySelector(':root');
        r.style.setProperty('--primary', '#674fa7');
        r.style.setProperty('--primary-trans', '#674fa7a6');
        signInUser();

        return () => {
            if (gameListener) gameListener();
            if (playerListener) playerListener();
            if (timeoutRef) clearTimeout(timeoutRef);
        }
    }, [])

    useEffect(() => {
        if (!currentUser) return;
        getUserProfile();
    }, [currentUser])

    useEffect(() => {
        if (!currentUser || !userProfile) {return;}
        console.log(userProfile);
        const now = new Date().getTime();
        const sixteenYrs = 31622400000 * 16;
        if (joinCode && joinNew) {
            navigate(`/home/joingame/${joinCode}`);
        }
        if (joinCode) {
            getGameData();
        } 
        else if (userProfile.birthday > now - sixteenYrs) {
            navigate('/games')
        } 
        else {
            navigate('/home');
        }
    }, [currentUser, userProfile])

    useEffect(() => {
        if (!joinCode) return;
        timeoutRef = setTimeout(() => {
            if (!playerRef.current) {
                navigate(`/home/joingame/${joinCode}`)
            }
        }, 3000)
    }, [joinCode])

    async function getUserProfile() {
        if (!userProfile) {  // refreshProfile && refreshProfile === 'refresh' ||   
            console.log('loading')
            const profile = await UseCloudFunction('signIn', {'email': currentUser.email});
            console.log(profile);
            if (profile.profile && profile.profile.fName) {
                dispatch(setUserProfile(profile.profile))
            } else {
                let tempProfile = JSON.parse(window.localStorage.getItem('tempProfile'));
                if (tempProfile) {
                    tempProfile = {...tempProfile, ...profile.profile};
                } else {
                    tempProfile = {...profile.profile}
                }
                window.localStorage.setItem('tempProfile', JSON.stringify(tempProfile))               
                navigate(`/profile${joinCode ? `/${joinCode}` : ''}`);
            }
        } 
    }

    function signInUser() {
        const currentlySignedInUser = auth.currentUser;
        console.log(currentlySignedInUser);
        
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                // console.log(user)
                console.log('app.jsx')
                saveUserToState(user)
                // if (joinCode) {
                //     getGameData();
                // } else {
                //     navigate('/home')
                // }
            } else {
                if (currentUser) {
                    navigate('/logout')
                }
            }
        });
        // if (!currentlySignedInUser) {
        //     navigate('/logout');
        //     return;
        // }
    }

    function getGameData() {
        getGameDataFromDb({'joinCode': joinCode, 'status': 'active', 'callback': callback, 'handleListener': handleListener})
        function callback(data) {
            console.log(data);
            getPlayerData(data);
        }
        function handleListener(unsub) {
            gameListener = unsub;
        }
    }

    function getPlayerData(gameData) {
        getPlayerDataFromDb({'appId': userProfile.appId, 'gameId': gameData.gameId, 'callback': callback, 'handleListener': handleListener})
        function callback(data) {
            console.log(data);
            playerRef.current = data;
            if (timeoutRef) clearTimeout(timeoutRef);
            navigate(`/play/${gameData.path}`)
        }
        function handleListener(unsub) {
            playerListener = unsub;
        }
    }

    function saveUserToState(user) {
        if (!user) {
            navigate('/logout');
            return;
        }
        const newUser = {
            displayName: user.displayName,
            email: user.email,
            metadata: {
            createdAt: Number(user.metadata.createdAt),
            lastLogInAt: user.metadata.lastLogInAt
            },
            uid: user.uid,
            photoURL: user.photoURL,
            provider: user.providerData.length > 0 ? user.providerData[0].providerId : 'FirestoreAuth'
        }
        dispatch(setCurrentUser({...newUser}));
    }

    return (
        <div className='loading-page'>
            {/* <div className='loader-icon'>
                <img src={require('assets/icon.png')} />
            </div> */}
            <Loader />  
            <ModalConfirm show={confirmMessage} cancel={() => setConfirmMessage(null)} message={confirmMessage} onApprove={approveRef.current} />
        </div>
    )
}

export default LoadingPage