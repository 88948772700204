import { format } from "date-fns";

export function checkElementAccess(e) {
    // console.log(e);
    const player = e.playerData;
    const gameElements = e.gameElements;
    const elementId = e.elementId;
    const gameStats = e.gameStats;
    let accessArr = [];
    const now = new Date().getTime();
    const element = gameElements[elementId];
    // console.log(element);
    if (!element) return null;
    let remainingInd;
    let remainingTotal;

    // check item count access
    if (element.opt_limitInd) {
        if (player.items && player.items[elementId]) {
            if (player.items[elementId].count >= element.opt_limitInd) {
                remainingInd = 0;
            } else {
                remainingInd = element.opt_limitInd - player.items[elementId].count;
            }
        }
    }   

    if (element.opt_limitTotal) {
        if (gameStats && gameStats[elementId]) {
            if (gameStats[elementId] >= element.opt_limitTotal) {
                remainingTotal = 0;
            } else {
                remainingTotal = element.opt_limitTotal - gameStats[elementId];
            }
        }
    }

    // check open date
    if (
        element.opt_dateOpen &&
        now < element.opt_dateOpen
    ) accessArr.push(`The element is not available until ${format(new Date(element.opt_dateOpen), 'Pp')}.`);

    // check close date
    if (
        element.opt_dateClose &&
        now > element.opt_dateClose
    ) accessArr.push(`The element closed on ${format(new Date(element.opt_dateClose), 'Pp')}.`);
    
    // check points
    if (
        element.opt_prereqPoints &&
        player.points < element.opt_prereqPoints
    ) accessArr.push(`You do not have the required ${element.opt_prereqPoints} XP.`);

    // check level
    if (
        element.opt_prereqLevel &&
        (
            !player.levels ||
            !player.levels[element.opt_prereqLevel]
        )
    ) accessArr.push(`You have not reached the ${gameElements[element.opt_prereqLevel].name} Level yet.`);
    
    // check badges
    if (element.opt_prereqBadges) {
        for (let badgeId of element.opt_prereqBadges) {
            if (
                !player.badges ||
                !player.badges[badgeId]
            ) accessArr.push(`You have not earned the required ${gameElements[badgeId].name} Badge yet.`);
        }
    }

    // check items
    if (element.opt_prereqItems) {
        for (let itemId of element.opt_prereqItems) {
            if (
                !player.items ||
                !player.items[itemId] || 
                player.items[itemId].count < 1
            ) accessArr.push(`You do not own the required ${gameElements[itemId].name} Item.`);
        }
    }

    // check teams
    if (element.opt_prereqTeams) {
        
    }

    // if nothing else but !opt_available
    if (accessArr.length === 0 && !element.opt_available) {
        accessArr.push(`The element is no longer available.`);
    }

    let response = {'arr': accessArr}
    if (!isNaN(remainingInd)) {
        response.remainingInd = remainingInd;
    }
    if (!isNaN(remainingTotal)) {
        response.remainingTotal = remainingTotal;
    }

    return response;
}