import React, { useEffect, useLayoutEffect, useState } from 'react'
import './manage-head-bar.styles.scss'

import { useSelector } from 'react-redux';
import UserDropdown from './manage-user-dropdown.component';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import UserIcon from '@/components/layout-auth/user-icon/user-icon.component';
import gamably_icon from '@/assets/icon.png';
import gamably_icon_grey from '@/assets/icon-greyscale.png';

const ManageHeadBar = ({ gameData }) => {

    const currentUser = useSelector(state => state.userData.currentUser);
    const userProfile = useSelector(state => state.userData.userProfile);
    const [ showDropdown, setShowDropdown ] = useState(false);
    const [ pageTitle, setPageTitle ] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!location || !gameData) {return;}
        let match;
        if (location.pathname === `/manage/${gameData.path}`) {
            match = 1;
        } else if (location.pathname.includes(`/manage/${gameData.path}/players`)) {
            match = 2;
        } else if (location.pathname.includes(`/manage/${gameData.path}/elements`)) {
            match = 3;
        } else if (location.pathname.includes(`/manage/${gameData.path}/leaderboards`)) {
            match = 4;
        } else if (location.pathname.includes(`/manage/${gameData.path}/actionitems`)) {
            match = 5;
        } else if (location.pathname.includes(`/manage/${gameData.path}/distribute`)) {
            match = 6;
        } else if (location.pathname.includes(`/manage/${gameData.path}/settings`)) {
            match = 7;
        } else if (location.pathname.includes(`/manage/${gameData.path}/store`)) {
            match = 8;
        } else if (location.pathname.includes(`/manage/${gameData.path}/teams`)) {
            match = 9;
        } else if (location.pathname.includes(`/manage/${gameData.path}/activity`)) {
            match = 10;
        } else if (location.pathname.includes(`/manage/${gameData.path}/realworlddist`)) {
            match = 11;
        } else if (location.pathname.includes(`/manage/${gameData.path}/contributions`)) {
            match = 12;
        } else if (location.pathname.includes(`/manage/${gameData.path}/lottery`)) {
            match = 13;
        }
        
        switch (match) {
            case 1:
                setPageTitle('Game Management');
                break;
            case 2:
                setPageTitle('Manage Players');
                break;
            case 3:
                setPageTitle('Manage Game Elements');
                break;
            case 4:
                setPageTitle('Manage Leaderboards');
                break;
            case 5:
                setPageTitle('Manage Action Items');
                break;
            case 6:
                setPageTitle('Distribute Rewards');
                break;
            case 7:
                setPageTitle('Game Settings');
                break;
            case 8:
                setPageTitle('Manage the Marketplace');
                break;
            case 9:
                setPageTitle('Manage Teams');
                break;
            case 10:
                setPageTitle('Game Activity');
                break;
            case 11:
                setPageTitle('Real World Item Distribution');
                break;
            case 12:
                setPageTitle('Manage Contributions');
                break;
            case 13:
                setPageTitle('Lottery Manager');
                break;
            default:
                setPageTitle('');
                break;
        }
    }, [location, gameData])

    return (
        <div className="manage-head-bar-container">
            <div className="logo-container">
                <Link to={'/loading'}>
                    <img src={gamably_icon_grey} alt="Logo" className="logo" />
                </Link>
                {
                    (pageTitle) &&
                    <>
                    <div className='menu-spacer'></div>
                    <h3>{pageTitle}</h3>
                    </>
                }
            </div>
            {/* <SearchContainer setShowSearchModal={setShowSearchModal} location='head-bar' /> */}
            <div className='head-menu-box'>
                <div className='head-menu-flex'>
                    <div className='head-menu'>
                        {/* <div className='head-menu-item' onClick={() => navigate('/coach')}>
                            Coach Home
                        </div>
                        <div className='menu-spacer'></div>
                        <div className='head-menu-item' onClick={() => navigate('/manage')}>
                            Manage Org
                        </div>
                        <div className='menu-spacer'></div>
                        <div className='head-menu-item' onClick={() => setShowMyOrgs(true)}>
                            Switch Organization
                        </div>
                        <div className='menu-spacer'></div> */}
                        {
                            (userProfile && userProfile.admin) &&
                                <>
                                    
                                    <div className='head-menu-item' onClick={() => navigate('/admin')}>
                                        Admin
                                    </div>
                                    <div className='menu-spacer'></div>
                                </>
                        }
                    </div>
                    <button className='head-user-info g-button text-only' onClick={() => setShowDropdown(!showDropdown)}>
                        {
                            (currentUser && userProfile) &&
                            <>
                                <div>{userProfile.fName}{userProfile.lName ? ` ${userProfile.lName}` : ''}</div>
                                <div className='user-icon' >
                                    <UserIcon profile={userProfile} reverse={true} planIcon={true} />
                                </div>

                            </>
                            
                        }
                    </button>
                </div>
                <UserDropdown show={showDropdown} />
            </div>
        </div>
    )
}

export default ManageHeadBar;