import React, { useEffect, useState } from 'react'
import './levels-list.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPie, faPen, faPlus } from '@fortawesome/free-solid-svg-icons';
import SearchBar from '@/components/SHARED/search-bar/search-bar.component';
import { format } from 'date-fns';
import { stripHtml } from '@/utils/sanitize';
import { useNavigate } from 'react-router-dom';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import { membershipRestrictions, testMemberRestrictions } from '@/utils/membershipRestrictions';
import { useSelector } from 'react-redux';
import UpgradeRequiredModal from '@/components/SHARED/upgrade-required-modal/upgrade-required-modal.component';

const LevelsList = ({ gameElements, gameData }) => {
    
    const membership = useSelector(state => state.userData.membership);
    const [ contentToDisplay, setContentToDisplay ] = useState([]);
    const [ showUpgradeModal, setShowUpgradeModal ] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!gameElements) {return;}
        setContentToDisplay(Object.values(gameElements).filter(a => a.type === 'level'))
    }, [gameElements])

    function checkMembership(e) {
        const res = testMemberRestrictions({'gameData':gameData, 'membership':membership, 'create': true});
        if (res.disabled && res.disabledReason.includes('elements')) {
            const planRest = membershipRestrictions[membership.membership];
            const message = `While using the ${planRest.title}, you are only able to have ${planRest.elementCount} active Game Elements per game.`;
            setShowUpgradeModal(message);
            return;
        }
        navigate('create');
    }

    function search(e) {
        if (!e) {
            setContentToDisplay(Object.values(gameElements).filter(a => a.type === 'level'))
            return;
        }
        setContentToDisplay(Object.values(gameElements).filter(a => a.type === 'level' && (a.name.toLowerCase().includes(e) || a.desc && a.desc.toLowerCase().includes(e))))
    }
    
    return (
        <div className='levels-list'>
            <div className='levels-list-search'>
                <SearchBar search={search} />
                
                <button type='button' className='g-button med-btn' onClick={() => checkMembership()}>Create a New Level<FontAwesomeIcon icon={faPlus} /></button>
            </div>
            <div className="g-space-2"></div>
            <div className='levels-list-div'>
                <div className='g-list-item level-list-head row'>
                    <div></div>
                    <div>Name</div>
                    <div>Description</div>
                    <div>Min XP</div>
                    <div>Created</div>
                    <div>Edited</div>
                    <div></div>
                </div>
                <div className='g-space-0-5'></div>
                <div className='level-list'>
                    {
                        (contentToDisplay.length > 0)
                        ?
                        contentToDisplay.sort((a,b) => a.opt_pointMinimum > b.opt_pointMinimum ? 1 : -1)
                        .map(c => (
                            <div 
                                key={c.id} 
                                className='g-list-item row'>
                                <ImageContainer 
                                    src={c.icon} 
                                    alt={`${c.name} level icon`} 
                                    className='level-icon' />
                                <div className='level-name'>
                                    {c.name}
                                </div>
                                <div className='level-desc meta'>
                                    {c.desc && stripHtml(c.desc).substring(0,60)}
                                    {c.desc && stripHtml(c.desc).length > 60 ? '...' : ''}
                                </div>
                                <div className='meta'>
                                    {c.opt_pointMinimum}
                                </div>
                                <div className='meta'>
                                    {
                                        c.ts_created 
                                        ? format(new Date(c.ts_created), 'P') 
                                        : format(new Date(c.created), 'P')}
                                </div>
                                <div className='meta'>
                                    {format(new Date(c.ts), 'P')}
                                </div>
                                <div className='buttons'>    
                                    <button 
                                        className='g-button med-btn' 
                                        title='View level data'
                                        onClick={() => navigate(`../data/${c.path}`)}>
                                        <FontAwesomeIcon icon={faChartPie} />
                                    </button>
                                    <button 
                                        className='g-button med-btn' 
                                        onClick={() => navigate(`edit/${c.path}`)} 
                                        title='Edit this level'>
                                        <FontAwesomeIcon icon={faPen} />
                                    </button>
                                </div>
                            </div>
                        ))
                        :
                        <p>No Levels yet...</p>
                    }
                </div>
            </div>
            <UpgradeRequiredModal
                show={showUpgradeModal}
                cancel={() => setShowUpgradeModal(false)}
                closeButton={true}
                message={showUpgradeModal}
            />
        </div>
    )
}

export default LevelsList