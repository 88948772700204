import React, { useEffect, useRef, useState } from 'react'
import './market.styles.scss';
import coin from '@/assets/icons/coin.png'
import ToggleInput from '@/components/SHARED/toggle-input/toggle-input.component';
import flatpickr from 'flatpickr';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';

const ManageContentMarket = ({ type, formData, saveData, gameElements }) => {
    
    const [ disabled, setDisabled ] = useState(true);

    useEffect(() => {
        setDisabled(!formData.opt_market);
        if (!formData.opt_market) {
            saveData('opt_marketPrice', null);
        }
    }, [formData.opt_market])
    
    return (
        <div className='manage-content-market g-list-item form-section'>
            <h3>Put this item up for sale in the Marketplace</h3>
            <hr />
            <div className="market-content">
                <div className='market-toggle'>
                    <div className='toggle-with-text'>
                        <ToggleInput 
                            contentKey="opt_market" 
                            checked={formData.opt_market} 
                            saveData={saveData} 
                        />
                        <div className='toggle-text'>
                            This Item is listed in the Marketplace. 
                        </div>
                    </div>
                </div>
                {/* <div className='market-toggle'>
                    <div className='toggle-with-text'>
                        <ToggleInput 
                            contentKey="opt_available" 
                            checked={
                                formData.opt_available &&
                                (
                                    (!formData.opt_dateOpen || formData.opt_dateOpen < new Date().getTime()) &&
                                    (!formData.opt_dateClose || formData.opt_dateClose > new Date().getTime())
                                )
                            } 
                            saveData={saveData} 
                        />
                        <div className='toggle-text'>
                            This Item is available to be purchased. 
                        </div>
                    </div>
                </div> */}
                <div className='market-price'>
                {
                    (formData.opt_itemType !== 'contributiona') &&
                    <div className={`field small ${disabled ? 'disabled' : ''}`}>
                        <label>How much should this item cost?<span className='required'>*</span></label>
                        <div className='input-with-icon'>
                            <img src={coin}  />
                            <input 
                                type='number' 
                                className='short-input' 
                                placeholder='Cost' 
                                value={formData.opt_marketPrice ?? ''} 
                                onChange={(e) => saveData('opt_marketPrice', Number(e.target.value))} 
                            />
                        </div>
                    </div>
                }
                </div>
                {/* <div className='market-dates'>
                    <div className={disabled ? 'disabled' : ''}>
                        <h3>Open Date</h3>
                        <div className="g-space-0-5"></div>
                        <div className='field small'>
                            <input 
                                className="flatpickr flatpickr-input" 
                                type="text" 
                                id="marketStartDate" 
                                placeholder='Start Date' 
                            />
                            <label>Leave blank if you want it available immediately.</label>
                        </div>
                    </div>
                    <div className={disabled ? 'disabled' : ''}>
                        <h3>Close Date</h3>
                        <div className="g-space-0-5"></div>
                        <div className='field small'>
                            <input 
                                className="flatpickr flatpickr-input" 
                                type="text" 
                                id="marketEndDate" 
                                placeholder='End Date' 
                            />
                            <label>Leave blank if you want it available indefinitely.</label>
                        </div>
                    </div>
                </div> */}
            </div>
            <div className='g-space-1'></div>
        </div>
    )
}

export default ManageContentMarket;