import React, { useEffect, useState } from 'react'
import './changelog.styles.scss';
import text from '@/changelog.txt'
import UnauthHeader from '@/components/layout-unauth/unauth-head.component';
import UnauthLayout from '@/components/layout-unauth/unauth-layout.component';
import { changeDocumentTitle } from '@/utils/useDocumentTitle';

const ChangeLog = () => {
    
    const [ displayText, setDisplayText ] = useState(null);

    useEffect(() => {
        changeDocumentTitle();
        fetch(text)
        .then(r => r.text())
        .then(transformed => {
            setDisplayText(transformed);
        })
    }, [])

    return (
        <div className='changelog'>
            <UnauthLayout>
            <div className='changelog-content'>
                <div className='card-container'>
                    <div className='g-card'>
                        {displayText}
                    </div>
                </div>
            </div>
            </UnauthLayout>
        </div>
    )
}

export default ChangeLog    