import React, { useEffect, useState } from 'react'
import './my-currency-earnings-list.styles.scss';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import { format } from 'date-fns';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import { useNavigate } from 'react-router-dom';
import PlayerNameIcon from '../player-name-icon.component';

const MyCurrencyEarningsList = ({ 
    gameData,
    playerData, 
    gameElements, 
    playerActivity, 
    total
}) => {
    
    const [ loading, setLoading ] = useState(true);
    const [ sortedList, setSortedList ] = useState([]);
    const navigate = useNavigate();
    
    useEffect(() => {
        const timer = setTimeout(() => {
            if (!playerData || !playerActivity) {
                setLoading(false);
            }
        }, 3000)

        return () => {
            if (timer) clearTimeout(timer);
        }
    }, [])

    useEffect(() => {
        console.log(playerActivity);
        // console.log(playerData);
        // console.log(gameElements);
        if (!playerData || !gameElements || !playerActivity) return;
        let activityArr = [];
        for (let activity of Object.values(playerActivity)) {
            if (!activity.data) continue;
            if (activity.type === 'purchase') {
                activityArr.push(activity);
                continue;
            }
            for (let data of Object.values(activity.data)) {
                if (data.type === 'currency') {
                    activityArr.push(activity);
                    continue;
                }
            }
        }
        activityArr.sort((a,b) => a.ts < b.ts ? 1 : -1)
        setSortedList(activityArr);
        setLoading(false);
    }, [playerData, gameElements, playerActivity])

    return (
        <div className='currency-earnings-list'>
            <div className='g-card list-card'>
                <BackButton cancel={() => navigate(-1)} />
                <div>
                    <div className='card-title split'>
                        My {total ? 'Total Currency Earnings' : 'Current Statement'}
                        {
                            (location.pathname.includes('/manage/')) &&
                            <PlayerNameIcon player={playerData} />
                        }
                    </div>
                    <div className="g-space-1"></div>
                    <hr className='no-margin'/>
                    <div className="g-space-0-5"></div>
                    {
                        (playerData) &&
                        <div className='summary meta'>
                            <div className='info'>
                                Current Balance: 
                                <ImageContainer src='/images/icons/coin.png' className='summary-icon' />
                                <b>{playerData.currency.toLocaleString()}</b>
                            </div>
                            <div className='info'>
                                Total Earnings: 
                                <ImageContainer src='/images/icons/coin.png' className='summary-icon' />
                                <b>{playerData.currencyTotal.toLocaleString()}</b>
                            </div>
                        </div>
                    }
                </div>
                {
                    (loading)
                    ?
                    <div className='center-col'><ThreeDotsLoader /></div>
                    :
                    (sortedList)
                    ?
                    sortedList.map(a => {
                        if (a.type === 'purchase' && !total) {
                            console.log(a);
                            return (
                                <div key={a.ts} className='g-list-item purchase-item'>
                                    <ImageContainer src={a.icon} className='icon' />
                                    <div className='info'>
                                        <div className='title'>
                                            <ImageContainer src='/images/icons/coin.png' />
                                            <div className='purchase'>
                                                -${a.cartTotal} Spent
                                            </div>
                                        </div>
                                        <div className='meta'>
                                            {a.event}
                                        </div>
                                        <div className='meta'>
                                            {format(new Date(a.ts), "Pp")}
                                        </div>
                                    </div>
                                    <div className='buttons'>
                                        <button 
                                            className='g-button small-btn'
                                            onClick={() => navigate(`/play/${gameData.path}/transaction/${a.path}`)}>View</button>
                                    </div>
                                </div>
                            )
                        } else if (a.type !== 'purchase') {
                            const data = Object.values(a.data).filter(a => a.type === 'currency')[0];
                            return (
                                <div key={a.ts} className='g-list-item currency-item'>
                                    <ImageContainer src={a.icon} className='icon' />
                                    <div className='info'>
                                        <div className='title'>
                                            <ImageContainer src='/images/icons/coin.png' />
                                            {data.title}
                                        </div>
                                        <div className='meta'>
                                            {a.event}<br />
                                            {format(new Date(a.ts), "Pp")}
                                        </div>
                                    </div>
                                    {
                                        (a.comments)
                                        ?
                                        <div className='comment'>
                                            Comments:<br /><b>"{a.comments}"</b>
                                        </div>
                                        :
                                        (a.info)
                                        ?
                                        <div className='comment'>
                                            Info:<br /><b>{a.info}</b>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            )
                        }
                    })
                    : 
                    <h4 className='center-col'>
                        No earnings yet...
                    </h4>
                }
            </div>
        </div>
    )
}

export default MyCurrencyEarningsList