import React, { useEffect, useRef, useState } from 'react'
import './player-view.styles.scss'

import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import PlayerStatusTable from '@/components/PLAYER/player-blocks/components/status-table.component'
import UserIcon from '@/components/layout-auth/user-icon/user-icon.component'
import BackButton from '@/components/SHARED/back-button/back-button.component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisVertical, faGem, faUserPen, faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { ModalAlert, ModalConfirm } from '@/components/modal/modal.component'
import { UseCloudFunction } from '@/utils/firebase.utils'
import MyXpEarningsList from '@/components/PLAYER/player-blocks/components/status-elements/xp/my-xp-earnings-list.component'
import MyBadges from '@/components/PLAYER/player-blocks/components/status-elements/badges/my-badges.component'
import BadgeView from '@/components/PLAYER/player-blocks/components/status-elements/badges/badge-view.component'
import MyLevelsList from '@/components/PLAYER/player-blocks/components/status-elements/levels/my-levels-list.component'
import MyCurrencyEarningsList from '@/components/PLAYER/player-blocks/components/status-elements/currency/my-currency-earnings-list.component'
import MySecretCodesList from '@/components/PLAYER/player-blocks/components/status-elements/secret-codes/secret-codes-list.component'
import MyChallengesList from '@/components/PLAYER/player-blocks/components/status-elements/challenges/my-challenges-list.component'
import ImageContainer from '@/components/SHARED/image-container/image-container.component'
import EditPlayer from './edit-player.component'
import DistributeIndividual from './distribute-individual.component'
import InventoryLanding from '@/components/PLAYER/inventory/inventory-landing.component'
import HiddenText from '@/components/SHARED/hidden-text/hidden-text.component'
import { setNewPasscode } from '@/state/slices/contentSlice'
import { getPlayerDataFromDb } from '@/utils/getDataFromDb'

const ManagerPlayerView = ({ 
    playerList, 
    gameElements, 
    gameData, 
    gameActivity, 
    setMainAlert, 
    gameStats,
    passcodes,
    role
}) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const [ playerData, setPlayerData ] = useState(null);
    const [ playerActivity, setPlayerActivity ] = useState({});
    const [ showDropdown, setShowDropdown ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const [ manager, setManager ] = useState(null);
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const root = useSelector(state => state.userData.root)
    const { playerPath } = useParams();
    const approveRef = useRef();
    const playerRef = useRef();
    const location = useLocation();
    let playerListener;

    // useEffect(() => {
    //     const timeout = setTimeout(() => {
    //         if (!playerRef.current) {
    //             navigate(`/404`);
    //         }
    //     }, 2000)

    //     return () => {
    //         if (timeout) clearTimeout(timeout);
    //     }
    // }, [])

    useEffect(() => {

        return () => {
            if (playerListener) playerListener();
        }
    }, [])

    useEffect(() => {
        if (!role || !playerPath) return;
        if (role === 'owner' || role === 'manager') {
            setManager(true);
        } else {
            getPlayerData();
        }
    }, [role, playerPath])

    useEffect(() => {
        // document.getElementById('scroll-point').scrollIntoView({'behavior': 'smooth', 'top': '-20px'});
        document.getElementById('scroll-to-top').scrollTo({'top': '0px', 'behavior': 'smooth'})
        
    }, [location])

    useEffect(() => {
        if (!playerList || !playerPath || !manager) return;
        const player = Object.values(playerList).filter(p => p.path === playerPath)[0];
        if (!player) return;
        
        playerRef.current = {...player};
        setPlayerData(playerRef.current);
        console.log(playerRef.current);

        // console.log(playerList[playerId]);
    }, [playerList, playerPath, manager])

    useEffect(() => {
        if (!gameActivity || !playerData) return;
        // console.log(gameActivity)
        const activityObj = {}
        Object.values(gameActivity)
        .filter(a => a.playerId === playerData.playerId)
        .map(a => {
            activityObj[a.id] = a
        })
        setPlayerActivity({...activityObj})
    }, [gameActivity, playerData])

    // get playerData from Database if distributor  
    function getPlayerData() {
        getPlayerDataFromDb({
            'playerPath': playerPath,
            'callback': callback,
            'handleListener': handleListener
        })
        function callback(data) {
            playerRef.current = {...data};
            setPlayerData(playerRef.current);
        }
        function handleListener(unsub) {
            playerListener = unsub;
        }
    }

    async function resetPasscode() {
        const res = await UseCloudFunction(
            'resetPasscode',
            {
                'playerData': playerData,
                'userProfile': userProfile
            }
        )
        console.log(res);
        if (res.error) {
            setAlertMessage('Sorry, something went wrong. Try again later. Error: ' + res.error);
            return;
        }
        setAlertMessage('Passcode reset successfully!');
        dispatch(setNewPasscode({
            'playerId': playerData.playerId,
            'code': res.passcode
        }))
        const resetTimer = setTimeout(() => {
            resetPlayerSignOut();
            clearTimeout(resetTimer);
        }, 5000)
        // clearTimeout(resetTimer);
    }

    async function resetPlayerSignOut() {
        const res = await UseCloudFunction('resetPlayerSignOut', {'playerId': playerData.playerId})
        console.log(res);
    }

    function cancel() {
        navigate(-1);
    }


    return (
        <div className='player-view-container'>
            <div id='scroll-to-top'></div>
            <Routes>
                <Route path='' element={
                    <div className='player-view-content'>
                        <div className='g-card'>
                            <div className='top-row'>
                                <BackButton cancel={cancel} />
                                {/* <button className='g-button text-only more-button' onClick={() => setShowDropdown(!showDropdown)}>
                                    <FontAwesomeIcon icon={faEllipsisVertical} className='clickable static' size='xl' />
                                </button>
                                <div className={`dropdown ${!showDropdown ? 'hidden' : ''}`}>
                                    <div className='content'>
                                        <button className='g-button text-only' onClick={() => clearNewStuff()}>Clear Rewards Notifications</button>
                                    </div>
                                </div> */}
                            </div>
                            <div className="g-space-20"></div>
                            {
                                (playerData) &&
                                <>
                                    <div className="g-space-20"></div>
                                    <div className='player-view-head'>                        
                                        <div className='player-head-text'>
                                            <UserIcon profile={playerData} large={true} noHover={true} />
                                            <div className='name'>
                                                {playerData.fName} {playerData.lName}
                                                {
                                                    (passcodes && passcodes[playerData.playerId]) &&
                                                    <div className='passcode-content'>
                                                        <div className='meta'>
                                                            <div>Passcode:</div> 
                                                        
                                                            <HiddenText 
                                                                text={passcodes[playerData.playerId]}
                                                                hidden={true} 
                                                                stars={4}
                                                            />
                                                            <button 
                                                                className='g-button text-only'
                                                                onClick={() => resetPasscode(playerData.playerId)}
                                                            >
                                                                New Code
                                                            </button>
                                                        </div>
                                                        
                                                    </div>
                                                }
                                            </div>
                                            {/* <div className='' */}
                                        </div>
                                        <div className='player-head-controls'>
                                            <button className="g-button med-btn" onClick={() => navigate('distribute')}>
                                                Distribute
                                                <FontAwesomeIcon icon={faGem} />
                                            </button>
                                            {
                                                (manager) &&
                                                <button className="g-button med-btn" onClick={() => navigate('edit')}>
                                                    Edit Player
                                                    <FontAwesomeIcon icon={faUserPen} />
                                                </button>
                                            }
                                        </div>
                                    </div>
                                    <div className="g-space-40"></div>
                                    <PlayerStatusTable role={role} playerData={playerData} gameData={gameData} gameElements={gameElements} playerActivity={playerActivity} />
                                </>
                            }
                            <div className='g-space-1'></div>
                            <div className='meta'>{playerData ? playerData.playerId : ''}</div>
                        </div>
                        <div className="g-space-2"></div>
                    </div>
                }></Route>
                <Route 
                    path='myxpearnings' 
                    element={<MyXpEarningsList playerData={playerData} gameElements={gameElements} playerActivity={playerActivity} />}>    
                </Route>
                <Route 
                    path='mybadges' 
                    element={<MyBadges playerData={playerData} gameElements={gameElements} playerActivity={playerActivity} />}>    
                </Route>
                <Route 
                    path='mybadges/:badgePath' 
                    element={<BadgeView playerData={playerData} gameElements={gameElements} playerActivity={playerActivity} />}>    
                </Route>
                <Route 
                    path='mylevels' 
                    element={<MyLevelsList playerData={playerData} gameElements={gameElements} playerActivity={playerActivity} />}>    
                </Route>
                <Route path='mycurrency' element={
                    <MyCurrencyEarningsList
                        playerData={playerData} 
                        gameElements={gameElements} 
                        playerActivity={playerActivity} 
                    />
                }></Route>
                <Route path='inventory/*' element={
                    <InventoryLanding
                        gameData={gameData}
                        playerData={playerData}
                        gameElements={gameElements}
                        playerActivity={playerActivity}
                        gameStats={gameStats}
                    />
                } ></Route>
                <Route path='mysecretcodes' element={
                    <MySecretCodesList
                        playerData={playerData} 
                        gameElements={gameElements} 
                        playerActivity={playerActivity} 
                    />
                }></Route>
                <Route path='mychallenges' element={
                    <MyChallengesList
                        gameData={gameData}
                        playerData={playerData} 
                        gameElements={gameElements} 
                        playerActivity={playerActivity} 
                    />
                }></Route>
                <Route path='edit' element={
                    <EditPlayer
                        playerData={playerData}
                        gameData={gameData}
                        setMainAlert={setMainAlert}
                    />
                }></Route>
                <Route path='distribute' element={
                    <div className='distribute-container'>
                        <div className='g-card'>
                            <BackButton cancel={() => navigate(-1)} />
                            <div className="g-space-1"></div>
                            <DistributeIndividual 
                                gameData={gameData}
                                gameElements={gameElements}
                                playerData={playerData}
                                setAlertMessage={setAlertMessage}
                            />
                        </div>
                        <div className="g-space-2"></div>
                    </div>
                }></Route>
            </Routes>
            <ModalAlert show={alertMessage} cancel={() => setAlertMessage(null)} message={alertMessage} />
            <ModalConfirm show={confirmMessage} cancel={() => setConfirmMessage(null)} message={confirmMessage} onApprove={approveRef.current} />
        </div>
    )
}

export default ManagerPlayerView;