import React from 'react';
import './manage-lottery-landing.styles.scss';
import { Route, Routes, useNavigate } from 'react-router-dom';
import LotteryList from './lottery-list.component';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTicket } from '@fortawesome/free-solid-svg-icons';
import LotteryView from './lottery-view.component';

const ManageLotteryLanding = ({
    gameData,
    gameActivity,
    playerList,
    gameStats,
    gameElements
}) => {

    const navigate = useNavigate();

    return (
        <div className='manage-lottery-landing'>
            <div className='g-card manage-lottery-card'>
                <BackButton cancel={() => navigate(-1)} />
                <div className="g-space-1"></div>
                <div className='card-title'>
                    <div className='title-with-icon'>
                        <FontAwesomeIcon icon={faTicket} />Lottery Manager
                    </div>
                </div>
                <hr />
                <Routes>
                    <Route path='' element={
                        <LotteryList
                            gameData={gameData}
                            gameElements={gameElements}
                            gameStats={gameStats}
                            playerList={playerList} />
                    }></Route>
                    <Route path=':lotteryPath' element={
                        <LotteryView
                            gameElements={gameElements}
                            gameStats={gameStats}
                            playerList={playerList} />
                    }></Route>
                </Routes>
            </div>
        </div>
    )
}

export default ManageLotteryLanding