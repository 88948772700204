import React, { useEffect } from 'react'
import "./init";
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import './index.css';
import { registerSW } from "virtual:pwa-register";

import { Provider } from 'react-redux'
import { store, persistor } from './state/store'
import { PersistGate } from 'redux-persist/integration/react'

import { BrowserRouter as Router } from 'react-router-dom'
import ReactGA from "react-ga4";
const gaTrackingID = "G-BMG0E5NLFS";


// add this to prompt for a refresh
const updateSW = registerSW({
    onNeedRefresh() {
        // if (confirm("New content available. Reload?")) {
            updateSW(true);
        // }
    },
});

ReactDOM.createRoot(document.getElementById('root')).render(
    // <React.StrictMode>
        <Provider store={store}>
            <Router>
                <PersistGate persistor={persistor}>
                    <App />
                </PersistGate>
            </Router>
        </Provider>
    // </React.StrictMode>,
)

// const SendAnalytics = ()=> {
    // ReactGA.send({
    //   hitType: "pageview",
    //   page: window.location.pathname,
    // });
// }