import React, { useEffect, useState } from 'react';
import './lottery-view.styles.scss';
import { useNavigate, useParams } from 'react-router-dom';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';

const LotteryView = ({
    gameElements,
    gameStats,
    playerList
}) => {

    const { lotteryPath } = useParams();
    const [ lottery, setLottery ] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (!gameElements || !lotteryPath) return;
        const lot = Object.values(gameElements)
        .filter(e => 
            e.opt_itemType === 'lottery' &&
            e.path === lotteryPath &&
            e.status === 'active'
        )[0]
        if (!lot) navigate(-1);
        setLottery(lot);
    }, [gameElements, lotteryPath])

    return (
        <div className='lottery-view'>
            {
                (lottery)
                ?
                <div className=''>
                    {lottery.name}
                </div>
                :
                <div className='center-col'>
                    <ThreeDotsLoader />
                </div>
                
            }
        </div>
    )
}

export default LotteryView