import React, { useState } from 'react';
import './item-display-card.styles.scss';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import ProgressBar from '@/components/SHARED/progress-bar/progress-bar.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExchange, faLayerGroup, faList } from '@fortawesome/free-solid-svg-icons';
import UseItemModal from './use-item-modal.component';
import { useLocation, useNavigate } from 'react-router-dom';

const ItemDisplayCard = ({ 
    gameData, 
    item, 
    gameStats, 
    playerData 
}) => {

    const [ showUseModal, setShowUseModal ] = useState(false);
    const [ showHistoryModal, setShowHistoryModal ] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <div className='item-display-card g-card'>
            {
                (item) 
                ?
                <div className='card-content'>
                    <div className='image-container'>
                        <ImageContainer
                            src={item.icon}
                            className='item-icon'
                        />
                    </div>
                    <div className='item-name'>{item.name}</div>
                    <div className='item-desc'>{item.desc}</div>
                    {
                        (item.opt_itemType === 'contribution') &&
                        <div className='contribution-progress'>
                            <div className="g-space-0-5"></div>
                            <div className='meta'>Contributions: {gameStats[`${item.id}_contributions`].toLocaleString()} / {item.opt_contributionGoal.toLocaleString()}</div>
                            <ProgressBar 
                                progressValue={(gameStats[`${item.id}_contributions`]/item.opt_contributionGoal) * 100}
                                itemId={item.id}
                            />
                            <div className="g-space-0-5"></div>
                        </div>
                    }
                    <div className='meta'>
                    {
                        (item.opt_cat) &&
                        <div>Category: {item.opt_cat}</div>
                    }
                    {
                        (item.opt_market && item.opt_marketPrice) &&
                        <div>Store Price: &#129689; {item.opt_marketPrice ?? 0}</div>
                    }
                    {
                        (item.opt_resale && item.opt_resalePrice) &&
                        <div>Resale Rewards: &#129689; {item.opt_resalePrice ?? 0}</div>
                    }
                    </div>
                    {
                        (item.opt_itemType === 'lottery') &&
                        <div>Tickets Earned: {gameStats[item.id] ? gameStats[item.id].toLocaleString() : 0}</div>
                    }
                    <div className='g-space-0-5'></div>
                    <div className='item-buttons'>
                        {
                            (
                                item.count && 
                                item.count > 0 && 
                                item.opt_itemType !== 'realworld' &&
                                item.opt_itemType !== 'contribution' &&
                                item.opt_itemType !== 'lottery' &&
                                item.opt_itemType !== 'collectible'
                            ) ?
                            <>
                            {
                                (
                                    !location.pathname.includes('/manage/') &&
                                    item.opt_usable && 
                                    playerData.items &&
                                    playerData.items[item.id] &&
                                    playerData.items[item.id].count > 0
                                ) &&
                                <button 
                                    className='g-button small-btn'
                                    onClick={() => setShowUseModal(true)}>
                                    <FontAwesomeIcon icon={faLayerGroup} />
                                    Use Item
                                </button>
                            }
                                {
                                    (
                                        !location.pathname.includes('/manage/') &&
                                        (
                                            item.opt_resale || 
                                            gameData.access.includes('tradePortal')
                                        )
                                    ) &&
                                    <button className='g-button med-btn'>
                                        <FontAwesomeIcon icon={faExchange} />
                                        Sell/Trade
                                    </button>
                                }
                            </>
                            : ''
                            
                        }
                        <button 
                            className='g-button small-btn'
                            onClick={() => navigate(`history/${item.path}`)}
                        >
                            <FontAwesomeIcon icon={faList} />
                            History
                        </button>
                    </div>
                </div>
                :
                <div className='card-content'>
                    <div className='image-container'>
                        <ImageContainer
                            src='/images/icons/item-placeholder.jpg'
                            className='item-icon'
                        />
                    </div>
                    <div className='item-name'>
                        Choose an Item
                    </div>
                    <div className='item-desc'>
                        For more information and actions
                    </div>
                </div>
            }
            <UseItemModal 
                show={showUseModal} 
                cancel={() => setShowUseModal(false)}
                gameData={gameData}
                selectedItem={item}
                playerData={playerData}
            />
        </div>
    )
}

export default ItemDisplayCard