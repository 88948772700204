import React, { useEffect } from 'react'
import './pricing-page.styles.scss';
import UnauthLayout from '@/components/layout-unauth/unauth-layout.component';
import PricingTable from '@/components/SHARED/pricing-table/pricing-table.component';
import UnauthHeader from '@/components/layout-unauth/unauth-head.component';
import Footer from '@/components/layout-unauth/footer/footer.component';
import { changeDocumentTitle } from '@/utils/useDocumentTitle';

const PricingPage = () => {

    useEffect(() => {
        changeDocumentTitle('Gamably | Pricing');
        var r = document.querySelector(':root');
        r.style.setProperty('--primary', '#674fa7');
        r.style.setProperty('--primary-trans', '#674fa7a6');
    }, [])

    return (
        <div className='pricing-page'>
            <UnauthHeader />
                <div className='pricing-page-container'>
                    <div className='g-space-2'></div>
                    <PricingTable />
                    <div className='g-space-2'></div>
                </div>
            <Footer />
        </div>
    )
}

export default PricingPage