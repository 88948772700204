import React, { useEffect, useState } from 'react'
import './manager-activity-list.styles.scss';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobeAmericas } from '@fortawesome/free-solid-svg-icons';
import DetailedActivityList from '@/components/SHARED/detailed-activity-list/detailed-activity-list.component';

const ManagerActivityList = ({ gameData, gameActivity, playerList, gameElements }) => {

    const [ activityToDisplay, setActivityToDisplay ] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (!gameActivity) return;
        const avail = Object.values(gameActivity)
        .filter(a => 
            a.display === 'reward' || 
            a.display === 'playerActivity' || 
            a.display === 'purchase' || 
            a.display === 'achievement' ||
            a.display === 'prizepack'
        )
        setActivityToDisplay([...avail]);
    }, [gameActivity])

    return (
        <div className='manager-activity-list'>
            <div className='manager-activity-content g-card'>
                <BackButton cancel={() => navigate(-1)} />
                <div className='g-space-1'></div>
                <div className='card-title'>
                    <FontAwesomeIcon icon={faGlobeAmericas} />
                    Game Activity
                </div>
                <hr />
                <DetailedActivityList
                    gameData={gameData}
                    activity={activityToDisplay}
                    playerList={playerList}
                    gameElements={gameElements}
                    manager={true}
                />
            </div>
        </div>
    )

}

export default ManagerActivityList  