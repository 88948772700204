import React, { useEffect, useRef, useState } from 'react';
import './detailed-activity-list.styles.scss';
import DetailedActivityCard from './detailed-activity-card.component';
import ImageContainer from '../image-container/image-container.component';
import LayeredIcons from '../layered-icons/layered-icons.component';
import { faArrowRight, faBan, faCheck, faDownload, faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import { useLocation, useNavigate } from 'react-router-dom';
import flatpickr from 'flatpickr';
import CsvDownload from 'react-json-to-csv';
import { sortPlayers } from '@/utils/sortPlayers';
import { useSelector } from 'react-redux';

const DetailedActivityList = ({ 
    gameData, 
    playerData,
    activity, 
    hidePlayer, 
    playerList, 
    gameElements, 
    manager 
}) => {

    const [ availableActivity, setAvailableActivity ] = useState([]);
    const [ activityToDisplay, setActivityToDisplay ] = useState([]);
    const [ totalCount, setTotalCount ] = useState(20);
    const [ more, setMore ] = useState(false);
    const [ filterData, setFilterData ] = useState({});
    const [ activityTypes, setActivityTypes ] = useState([]);
    const [ playersToDisplay, setPlayersToDisplay ] = useState([]);
    const [ elementsToDisplay, setElementsToDisplay ] = useState([]);
    const [ csvData, setCsvData ] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();
    const date1Ref = useRef();
    const date2Ref = useRef();
    const dataRef = useRef();

    useEffect(() => {
        const openDateElement = document.getElementById('startDate');
        if (!openDateElement) return;
        if (!date1Ref.current) {
            const flat1 = flatpickr(openDateElement, 
                {
                    altInput: true,
                    altFormat: "F j, Y",
                    enableTime: false,
                    dateFormat: "Y-m-d",
                    onChange: (dates) => {
                        const date = new Date(dates[0]).getTime()
                        saveData('startDate', date)
                    }
                }
            );
            date1Ref.current = flat1;
            // console.log('set fp')
        }
        const closeDateElement = document.getElementById('endDate');
        if (!date2Ref.current) {
            const flat2 = flatpickr(closeDateElement, 
                {
                    altInput: true,
                    altFormat: "F j, Y",
                    enableTime: false,
                    dateFormat: "Y-m-d",
                    onChange: (dates) => {
                        const date = new Date(dates[0]).getTime() + 86400000 - 1;
                        saveData('endDate', date)
                    }
                }
            );
            date2Ref.current = flat2;
            // console.log('set date2')
        }
        const savedCount = window.localStorage.getItem('totalCount');
        if (savedCount) setTotalCount(Number(savedCount));
        const filters = JSON.parse(window.localStorage.getItem('activity-list-filter'));
        if (filters) {
            dataRef.current = {...filters}
            setFilterData(dataRef.current);
            if (dataRef.current.startDate) {
                date1Ref.current.setDate(new Date(dataRef.current.startDate))
            }
            if (dataRef.current.endDate) {
                date2Ref.current.setDate(new Date(dataRef.current.endDate))
            }
        }
        return () => {
            if (
                !location.pathname.includes('/activity') &&
                !location.pathname.includes('/transaction/')
            ) {
                console.log('removed')
                localStorage.setItem('scrollpoint', 0);
                localStorage.setItem('totalCount', 20);
                localStorage.removeItem('activity-list-filter');
                
                
            }
        }
        // manage-landing-content
    }, [])

    useEffect(() => {
        if (!playerData) return;
        if (!manager) {
            saveData('playerId', playerData.playerId)
        }
    }, [playerData])

    useEffect(() => {
        if (!activity || !gameElements) return;
        let typeArr = [];
        let playerArr = [];
        let elementArr = ['points', 'currency'];
        for (let a of Object.values(activity)) {
            if (!typeArr.includes(a.type)) {
                typeArr.push(a.type);
            }
            if (!playerArr.includes(a.playerId) && playerList[a.playerId]) {
                playerArr.push(a.playerId);
            }
            if (!elementArr.includes(a.elementId)) {
                elementArr.push(a.elementId);
            }
            if (a.data && Object.values(a.data).length > 0) {
                for (let d of Object.values(a.data)) {
                    if (!elementArr.includes(d.id)) {
                        elementArr.push(a.elementId);
                    }
                }
            }
        }
        setActivityTypes([...typeArr]);
        let elementObj = {}
        for (let eId of elementArr) {
            if (gameElements[eId]) {
                elementObj[eId] = gameElements[eId];
            }
        }
        const sortedElements = Object.values(elementObj)
        .sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        setElementsToDisplay([...sortedElements]);
        let filteredActivity = Object.values(activity);
        if (!hidePlayer) {
            filteredActivity = [...filteredActivity.filter(a => a.type !== 'declinedChallenge' && a.type !== 'declinedItem')]
        }
        setAvailableActivity(filteredActivity);
        let playerObj = {}
        for (let pId of playerArr) {
            if (playerList[pId]) {
                playerObj[pId] = playerList[pId];
            }
        }
        const sortedPlayers = sortPlayers({
            'playerList': playerObj,
            'filter': 'players'
        });
        // console.log(sortedPlayers);
        setPlayersToDisplay([...sortedPlayers]);
    }, [activity, gameElements])

    useEffect(() => {
        if (availableActivity.length === 0) return;
        sortActivity();
    }, [availableActivity])

    useEffect(() => {
        if (!totalCount) return;
        sortActivity();
        localStorage.setItem('totalCount', totalCount);
    }, [totalCount])

    useEffect(() =>{
        if (!filterData) return;
        sortActivity();
    }, [filterData])

    useEffect(() => {
        const scrollheight = window.localStorage.getItem('scrollpoint');
        if (scrollheight) {
            console.log(scrollheight);
            const el = document.getElementById('scroll-to-top');
            console.log(el);
            if (el) {
                el.scrollTo({'top': scrollheight});
            }
        }
    }, [activityToDisplay])

    function saveData(key, value) {
        dataRef.current = {...dataRef.current, ...{[key]: value}};
        setFilterData(dataRef.current);
        window.localStorage.setItem('activity-list-filter', JSON.stringify(dataRef.current));
    }

    function displayActivity() {
        const trimmedArr = availableActivity.sort((a,b) => a.ts < b.ts ? 1 : -1).slice(0, totalCount)
        setActivityToDisplay([...trimmedArr]);
        sortActivity();
    }

    function loadMore() {
        setTotalCount(totalCount + 20);
    }

    function sortActivity() {
        let filteredActivity = availableActivity.sort((a,b) => a.ts < b.ts ? 1 : -1);
        if (filterData.playerId) {
            filteredActivity = filteredActivity.filter(a => a.playerId === filterData.playerId);
        }
        if (filterData.activityType) {
            filteredActivity = filteredActivity.filter(a => a.type === filterData.activityType);
        }
        if (filterData.elementId) {
            filteredActivity = filteredActivity.filter(a => 
                a.elementId === filterData.elementId || 
                (
                    a.data &&
                    Object.values(a.data)
                    .filter(d => d.id === filterData.elementId).length > 0
                )
            );
        }
        if (filterData.startDate) {
            filteredActivity = filteredActivity.filter(a => a.ts >= filterData.startDate);
        }
        if (filterData.endDate) {
            filteredActivity = filteredActivity.filter(a => a.ts <= filterData.endDate);
        }
        setActivityToDisplay([...filteredActivity.slice(0, totalCount)]);
        // console.log(filteredActivity.length, totalCount)
        if (filteredActivity.length > totalCount) {
            setMore(true);
        } else {
            setMore(false);
        }
        
    }

    function clearFilter() {
        date1Ref.current.clear();
        date2Ref.current.clear();
        filterData.current = {}
        setFilterData(filterData.current);
        
    }

    function viewTransaction(path) {
        const el = document.getElementById('scroll-to-top');
        console.log(el);
        if (el) window.localStorage.setItem('scrollpoint', el.scrollTop)
        if (manager) {
            navigate(`/manage/${gameData.path}/transaction/${path}`);
        } else {
            navigate(`/play/${gameData.path}/transaction/${path}`)
        }                                   
    }

    return (
        <div className='detailed-activity-list-container'>
            <div className='detailed-activity-list'>
                {
                    (!hidePlayer) &&
                    <div className='filter-row'>
                        {
                            (!location.pathname.includes('/play/')) &&
                            <div className='field small'>
                                <label>Player</label>
                                <select 
                                    value={filterData.playerId ?? ''}
                                    onChange={(e) => saveData('playerId', e.target.value)}
                                >
                                    <option value=''>Choose...</option>
                                    {
                                        (playersToDisplay && playerList) &&
                                        playersToDisplay.map(p => {
                                            return (
                                                <option key={p.playerId} value={p.playerId}>
                                                    {p.lName ? `${p.lName}, ` : ''}
                                                    {p.fName}
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        }
                        <div className='field small'>
                            <label>Activity Type</label>
                            <select 
                                value={filterData.activityType ?? ''}
                                onChange={(e) => saveData('activityType', e.target.value)}
                            >
                                <option value=''>Choose...</option>
                                {
                                    (activityTypes) &&
                                    activityTypes.map(t => (
                                        <option key={t} value={t}>{t}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className='field small'>
                            <label>Element</label>
                            <select
                                value={filterData.elementId ?? ''}
                                onChange={(e) => saveData('elementId', e.target.value)}
                            >
                                <option value=''>Choose...</option>
                                {
                                    (elementsToDisplay && gameElements) &&
                                    elementsToDisplay.map(i => (
                                        <option key={i.id} value={i.id}>{i.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className='field small'>
                            <label>Start Date</label>
                            <input 
                                className="flatpickr flatpickr-input" 
                                type="text" 
                                id="startDate" 
                                placeholder='Choose a date...' 
                            />
                        </div>
                        <div className='field small'>
                            <label>End Date</label>
                            <input 
                                className="flatpickr flatpickr-input" 
                                type="text" 
                                id="endDate" 
                                placeholder='Choose a date...' 
                            />
                        </div>
                        <div className='action-buttons'>
                            <button 
                                className='g-button med-btn'
                                onClick={() => clearFilter({})}
                            >
                                Clear
                            </button>
                            {/* {
                                (manager) &&
                                <CsvDownload 
                                    data={csvData}
                                    filename='GamablyPurchases.csv'
                                    className='g-button med-btn'
                                    delimiter=','
                                >
                                    <FontAwesomeIcon icon={faDownload} size='xl' />
                                </CsvDownload>
                            } */}
                        </div>
                    </div>
                }
                <div className="g-space-0-5"></div>
                <div className={`head-row g-list-item row ${
                    location.pathname.includes('/play/') || hidePlayer 
                    ? 'hide-player' 
                    : manager 
                    ? 'manager' 
                    : ''
                }`}>
                    <div className='icon'></div>
                    <div className='date'>Date</div>
                    {
                        (!hidePlayer && !location.pathname.includes('/play/')) &&
                        <div className='player'>Player</div>
                    }
                    <div className='activity'>Activity</div>
                    <div className='notes'>Notes</div>
                    <div className='actions'></div>

                </div>
                {
                    (activityToDisplay.length > 0)
                    ?
                    activityToDisplay.map(a => (
                        <div key={a.id} className={`data-row g-list-item row ${
                            location.pathname.includes('/play/') || hidePlayer 
                            ? 'hide-player' 
                            : manager 
                            ? 'manager' 
                            : ''
                        }`}>
                            <div className='icon'>
                                {
                                    (a.type === 'submittedItem')
                                    ?
                                    <div style={{'color': 'var(--item-color)'}}>
                                        <FontAwesomeIcon icon={faLayerGroup} size='2x' />
                                    </div>
                                    :
                                    (a.type === 'declinedItem')
                                    ?
                                    <LayeredIcons
                                        icon1={faLayerGroup}
                                        icon1Color='var(--item-color)'
                                        icon1Size='2x'
                                        icon2={faBan}
                                        icon2Color='red'
                                        icon2Size='2x'
                                    />
                                    :
                                    (a.type === 'approvedItem')
                                    ?
                                    <LayeredIcons
                                        icon1={faLayerGroup}
                                        icon1Size='2x'
                                        icon1Color='var(--item-color)'
                                        icon2={faCheck}
                                        icon2Color='lightgreen'
                                        icon2Size='2x'
                                    />
                                    :
                                    <ImageContainer src={a.icon} />
                                }
                            </div>
                            <div className='date'>{format(new Date(a.ts), "P")}</div>
                            {
                                (!hidePlayer && !location.pathname.includes('/play/')) &&
                                <div className='player'>{playerList && a.playerId && playerList[a.playerId] && playerList[a.playerId].displayName}</div>
                            }
                            <div className='activity'>
                                {a.event}
                            </div>
                            <div className='notes'>
                                {
                                    (a.data && Object.values(a.data).length > 0) &&
                                    <div>{Object.values(a.data).length} Total Rewards</div>
                                }
                                {
                                    (a.comments && hidePlayer) &&
                                    <div>Comments: "{a.comments}"</div>
                                }
                                {
                                    (a.type === 'purchase') &&
                                    <div>
                                        <div className='item-cost'>Total Cost: <ImageContainer src='/images/icons/coin.png' className='coin-icon' /> {a.cartTotal}</div> <div>Total Items: {a.cartQuantity}</div>
                                    </div>
                                }
                                { 
                                    (a.elementId && gameElements && gameElements[a.elementId]) &&
                                    <div>({gameElements[a.elementId] ? gameElements[a.elementId].name : ''})</div>
                                }
                            </div>
                            {
                                (hidePlayer || manager || a.playerId === playerData.playerId) &&
                                <div className='actions'>
                                    {
                                        (a.type === 'purchase' || a.type === 'rewards' || (a.data && Object.values(a.data).length > 0)) &&
                                        <div className='buttons'>
                                            <button 
                                                className='g-button small-btn'
                                                onClick={() => viewTransaction(a.path)}>
                                                View
                                            </button>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    ))
                    :
                    <div className='center-col'>
                        <h4>No Activity to View</h4>
                    </div>
                }
                {
                    (more) &&
                    <div className='center-col'>
                        <div className="g-space-1"></div>
                        <button 
                            className='g-button small-btn'
                            onClick={() => loadMore()}
                        >
                            Load More...
                        </button>
                    </div>
                }
            </div>
        </div>
    )
}

export default DetailedActivityList;