import React, { useEffect } from 'react'
import './logout-page.styles.scss';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { revertAll } from '@/state/slices/userSlice';
import Loader from '@/components/loader/loader.comp';
import { signOut } from '@/utils/firebase.utils';
import { changeDocumentTitle } from '@/utils/useDocumentTitle';

const LogoutPage = () => {
    
    const [ searchParams, setSearchParams ] = useSearchParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        changeDocumentTitle();
        async function removeUser() {
            dispatch(revertAll())
            await signOut();
            setTimeout(() => {
                const url = searchParams.get('redirectUrl');
                if (url) {
                    console.log(url)
                    navigate(url);
                } else {
                    navigate('/')
                }
                }, 1000);
        }
        removeUser();

    }, [])

    return (
        <div className='logout-page'>
            <Loader />  
        </div>
    )
}

export default LogoutPage