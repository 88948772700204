import React, { useEffect, useRef, useState } from 'react'
import './collections.styles.scss'
import ImageContainer from '@/components/SHARED/image-container/image-container.component';

const MangageContentCollections = ({ 
    type,       // str
    formData,   // obj
    saveData,   // fn
    collections // arr
}) => {

    const [ selectedCollections, setSelectedCollections ] = useState([]);
    const selectedRef = useRef([]);
    const collectionsRef = useRef([]);

    useEffect(() => {
        if (!formData || !collections) return;
        if (formData.opt_collections) {
            collectionsRef.current = [...formData.opt_collections];
        }
        else if (collections.length > 0) {
            let idsFromCollection = []
            for (let coll of collections) {
                if (
                    formData.id && 
                    coll.opt_itemList &&
                    coll.opt_itemList.includes(formData.id)
                ) {
                    idsFromCollection.push(coll.id);
                }
            }
            collectionsRef.current = [
                ...collectionsRef.current,
                ...idsFromCollection
            ]
            saveData('opt_collections', [...collectionsRef.current]);
        }
        setSelectedCollections(collectionsRef.current);
    }, [formData, collections])

    function chooseMultipleOption(id) {
        let tempList = [...collectionsRef.current];
        if (tempList.includes(id)) {
            tempList.splice(tempList.indexOf(id), 1);   
        } else {
            const coll = collections.filter(c => c.id === id)[0];
            if (coll) {
                tempList.push(coll.id);
            }
        }
        collectionsRef.current = [...tempList];
        saveData('opt_collections', collectionsRef.current);
    }

    return (
        <div className='manage-content-collections g-list-item form-section'>
            <h3>Collections</h3>
            <hr />
            <div className="collections-content">
                <div className='field small'>
                    <label>Choose any collections to which you want to add this item.</label>
                    <div className='g-list-item'>
                        <div className='multiple-select'>
                        {
                            (collections.length > 0) 
                            ?
                            collections.sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                            .map(c => (
                                <div 
                                    key={c.id} 
                                    className={`multiple-select-option ${selectedCollections.includes(c.id) ? 'active' : ''}`} 
                                    onClick={() => chooseMultipleOption(c.id)}
                                >
                                    <ImageContainer src={c.icon} alt={`${c.name} collection icon`} />
                                    {c.name}
                                </div>
                            ))
                            :
                            <span>No Collections Yet...</span>
                        }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MangageContentCollections;