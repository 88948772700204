import React, { useEffect, useRef, useState } from 'react'
import './items-list.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPie, faFilter, faPen, faPlus, faUpload } from '@fortawesome/free-solid-svg-icons';
import SearchBar from '@/components/SHARED/search-bar/search-bar.component';
import CategoryFilterModal from '../components/category-filter-modal.component';
import { format } from 'date-fns';
import { stripHtml } from '@/utils/sanitize';
import { useLocation, useNavigate } from 'react-router-dom';
import VisibilityButton from '../components/visibility-button.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import UpgradeRequiredModal from '@/components/SHARED/upgrade-required-modal/upgrade-required-modal.component';
import { membershipRestrictions, testMemberRestrictions } from '@/utils/membershipRestrictions';
import { useSelector } from 'react-redux';
import ItemsUploadModal from './items-upload-modal.component';
import ItemSearchBar from '@/components/SHARED/search-bar/item-search-bar.component';

const ItemsList = ({ gameElements, gameData }) => {
    
    const membership = useSelector(state => state.userData.membership);
    const [ availableItems, setAvailableItems ] = useState([]);
    const [ contentToDisplay, setContentToDisplay ] = useState([]);
    const [ showUpgradeModal, setShowUpgradeModal ] = useState(false);
    
    const catRef = useRef();
    const typeRef = useRef();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        catRef.current = [];
        typeRef.current = []
    }, [])

    useEffect(() => {
        if (!gameElements) {return;}
        const avail = Object.values(gameElements).filter(a => 
            a.type === 'item' && 
            a.opt_itemType !== 'prizepack'
        );
        setContentToDisplay([...avail]);
        setAvailableItems([...avail]);
    }, [gameElements])

    return (
        <div className='item-list'>
            <ItemSearchBar
                availableItems={availableItems}
                setItemsToDisplay={setContentToDisplay}
            />
            <div className="g-space-1"></div>
            <div className='item-list-div'>
                <div className='g-list-item item-list-head row'>
                    <div></div>
                    <div>Name</div>
                    <div>Type</div>
                    <div>Description</div>
                    <div>Closes</div>
                    <div></div>
                </div>
                <div className='g-space-0-5'></div>
                <div className='item-list'>
                    {
                        (contentToDisplay.length > 0)
                        ?
                        contentToDisplay.sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                        .map(c => (
                            <div key={c.id} className='g-list-item row'>
                                <div className='item-icon-div'>
                                    <ImageContainer 
                                        src={c.icon} 
                                        alt={`${c.name} item icon`} 
                                        className='item-icon' 
                                    />
                                </div>
                                <div className='item-name'>{c.name}</div>
                                <div className='item-type meta'>{c.opt_itemType}</div>
                                <div className='item-desc meta'>{c.desc && stripHtml(c.desc).substring(0,60)}{c.desc && stripHtml(c.desc).length > 60 ? '...' : ''}</div>
                                <div className='meta'>{c.opt_dateClose ? format(new Date(c.opt_dateClose), 'P') : "Never"}</div>
                                <div className='buttons'>
                                    <VisibilityButton element={c} />
                                    <button 
                                        className='g-button med-btn' 
                                        title='View item data'
                                        onClick={() => navigate(`/manage/${gameData.path}/elements/data/${c.path}`)}
                                    >
                                        <FontAwesomeIcon icon={faChartPie} />
                                    </button>
                                    <button className='g-button med-btn' onClick={() => navigate(`edit/${c.path}`)} title='Edit this item'><FontAwesomeIcon icon={faPen} /></button>
                                </div>
                            </div>
                        ))
                        :
                        <p>No items yet...</p>
                    }
                </div>
            </div>

            
            <UpgradeRequiredModal
                show={showUpgradeModal}
                cancel={() => setShowUpgradeModal(false)}
                closeButton={true}
                message={showUpgradeModal}
            />
        </div>
    )
}

export default ItemsList