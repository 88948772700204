import React, { useEffect, useRef, useState } from 'react'
import './manage-contribution-card.styles.scss';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import ProgressBar from '@/components/SHARED/progress-bar/progress-bar.component';
import { format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { faXmarkCircle } from '@fortawesome/free-regular-svg-icons';
import { useNavigate } from 'react-router-dom';
import { ModalAlert, ModalConfirm } from '@/components/modal/modal.component';
import { UseCloudFunction } from '@/utils/firebase.utils';
import { useSelector } from 'react-redux';

const ManageContributionCard = ({
    gameData,
    item,
    gameStats,
    gameActivity,
    playerList
}) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const [ progress, setProgress ] = useState(0);
    const [ contributions, setContributions ] = useState(0);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const navigate = useNavigate();
    const approveRef = useRef();

    useEffect(() => {
        if (!gameStats || !gameActivity || !item) return;
        const conTotal = gameStats[item.id + '_contributions'] || 0;
        setContributions(conTotal);
        setProgress((conTotal / item.opt_contributionGoal) * 100)
    }, [gameStats, gameActivity, item])

    function resetContributions() {
        setConfirmMessage(`Are you sure you want to reset the contribution total to zero? Your players' contribution history will not change. This action cannot be undone.`);
        approveRef.current = () => {
            continueReset();
        }

        async function continueReset() {
            const res = await UseCloudFunction(
                'resetContributionTotal',
                {
                    'appId': userProfile.appId,
                    'item': item,
                    'ts': new Date().getTime()
                }
            )
            console.log(res);
            if (res.error) {
                setAlertMessage('Something went wrong: ' + res.error);
                return;
            } 
        }
    }

    return (
        <div className={`g-list-item manage-contribution-card ${
            progress >= 100 
            ? 'completed'
            : ''
        }`}>
            <div className='store-contribution-grid'>
                <div className='store-contribution-icon-div'>
                    <ImageContainer src={item.icon} className='item-icon' />
                </div>
                <div className='store-contribution-text-div'>
                    {
                        (progress >= 100) &&
                        <ImageContainer 
                            src='/images/icons/completed-overlay-green.png' 
                            className='completed-image' />
                    }
                    <div className='item-name'>{item.name}</div>
                    {
                        (item.desc) &&
                        <div className='item-desc'>
                            <div className="g-space-1"></div>
                            {item.desc}
                        </div>
                    }
                    <div className="g-space-0-5"></div>
                    <div className='contributions'>
                        Contributions: {contributions.toLocaleString()} of {item.opt_contributionGoal.toLocaleString()}
                    </div>
                    <div className='item-progress'>
                        <ProgressBar progressValue={progress} itemId={item.id} />
                    </div>
                    
                    {
                        (item.opt_dateClose && item.opt_dateClose > new Date().getTime()) &&
                        <div className='meta'>
                            Element closes on {format(new Date(item.opt_dateClose), "Pp")}
                        </div>
                    }
                    
                </div>
            </div>
            <div className='action-buttons'>
                <button 
                    className='g-button med-btn'
                    onClick={() => resetContributions()}>
                    <FontAwesomeIcon icon={faRefresh} />
                    Reset Contributions
                </button>
                <button 
                    className='g-button med-btn'
                    onClick={() => navigate(`/manage/${gameData.path}/elements/items/edit/${item.path}`)} >
                    <FontAwesomeIcon icon={faPen} />
                    Edit Item
                </button>
            </div>
            <ModalAlert
                show={alertMessage}
                cancel={() => setAlertMessage(null)}
                message={alertMessage} />
            <ModalConfirm
                show={confirmMessage}
                cancel={() => setConfirmMessage(null)}
                message={confirmMessage}
                onApprove={approveRef.current} />
        </div>
    )
}

export default ManageContributionCard