import React, { useEffect, useState } from 'react'
import './declined-challenge-modal.styles.scss'

import Modal from '@/components/modal/modal.component'
import { useNavigate } from 'react-router-dom'
import ImageContainer from '@/components/SHARED/image-container/image-container.component'
import { UseCloudGameplay } from '@/utils/firebase.utils'

const DeclinedChallengeModal = ({ gameData, playerData, playerActivity, gameElements }) => {
    
    const [ element, setElement ] = useState(null);
    const [ activityId, setActivityId ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!playerData || !gameElements || !playerActivity) return;
        if (playerData.newStuff && playerData.newStuff.length > 0) {
            for (let actId of playerData.newStuff) {
                if (playerActivity[actId] && (actId.includes('declinedChallenge') || actId.includes('declinedItem'))) {
                    console.log(playerActivity[actId]);
                    console.log(gameElements[playerActivity[actId].elementId]);
                    setElement(gameElements[playerActivity[actId].elementId]);
                    setActivityId(actId)
                    break;
                }
            }
        }
    }, [playerData, gameElements, playerActivity])

    async function goToChallenge() {
        const data = {
            'gameId': gameData.gameId,
            'playerId': playerData.playerId,
            'rewardId': activityId
        }
        const res = await UseCloudGameplay('markRewardAsViewed', data);
        console.log(res);
        console.log(element);
        if (element.type === 'challenge') {
            navigate(`/play/${gameData.path}/challenges/${element.path}`);
            setElement(null);
        } else if (element.type = 'item') {
            setElement(null);
        }
    }

    return (
        <div>
            <Modal show={element} cancel={() => setElement(null)} cls='narrow' text={`Declined ${element && (element.type === 'challenge' ? 'Challenge' : 'Item')}`}>
                <div className='modal-children'> 
                    {
                        (element) &&
                        <div className='declined-challenge-modal'>
                            <ImageContainer src={element.icon} alt={`${element.name} icon`} className='treasure-gif' />
                            <p>{element.name}</p>
                            <h3>
                                {
                                    (element.type === 'challenge') ?
                                    'You have a declined challenge. Click the button below to check it out.' :
                                    (element.type === 'item') ?
                                    'An item you submitted has been returned to you.'
                                    : ''
                                }
                            </h3>
                            
                            <hr />
                            <div className="buttons">
                                {
                                    (loading)
                                    ?
                                    <button className="g-button"><ThreeDotsLoader /></button>
                                    :
                                    <button className="g-button" onClick={() => goToChallenge()}>
                                        {
                                            element.type === 'challenge' ? 
                                            'Go to Challenge' :
                                            element.type === 'item' ?
                                            'Ok' :
                                            ''
                                        }
                                    </button>
                                }
                            </div>
                        </div>
                    }
                </div>
            </Modal>
        </div>
    )
}

export default DeclinedChallengeModal