import React, { useState } from 'react';
import './manage-game-sidebar.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarsProgress, faBell, faBriefcase, faBug, faCaretRight, faExchange, faFlagCheckered, faFunnelDollar, faGear, faGem, faGlobeAmericas, faHandshake, faHouse, faList, faListCheck, faMagicWandSparkles, faMagnifyingGlass, faPeopleGroup, faPeopleLine, faPiggyBank, faShop, faShoppingBag, faShoppingBasket, faShoppingCart, faSliders, faStore, faTicket, faTicketAlt, faTicketSimple, faTrophy, faUserSlash, faUsers } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
import BugModal from '@/components/layout-auth/bug-modal/bug-modal.component';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import InfoLinks from '@/components/SHARED/info-links/info-links.component';

export function switchManagerMenu(e) {
    const items = document.getElementsByClassName('menu-item')
    for (let item of items) {
        item.classList.remove('active')
    }
    const selectedItems = document.getElementsByClassName(e);
    for (let selectedItem of selectedItems) {
        selectedItem.classList.add('active');
    }
}

const ManageGameSidebar = ({ 
    gameData, 
    actionItems, 
    gameElements, 
    orgs,
    role
}) => {

    const screenSize = useSelector(state => state.contentData.screenSize);
    const [ showBugModal, setShowBugModal ] = useState(false);
    const [ pendingActionItems, setPendingActionItems ] = useState(0);
    const [ pendingRealWorldItems, setPendingRealWorldItems ] = useState(0);
    const [ showSettingsDrop, setShowSettingsDrop ] = useState(false);
    const [ showMarketplaceDrop, setShowMarketplaceDrop ] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!gameData) {return;}

    }, [gameData]) 

    useEffect(() => {
        if (!gameElements) return;
        const count =
        Object.values(gameElements)
        .filter(e => 
            e.opt_itemType === 'realworld' &&
            e.status === 'active'
        ).length;
        
    }, [gameElements])

    useEffect(() => {
        if (!actionItems) return;
        const pendingAI = Object.values(actionItems)
        .filter(i => 
            i.status === 'pending' &&
            i.type !== 'realworld'
        ).length;
        setPendingActionItems(pendingAI);
        const pendingRW = Object.values(actionItems)
        .filter(i => 
            i.status === 'pending' &&
            i.type === 'realworld'
        ).length;
        setPendingRealWorldItems(pendingRW);
    }, [actionItems])

    useEffect(() => {
        if (
            location.pathname.includes('/store') ||
            location.pathname.includes('/contributions') ||
            location.pathname.includes('/lottery')
        ) {
            setShowMarketplaceDrop(true);
        } else {
            setShowMarketplaceDrop(false);
        }
        if (location.pathname.includes('/settings')) {
            setShowSettingsDrop(true);
        } else {
            setShowSettingsDrop(false);
        }
    }, [location])

    return (
        <div className='manage-game-sidebar'>
            <div className='sidebar-container'>
                <div className='game-header'>
                    <div className='image-div'>
                        <img src={gameData.header} />
                    </div>  
                    <div className='title' style={{'color':gameData.color}}>{gameData.gameName}</div>
                </div>  
                {
                    (role === 'distributor') &&
                    <div className='manage-menu-container'>
                        <div className='manage-game-menu'>
                    {/* Home button */}
                            
                            <button
                                className={`g-button text-only menu-item ${
                                    location.pathname === `/manage/${gameData.path}` ? 
                                    'active' : 
                                    ''
                                }`}
                                onClick={() => navigate(`/manage/${gameData.path}`)} 
                            >
                                <FontAwesomeIcon 
                                    icon={faHouse} 
                                    className='menu-item-icon' 
                                    size='xl' 
                                />
                                <span>Home</span>
                            </button>

                    {/* Players button */}

                            <button 
                                className={`g-button text-only menu-item ${
                                    location.pathname.includes(`/manage/${gameData.path}/players`) ? 
                                    'active' : 
                                    ''
                                }`}
                                onClick={() => navigate(`/manage/${gameData.path}/players`)} 
                            >
                                <FontAwesomeIcon 
                                    icon={faUsers} 
                                    className='menu-item-icon' 
                                    size='xl' 
                                />
                                <span>Players</span>
                            </button>

                    {/* Rewards button */}

                            <button 
                                className={`g-button text-only menu-item ${
                                    location.pathname.includes(`/manage/${gameData.path}/distribute`) ? 
                                    'active' : 
                                    ''
                                }`}
                                onClick={() => navigate(`/manage/${gameData.path}/distribute`)}  
                            >
                                <FontAwesomeIcon 
                                    icon={faGem} 
                                    className='menu-item-icon' 
                                    size='xl' 
                                />
                                <span>Distribute Rewards</span>
                            </button>
                    {/* Real World items button */}

                            {
                                (
                                    gameElements &&
                                    Object.values(gameElements)
                                    .filter(e => 
                                        e.type === 'item' &&
                                        e.opt_itemType === 'realworld' &&
                                        e.status === 'active'
                                    ).length > 0
                                ) &&
                                <button 
                                    className={`g-button text-only menu-item manage-menu-action-items 
                                        ${
                                            pendingRealWorldItems > 0 ? 
                                            'pending-items' : 
                                            ''}
                                        ${
                                            location.pathname.includes(`/manage/${gameData.path}/realworlddist`) ?
                                            'active' : 
                                            ''
                                        }`}
                                    onClick={() => navigate(`/manage/${gameData.path}/realworlddist`)}
                                >
                                    <div className="notification-with-badge">
                                        <FontAwesomeIcon 
                                            icon={faShoppingBag} 
                                            className='menu-item-icon' 
                                            size='xl' 
                                        />
                                        {
                                            (pendingRealWorldItems > 0) &&
                                            <span className="badge">{pendingRealWorldItems}</span>
                                        }
                                    </div>
                                    <span>Real World Dist.</span>
                                </button>
                            }
                        {/* Leaderboards button */}

                            {
                                (gameData.access.includes('leaderboards')) &&
                                <button 
                                    className={`g-button text-only menu-item ${
                                        location.pathname.includes(`/manage/${gameData.path}/leaderboards`) ? 
                                        'active' : 
                                        ''
                                    }`}
                                    onClick={() => navigate(`/manage/${gameData.path}/leaderboards`)}  
                                >
                                    <FontAwesomeIcon 
                                        icon={faFlagCheckered} 
                                        className='menu-item-icon' 
                                        size='xl' 
                                    />
                                    <span>Leaderboards</span>
                                </button>  
                            }
                    {/* Explore data button */}   

                            <div 
                                className={`g-button text-only menu-item ${
                                    location.pathname.includes(`/manage/${gameData.path}/activity`) ? 
                                    'active' : 
                                    ''
                                }`}
                                onClick={() => navigate(`/manage/${gameData.path}/activity`)} 
                            >
                                <FontAwesomeIcon 
                                    icon={faGlobeAmericas} 
                                    className='menu-item-icon' 
                                    size='xl' 
                                />
                                <span>Game Activity</span>
                            </div>
                        </div>
                    </div>
                }
                {
                    (role === 'owner' || role === 'manager') &&
                    <div className='manage-menu-container'>
                        <div className='manage-game-menu'>
                            
                    {/* Home button */}
                            
                            <button
                                className={`g-button text-only menu-item ${
                                    location.pathname === `/manage/${gameData.path}` ? 
                                    'active' : 
                                    ''
                                }`}
                                onClick={() => navigate(`/manage/${gameData.path}`)} 
                            >
                                <FontAwesomeIcon 
                                    icon={faHouse} 
                                    className='menu-item-icon' 
                                    size='xl' 
                                />
                                <span>Home</span>
                            </button>
                            
                    {/* Players button */}

                            <button 
                                className={`g-button text-only menu-item ${
                                    location.pathname.includes(`/manage/${gameData.path}/players`) ? 
                                    'active' : 
                                    ''
                                }`}
                                onClick={() => navigate(`/manage/${gameData.path}/players`)} 
                            >
                                <FontAwesomeIcon 
                                    icon={faUsers} 
                                    className='menu-item-icon' 
                                    size='xl' 
                                />
                                <span>Players</span>
                            </button>
                    
                    {/* Players button */}

                            <button 
                                className={`g-button text-only menu-item ${
                                    location.pathname.includes(`/manage/${gameData.path}/teams`) ? 
                                    'active' : 
                                    ''
                                }`}
                                onClick={() => navigate(`/manage/${gameData.path}/teams`)} 
                            >
                                <FontAwesomeIcon 
                                    icon={faPeopleGroup} 
                                    className='menu-item-icon' 
                                    size='xl' 
                                />
                                <span>Teams</span>
                            </button>

                    {/* Action items button */}

                            {
                                (gameData.access.includes('challenges') || gameData.access.includes('quests')) &&
                                <button 
                                    className={`g-button text-only menu-item manage-menu-action-items 
                                        ${
                                            pendingActionItems > 0 ? 
                                            'pending-items' : 
                                            ''}
                                        ${
                                            location.pathname.includes(`/manage/${gameData.path}/actionitems`) ?
                                            'active' : 
                                            ''
                                        }`}
                                    onClick={() => navigate(`/manage/${gameData.path}/actionitems`)}
                                >
                                    <div className="notification-with-badge">
                                        <FontAwesomeIcon 
                                            icon={faBell} 
                                            className='menu-item-icon' 
                                            size='xl' 
                                        />
                                        {
                                            (pendingActionItems > 0) &&
                                            <span className="badge">{pendingActionItems}</span>
                                        }
                                    </div>
                                    <span>Action Items</span>
                                </button>
                            }

                    {/* Real World items button */}

                            {
                                (
                                    gameElements &&
                                    Object.values(gameElements)
                                    .filter(e => 
                                        e.type === 'item' &&
                                        e.opt_itemType === 'realworld' &&
                                        e.status === 'active'
                                    ).length > 0
                                ) &&
                                <button 
                                    className={`g-button text-only menu-item manage-menu-action-items 
                                        ${
                                            pendingRealWorldItems > 0 ? 
                                            'pending-items' : 
                                            ''}
                                        ${
                                            location.pathname.includes(`/manage/${gameData.path}/realworlddist`) ?
                                            'active' : 
                                            ''
                                        }`}
                                    onClick={() => navigate(`/manage/${gameData.path}/realworlddist`)}
                                >
                                    <div className="notification-with-badge">
                                        <FontAwesomeIcon 
                                            icon={faShoppingBag} 
                                            className='menu-item-icon' 
                                            size='xl' 
                                        />
                                        {
                                            (pendingRealWorldItems > 0) &&
                                            <span className="badge">{pendingRealWorldItems}</span>
                                        }
                                    </div>
                                    <span>Real World Dist.</span>
                                </button>
                            }

                    {/* Manage elements button */}

                            <button 
                                className={`g-button text-only menu-item ${
                                    location.pathname.includes(`/manage/${gameData.path}/elements`) ? 
                                    'active' : 
                                    ''
                                }`}
                                onClick={() => navigate(`/manage/${gameData.path}/elements`)} 
                            >
                                <FontAwesomeIcon 
                                    icon={faBriefcase} 
                                    className='menu-item-icon' 
                                    size='xl'
                                />
                                <span>Manage Elements</span>
                            </button>

                    {/* Marketplace button */}  
                        {
                            (
                                gameData && 
                                gameData.access.includes('marketplace')
                            ) &&
                            <>
                            <button 
                                className={`g-button text-only menu-item ${
                                    location.pathname.includes(`/manage/${gameData.path}/marketplace`) ? 
                                    '' : 
                                    ''
                                }`}
                                onClick={() => setShowMarketplaceDrop(!showMarketplaceDrop)} 
                            >
                                <FontAwesomeIcon 
                                    icon={faStore} 
                                    className='menu-item-icon' 
                                    size='xl' 
                                />
                                <span>Marketplace</span>
                                <FontAwesomeIcon 
                                    icon={faCaretRight} 
                                    id='marketplace-drop-menu-caret'
                                    className={
                                        `menu-item-icon ${
                                            showMarketplaceDrop ? 
                                            'rotated' : 
                                            ''
                                        }`
                                    } 
                                />
                            </button>
                            <div 
                                className={`drop-container marketplace ${
                                    showMarketplaceDrop ? 'active' : ''
                                }`}
                                id='marketplace-drop-menu'
                            >
                                <div className='drop-menu'>
                                    <button 
                                        className={
                                            `g-button text-only drop-menu-item menu-item ${
                                                (
                                                    location.pathname.includes(`/manage/${gameData.path}/store`) &&
                                                    !location.pathname.includes('/store/')
                                                ) ? 
                                                'active' : 
                                                ''
                                            }`
                                        } 
                                        onClick={() => navigate(`/manage/${gameData.path}/store`)}
                                    >
                                        <FontAwesomeIcon 
                                            icon={faShoppingCart} 
                                            className='drop-menu-item-icon' 
                                            size='lg' 
                                        /> 
                                        Game Store
                                    </button>
                                    <button 
                                        className={
                                            `g-button text-only drop-menu-item menu-item ${location.pathname.includes('/store/purchases') ? 
                                            'active' : 
                                            ''}`
                                        } 
                                        onClick={() => navigate(`/manage/${gameData.path}/store/purchases`)}
                                    >
                                        <FontAwesomeIcon 
                                            icon={faShoppingBasket} 
                                            className='drop-menu-item-icon' 
                                            size='lg' 
                                        /> 
                                        Purchases
                                    </button>
                                    <button 
                                        className={
                                            `g-button text-only drop-menu-item menu-item ${location.pathname.includes('contributions') ? 
                                            'active' : 
                                            ''}`
                                        } 
                                        onClick={() => navigate(`/manage/${gameData.path}/contributions`)}
                                    >
                                        <FontAwesomeIcon 
                                            icon={faPiggyBank} 
                                            className='drop-menu-item-icon' 
                                            size='lg' 
                                        /> 
                                        Contributions
                                    </button>
                                    <button 
                                        className={
                                            `g-button text-only drop-menu-item menu-item ${location.pathname.includes('lottery') ? 
                                            'active' : 
                                            ''}`
                                        } 
                                        onClick={() => navigate(`/manage/${gameData.path}/lottery`)}
                                    >
                                        <FontAwesomeIcon 
                                            icon={faTicket} 
                                            className='drop-menu-item-icon' 
                                            size='lg' 
                                        /> 
                                        Lottery
                                    </button>
                                    
                                    <button 
                                        className={
                                            `g-button text-only drop-menu-item menu-item disabled ${location.pathname.includes('tradeportal') ? 
                                            'active' : 
                                            ''}`
                                        } 
                                        onClick={() => navigate(`/manage/${gameData.path}/tradeportal`)}
                                    >
                                        <FontAwesomeIcon 
                                            icon={faExchange} 
                                            className='drop-menu-item-icon' 
                                            size='lg' 
                                        /> 
                                        Trade Portal
                                    </button>
                                </div>
                            </div>
                            </>
                        }

                    {/* Leaderboards button */}

                            {
                                (gameData.access.includes('leaderboards')) &&
                                <button 
                                    className={`g-button text-only menu-item ${
                                        location.pathname.includes(`/manage/${gameData.path}/leaderboards`) ? 
                                        'active' : 
                                        ''
                                    }`}
                                    onClick={() => navigate(`/manage/${gameData.path}/leaderboards`)}  
                                >
                                    <FontAwesomeIcon 
                                        icon={faFlagCheckered} 
                                        className='menu-item-icon' 
                                        size='xl' 
                                    />
                                    <span>Leaderboards</span>
                                </button>  
                            }
                            
                    {/* Rewards button */}

                            <button 
                                className={`g-button text-only menu-item ${
                                    location.pathname.includes(`/manage/${gameData.path}/distribute`) ? 
                                    'active' : 
                                    ''
                                }`}
                                onClick={() => navigate(`/manage/${gameData.path}/distribute`)}  
                            >
                                <FontAwesomeIcon 
                                    icon={faGem} 
                                    className='menu-item-icon' 
                                    size='xl' 
                                />
                                <span>Distribute Rewards</span>
                            </button>
                            
                    {/* Explore data button */}   

                            <div 
                                className={`g-button text-only menu-item ${
                                    location.pathname.includes(`/manage/${gameData.path}/activity`) ? 
                                    'active' : 
                                    ''
                                }`}
                                onClick={() => navigate(`/manage/${gameData.path}/activity`)} 
                            >
                                <FontAwesomeIcon 
                                    icon={faGlobeAmericas} 
                                    className='menu-item-icon' 
                                    size='xl' 
                                />
                                <span>Game Activity</span>
                            </div>

                    {/* Settings button */}   
                            <div 
                                className={`g-button text-only menu-item ${
                                    location.pathname.includes(`/manage/${gameData.path}/settings`) ? 
                                    '' : 
                                    ''
                                }`}
                                onClick={() => setShowSettingsDrop(!showSettingsDrop)} 
                            >
                                <FontAwesomeIcon 
                                    icon={faGear} 
                                    className='menu-item-icon' 
                                    size='xl' 
                                />
                                <span>Game Settings</span>
                                <FontAwesomeIcon 
                                    icon={faCaretRight} 
                                    id='settings-drop-menu-caret'
                                    className={
                                        `menu-item-icon ${
                                            showSettingsDrop ? 
                                            'rotated' : 
                                            ''
                                        }`
                                    } 
                                />
                            </div>
                            <div 
                                className={`drop-container settings ${
                                    showSettingsDrop ? 'active' : ''
                                } ${
                                    orgs ? 'orgs' : ''
                                }`}
                                id='settings-drop-menu'
                            >
                                <div className='drop-menu'>
                                    <div 
                                        className={
                                            `drop-menu-item menu-item ${
                                                location.pathname === (`/manage/${gameData.path}/settings`) ? 
                                                'active' : 
                                                ''
                                            }`
                                        } 
                                        onClick={() => navigate(`/manage/${gameData.path}/settings`)}
                                    >
                                        <FontAwesomeIcon 
                                            icon={faListCheck} 
                                            className='drop-menu-item-icon' 
                                            size='lg' 
                                        /> 
                                        Information
                                    </div>
                                    {
                                        (orgs) &&
                                        <div 
                                            className={
                                                `drop-menu-item menu-item ${
                                                    location.pathname === (`/manage/${gameData.path}/settings/collaborators`) ? 
                                                    'active' : 
                                                    ''
                                                }`
                                            } 
                                            onClick={() => navigate(`/manage/${gameData.path}/settings/collaborators`)}
                                        >
                                            <FontAwesomeIcon 
                                                icon={faHandshake} 
                                                className='drop-menu-item-icon' 
                                                size='lg' 
                                            /> 
                                            Collaborators
                                        </div>
                                    }
                                    <div 
                                        className={
                                            `drop-menu-item menu-item ${location.pathname.includes('settings/design') ? 
                                            'active' : 
                                            ''}`
                                        } 
                                        onClick={() => navigate(`/manage/${gameData.path}/settings/design`)}
                                    >
                                        <FontAwesomeIcon 
                                            icon={faMagicWandSparkles} 
                                            className='drop-menu-item-icon' 
                                            size='lg' 
                                        /> 
                                        Game Design
                                    </div>
                                    <div 
                                        className={
                                            `drop-menu-item menu-item ${location.pathname.includes('settings/rostering') ? 
                                            'active' : 
                                            ''}`
                                        } 
                                        onClick={() => navigate(`/manage/${gameData.path}/settings/rostering`)}
                                    >
                                        <FontAwesomeIcon 
                                            icon={faPeopleLine} 
                                            className='drop-menu-item-icon' 
                                            size='lg' 
                                        /> 
                                        Rostering
                                    </div>
                                    <div 
                                        className={
                                            `drop-menu-item menu-item ${location.pathname.includes('settings/elements') ? 
                                            'active' : 
                                            ''}`
                                        } 
                                        onClick={() => navigate(`/manage/${gameData.path}/settings/elements`)}
                                    >
                                        <FontAwesomeIcon 
                                            icon={faSliders} 
                                            className='drop-menu-item-icon' 
                                            size='lg' 
                                        /> 
                                        Element Options
                                    </div>
                                    <div 
                                        className={
                                            `drop-menu-item menu-item ${location.pathname.includes('settings/removedplayers') ? 
                                            'active' : 
                                            ''}`
                                        } 
                                        onClick={() => navigate(`/manage/${gameData.path}/settings/removedplayers`)}
                                    >
                                        <FontAwesomeIcon 
                                            icon={faUserSlash} 
                                            className='drop-menu-item-icon' 
                                            size='lg' 
                                        /> 
                                        Removed Players
                                    </div>
                                </div>
                            </div>

                    {/* Bug report button */}

                            <button className='g-button text-only menu-item manage-menu-bug' onClick={() => setShowBugModal(true)}>
                                <FontAwesomeIcon 
                                    icon={faBug} 
                                    className='menu-item-icon' 
                                    size='xl' 
                                />
                                <span>Report a Bug</span>
                            </button>
                        </div>    
                    </div>
                }
                {/* <div className="g-space-40"></div> */}
                <div className='manage-menu-footer'>
                    <InfoLinks />
                </div>  
            </div>
            <BugModal 
                show={showBugModal} 
                cancel={() => setShowBugModal(false)} 
            />
        </div>
    )
}

export default ManageGameSidebar