import React, { useEffect } from 'react'
import './under-construction.styles.scss';
import construction from '@/assets/const.png';
import gamablyLogo from '@/assets/icon.png'
import { changeDocumentTitle } from '@/utils/useDocumentTitle';

const UnderConstruction = () => {

    useEffect(() => {
        changeDocumentTitle();
    }, [])
    return (
        <div className='under-construction'>
            <div className='under-construction-content'>
                <div className='g-card info-card'>
                    <img src={construction} className='main-image' />
                    <div className='info-div'>
                        <img src={gamablyLogo} className='gamably-logo' />
                        <div>
                            <h3>Gamably is under construction!</h3>
                            <div className="g-space-1"></div>
                            <p>We're sorry for the inconvenience! The site should reopen on Monday, Dec. 2nd. If you have a specific issue please contact <a href='mailto:adam@gamably.com' target='_blank'>adam@gamably.com</a>. Thank you for your understanding.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="g-space-1"></div>
            <a className='meta' href="https://www.freepik.com/free-vector/development-concept-illustration_11683643.htm#fromView=search&page=1&position=51&uuid=be940076-48d8-4e60-b2df-b4d1d1830282">Image by storyset on Freepik</a>
        </div>
    )
}

export default UnderConstruction