import React, { useEffect } from 'react';
import './visibility.styles.scss';
import ToggleInput from '@/components/SHARED/toggle-input/toggle-input.component';

const ManageContentVisibility = ({ 
    type, 
    saveData, 
    formData, 
    previewText, 
    visibilityText, 
    availabilityText,
    disabled 
}) => {

    useEffect(() => {
        if (formData && !formData.opt_visible) {
            saveData('opt_preview', false);
            saveData('opt_available', false);
        }
    }, [formData.opt_visible])

    return (
        <div className={`g-list-item form-section ${disabled}`}>
            <h3>Visibility and Availability</h3>
            <hr />
            {
                (visibilityText) &&
                <>
                    <div className='toggle-with-text'>
                        <ToggleInput contentKey="opt_visible" checked={formData.opt_visible} saveData={saveData} />
                        <div className='toggle-text'>
                            <div><b>Visible by Players:</b></div>
                            <div>{visibilityText}</div>
                        </div>
                    </div>
                    <div className='g-space-1'></div>
                </>
            }
            {
                (availabilityText) &&
                <>
                
                <div className={`toggle-with-text ${!formData.opt_visible ? 'disabled': ''}`}>
                    <ToggleInput
                        contentKey="opt_available"
                        checked={formData.opt_available}
                        saveData={saveData} />
                    <div className='toggle-text'>
                        <div><b>Available to be {
                            type === 'challenge'
                            ? 'Accessed and Responses Submitted'
                            : 'Earned and/or Purchased:'
                        }</b></div>
                        <div>{availabilityText}</div>
                    </div>
                </div>
                <div className='g-space-1'></div>
                </>
            }
            
            {
                (previewText) &&
                <>
                <div className={`toggle-with-text ${!formData.opt_visible ? 'disabled': ''}`}>
                    <ToggleInput contentKey="opt_preview" checked={formData.opt_preview} saveData={saveData} />
                    <div className='toggle-text'>
                        <div><b>Players can Preview</b></div>
                        <div dangerouslySetInnerHTML={{ __html: previewText}} />
                    </div>
                </div>
                <div className='g-space-1'></div>
                </>
            }
        </div>
    )
}

export default ManageContentVisibility;