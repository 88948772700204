import React, { useEffect, useState } from 'react';
import './sign-player-in-with-code.styles.scss';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import { UseCloudFunction, auth } from '@/utils/firebase.utils';
import { ModalAlert } from '@/components/modal/modal.component';
import { useNavigate } from 'react-router-dom';
import { signInWithCustomToken, updateEmail, updateProfile } from 'firebase/auth';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentUser, setUserProfile } from '@/state/slices/userSlice';
import { changeDocumentTitle } from '@/utils/useDocumentTitle';

const SignPlayerInWithCode = ({ gameData, playerData }) => {

    const currentUser = useSelector(state => state.userData.currentUser);
    const profile = useSelector(state => state.userData.userProfile);
    const [ loading, setLoading ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        changeDocumentTitle();

        const input = document.getElementById('passcode');
        input.addEventListener('keypress', function(event) {
            if (event.key === 'Enter') {
                event.preventDefault();
                document.getElementById('sign-in-button').click();
            }
        })
    }, [])

    useEffect(() => {
        if (!currentUser || !profile) return;
        console.log(profile);
        navigate(`/play/${gameData.path}`);
        setLoading(false);
    }, [currentUser, profile])
    
    async function signInPlayer() {
        const passcode = document.getElementById('passcode').value;
        if (!passcode) return;
        setLoading(true);
        const res = await UseCloudFunction('signInUploadedPlayer', {
            'gameData': gameData,
            'playerData': playerData,
            'passcode': passcode,
        })
        console.log(res);
        if (res.error) {
            setAlertMessage(`Something went wrong. Please try again later. (Error: ${res.error})`);
            setLoading(false);
            return;
        }
        if (res.incorrect) {
            setAlertMessage(`The passcode is incorrect. Please try again.`);
            setLoading(false);
            return;
        }
        signInWithCustomToken(auth, res.token)
        .then((userCredential) => {
            // Signed in
            var user = userCredential.user;
            console.log(user);
            dispatch(setUserProfile(res.profile));
            updateProfile(user, {
                'displayName': playerData.displayName,
            })
            updateEmail(user, `${playerData.playerId.split('_')[1]}@gamablyplayers.com`);
            const newUser = {
                displayName: playerData.displayName,
                email: `${playerData.playerId.split('_')[1]}@gamablyplayers.com`,
                metadata: {
                createdAt: Number(user.metadata.createdAt),
                    lastLogInAt: Number(user.metadata.lastLogInAt)
                },
                uid: user.uid,
                photoURL: user.photoURL,
                provider: 'FirestoreAuth'
            }
            dispatch(setCurrentUser({...newUser}));
            
            // ...
        })
        .catch((error) => {
            var errorCode = error.code;
            var errorMessage = error.message;
            console.log(errorMessage);
            // ...
        });
    }

    return (
        <div className='sign-player-in-with-code'>
            {
                (gameData && playerData) &&
                <div className='sign-in-div'>
                    
                    <div className='field'>
                        <label>
                        <div className='icon-div'>
                            {
                                (playerData.icon)
                                ?
                                <ImageContainer src={playerData.icon} className='icon' alt='group icon' />
                                :
                                <div className='gradient' style={{'background': playerData.gradient}}>{playerData.displayName[0].toUpperCase()}</div>
                            }
                        </div>
                        {playerData.displayName}</label>
                        <input type='password' placeholder='Passcode' id='passcode' />
                    </div>
                    {
                        (loading)
                        ?
                        <button className='g-button'><ThreeDotsLoader /></button>
                        :
                        <button 
                            className='g-button' 
                            onClick={() => signInPlayer()} 
                            id='sign-in-button'>
                            Sign In
                        </button>
                    }
                </div>
            }
            <ModalAlert show={alertMessage} cancel={() => setAlertMessage(null)} message={alertMessage} />
        </div>
    )
}

export default SignPlayerInWithCode