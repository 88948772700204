import React, { useEffect, useState } from 'react'
import './sign-in.styles.scss'
import './google-btn.styles.scss'
import { GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth'
import { auth, signInWithGoogle } from '@/utils/firebase.utils'
import { Link, useNavigate } from 'react-router-dom';
import googleImg from '@/assets/google.png';
import microsoftImg from '@/assets/microsoft.png';
import UnauthLayout from '@/components/layout-unauth/unauth-layout.component'
import logo_long from '@/assets/logo_long.png';
import { ModalAlert } from '@/components/modal/modal.component'
import Loader from '@/components/loader/loader.comp';
import { useParams } from 'react-router-dom';
import { changeDocumentTitle } from '@/utils/useDocumentTitle'

const SignIn = () => {

    const [ email, setEmail ] = useState('')
	const [ password, setPassword ] = useState('');
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ currentUser, setCurrentUser ] = useState(auth.currentUser);
    const [ authorized, setAuthorized ] = useState(true);
    const { joinCode } = useParams();
    const paramSearch = new URLSearchParams(window.location.search)
    const [ joinNew ] = useState(paramSearch.get('join') === 'true');
    const navigate = useNavigate();

    useEffect(() => {
        changeDocumentTitle();
        setTimeout(() => {
            checkForAuth();
        }, 1000);

        function checkForAuth() {
            const currentlySignedInUser = auth.currentUser;
            console.log(currentlySignedInUser);
            if (currentlySignedInUser) {
                navigate(`/loading${joinCode ? `/${joinCode}` : ''}${joinNew ? `?join=true` : ''}`);
            } else {
                setAuthorized(false);
            }
        }
    }, [])

    useEffect(() => {
        if (currentUser) {
            navigate(`/loading${joinCode ? `/${joinCode}` : ''}${joinNew ? `?join=true` : ''}`);
        }
    }, [currentUser])

	const handleSubmit = async (e) => {
		e.preventDefault();		
		signInWithEmailAndPassword(auth, email, password)
		.then((userCredential) => {
			// Signed in 
			// const user = userCredential.user;
			// ...
            console.log(userCredential)
            navigate(`/loading${joinCode ? `/${joinCode}` : ''}${joinNew ? `?join=true` : ''}`);
		}).catch(err => {
            setAlertMessage('Sorry, the email and/or password doesn\'t match our records.');
        })
		
		setEmail('')
		setPassword('')
	}

    async function startGoogleSignin() {
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider)
        .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            // The signed-in user info.
            const user = result.user;
            navigate(`/loading${joinCode ? `/${joinCode}` : ''}${joinNew ? `?join=true` : ''}`)
            // IdP data available using getAdditionalUserInfo(result)
            // ...
        }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            // ...
        });
    }

    return (
        <div>
            <UnauthLayout>
            <div className='sign-in-container'>
                <div className='sign-in-content'>
                    {
                        (!authorized) 
                        ?
                        <div className='g-card'>
                            <div className='icon-container'>
                                <img className='main-logo' src={logo_long} alt="main logo" />
                            </div>
                            <div className="g-space-1"></div>
                                
                            <div className='center-box'>
                                <button onClick={() => startGoogleSignin()} className="g-button provider-button" >
                                    <img src={googleImg} />
                                    Sign in with Google
                                </button>
                                <div className="g-space-10"></div>
                                <button onClick={() => startGoogleSignin()} className="g-button provider-button" >
                                    <img src={microsoftImg} />
                                    Sign in with Microsoft
                                </button>
                                <p><b>- OR -<br />
                                Sign in with your email and password</b></p>
                            </div>
                            <div className="field" >
                                <input 
                                    type="text" 
                                    placeholder="Email" 
                                    onChange={(e) => setEmail(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            handleSubmit(e);
                                        }
                                    }}  
                                />
                                <input 
                                    className="uk-input" 
                                    type="password" 
                                    placeholder="Password" 
                                    onChange={(e) => setPassword(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            handleSubmit(e);
                                        }
                                    }}  
                                />
                            </div>
                            <div className="g-space-1"></div>
                            <div className='buttons'>
                                <Link to='/'>
                                    <button className='g-button'>
                                        Cancel
                                    </button>
                                </Link>
                                <button className='g-button primary' onClick={(e) => handleSubmit(e)}>
                                    Sign In
                                </button>
                                {/* <CustomButton onClick={signInWithGoogle} isGoogleSignIn>
                                    Sign In With Google
                                </CustomButton>*/} 
                            </div>
                            <div className='center-box'>
                                <h4>Don't have an account?<br />Register <Link to={`/register${joinCode ? `/${joinCode}` : ''}`}><b>HERE</b></Link>!</h4>
                            </div>
                        </div>
                        :
                        <div className='center'>
                            <Loader />
                        </div>
                    }
                </div>
            </div>
            </UnauthLayout>
            <ModalAlert show={alertMessage} cancel={() => setAlertMessage(null)} message={alertMessage} />
        </div>
    )
}

export default SignIn

// import 'shared/google-btn.styles.scss'

// import { Link } from 'react-router-dom'
// import { auth, signInWithGoogle } from 'firebase-utils/firebase.utils'
// import { signInWithEmailAndPassword } from 'firebase/auth'

// import Footer from 'components/footer/footer.component'
// import Header from 'components/header/header.component'
// import UIkit from 'uikit'

// const SignInPage = () => {

//     const [ email, setEmail ] = useState('')
// 	const [ password, setPassword ] = useState('')

// 	const handleSubmit = async (e) => {
// 		e.preventDefault();		
// 		signInWithEmailAndPassword(auth, email, password)
// 		.then((userCredential) => {
// 			// Signed in 
// 			// const user = userCredential.user;
// 			// ...
//             console.log(userCredential)
// 		}).catch(err => {
//             UIkit.modal.alert('Sorry, the email and/or password doesn\'t match our records.');
//         })
		
// 		setEmail('')
// 		setPassword('')
// 	}

//     return (
//         <div className='sign-in-page'>
//             <div className="content-container">
//                 {/* <Header /> */}
//                 <div className="sign-in-container">
//                     <div className='icon-container'>
//                         <img className='main-logo' src={require('assets/mainlogo-white.png')} alt="" />
//                     </div>
//                     <div className='sign-in g-card'>
                        
//                         <button onClick={signInWithGoogle} className="login-with-google-btn" >
//                             Sign in with Google
//                         </button>
//                         <p>- OR -</p>
//                         <p>Sign in with your email and password</p>
                    
//                         <div className="field" >
//                             <input type="text" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
//                             <div className="g-space-20"></div>
//                             <input className="uk-input" type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
//                         </div>
                        
//                         <div className='buttons'>
//                             <Link to='/'>
//                                 <button className='button-6 minor'>
//                                     Cancel
//                                 </button>
//                             </Link>
//                             <button className='button-6' onClick={(e) => handleSubmit(e)}>
//                                 Sign In
//                             </button>
//                             {/* <CustomButton onClick={signInWithGoogle} isGoogleSignIn>
//                                 Sign In With Google
//                             </CustomButton>*/} 
//                         </div>
//                         <div className="g-space-20"></div>
//                         <h4>Don't have an account?<br />Register <Link to='/register'><b>HERE</b></Link>!</h4>
//                     </div>
//                 </div>
//             </div>
//             <div className="footer-container">
//                 <Footer />  
//             </div>
//         </div>
//     )
// }