import { useEffect, useRef, useState } from 'react'

import './App.scss'
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import LoadingPage from './pages/loading/loading-page.page'
import Register from './pages/sign-in/register.component';
import ProfilePage from './pages/profile/profile.page';
import SignIn from './pages/sign-in/sign-in.component';
import LogoutPage from './pages/logout/logout-page.page';
import PageNotFound from './pages/page-not-found/page-not-found.component';
import LandingPage from './pages/landing-page/landing-page.page';
import HomePage from './pages/home-page/home-page.page';
import ManageGamePage from './pages/manage-game/manage-game.page';
import JoinPage from './pages/join-page/join-page.page';
import AdminPage from './pages/admin/admin.page';
import PlayGamePage from './pages/play-game/play-game.page';
import ChangeLog from './pages/changelog/changelog.page';
import { screenSizes } from './utils/responsiveQueries';
import { setScreenSize } from './state/slices/contentSlice';
import LeaderboardPage from './pages/leaderboard/leaderboard.page';
import { getGameDataFromDb, getOrgDataFromDb, getRegsFromDb, getUserMembershipFromDb } from './utils/getDataFromDb';
import { setMembership } from './state/slices/userSlice';
import PricingPage from './pages/pricing/pricing-page.page';
import QuotePage from './pages/quote/quote.page';
import MyPlan from './pages/my-plan/my-plan.component';
import PrintLogins from './components/MANAGE-GAME/players/print-logins.component';
import TermsOfService from './pages/info/terms-of-service.component';
import PrivacyPolicy from './pages/info/privacy-policy.component';
import Coppa from './pages/info/coppa.component';
import MyOrgs from './components/HOME/orgs/my-orgs.page';
import UnderConstruction from './pages/under-construction/under-construction.page';
import ReactGA from "react-ga4";
const gaTrackingID = "G-BMG0E5NLFS";


function App() {

    const currentUser = useSelector(state => state.userData.currentUser);
    const userProfile = useSelector(state => state.userData.userProfile);
    const [ managingList, setManagingList ] = useState({});
    const [ enrolledList, setEnrolledList ] = useState({});
    const [ orgs, setOrgs ] = useState(null);
    const orgsRef = useRef();
    const manageRef = useRef();
    const enrollRef = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let membershipListener;
    let orgsListener;
    let regsListener;

    useEffect(() => {
        // navigate('/construction');
        // return;
        onresize = (event) => {
            getScreenSize();
        };

        ReactGA.initialize(gaTrackingID);
        if (!window.location.host.includes('localhost:')) {
            ReactGA.send({
                hitType: 'pageview',
                page: window.location.pathname,
                title: document.title 
            })
        }

        return () => {
            if (membershipListener) membershipListener();
            if (orgsListener) orgsListener();
            if (regsListener) regsListener();
        }

    }, [])

    useEffect(() => {
        if (!userProfile) {
            manageRef.current = {};
            setManagingList(manageRef.current);
            enrollRef.current = {};
            setEnrolledList(enrollRef.current);
            return;
        }
        getMembership();
        getRegs();
    }, [userProfile])

    useEffect(() => {
        if (!orgs) return;
        if (Object.values(orgs).length > 0) {
            let firstOrg = Object.values(orgs)
            .sort((a,b) => a.ts_expires > b.ts_expires ? 1 : -1)[0]
            const memOrg = {
                'appId': userProfile.appId,
                'email': userProfile.email,
                'membership': 3,
                'ts': firstOrg.dateJoin,
                'ts_expires': firstOrg.dateExpire
            }
            dispatch(setMembership({...memOrg}))
        }
    }, [orgs])

    function getRegs() {
        // gets registration data for user, then gets game data for each registered game
        // also breaks data up into managing and playing
        // creates listener for reg changes - not game data changes
        getRegsFromDb({'appId': userProfile.appId, 'status': 'active', 'callback': callback, 'handleListener': handleListener })
        function callback(regData) {
            let currentManaging = {...manageRef.current};
            if (
                !regData.roles[userProfile.appId] ||
                (
                    currentManaging[regData.gameId] &&
                    regData.roles[userProfile.appId] &&
                    regData.roles[userProfile.appId].length === 1 &&
                    regData.roles[userProfile.appId].includes('player')
                )
            ) {
                if (currentManaging[regData.gameId]) {
                    delete currentManaging[regData.gameId];
                    manageRef.current = {...currentManaging};
                    setManagingList(manageRef.current);
                }
                return;
            }
            let currentEnrolled = {...enrollRef.current};
            if (
                !regData.roles[userProfile.appId] || 
                (
                    regData.roles[userProfile.appid] && 
                    currentEnrolled[regData.gameId] &&
                    !regData.roles[userProfile.appid].includes('player')
                )
            ) {
                if (currentEnrolled[regData.gameId]) {
                    delete currentEnrolled[regData.gameId];
                    enrollRef.current = {...currentEnrolled};
                    setEnrolledList(enrollRef.current);
                }
                return;
            }
            getGameDataFromDb({'gameId': regData.gameId, 'appId': userProfile.appId, 'once': true, 'callback': callback2});
            function callback2(gameData) {
                let tempGameData = {...gameData};
                tempGameData.roles = regData.roles[userProfile.appId];
                tempGameData.lastEdit = regData.lastEdit;
                if (tempGameData.roles.includes('owner') || tempGameData.roles.includes('manager') || tempGameData.roles.includes('distributor')) {
                    let newGame = {...tempGameData};
                    newGame.role = newGame.roles.filter(r => r !== 'player')[0];
                    manageRef.current = {...manageRef.current, ...{[newGame.gameId]: newGame}}
                    setManagingList(manageRef.current);
                } 
                if (tempGameData.roles.includes('player')) {
                    let newPlayerGame = {...tempGameData};
                    newPlayerGame.role = 'player'
                    enrollRef.current = {...enrollRef.current, ...{[newPlayerGame.gameId]: newPlayerGame}}
                    setEnrolledList(enrollRef.current);
                }
            }
        }   
        function handleListener(unsub) {
            regsListener = unsub;
        }
    }

    function getMembership() {
        getUserMembershipFromDb({'appId': userProfile.appId, 'callback': callback, 'handleListener': handleListener})
        function callback(data) {
            if (!userProfile.orgs) {
                dispatch(setMembership(data));
            } else {
                getOrgs(data);
            }
        }
        function handleListener(unsub) {
            membershipListener = unsub;
        }
    }

    function getOrgs() {
        for (let orgId of userProfile.orgs) {
            getOrgDataFromDb({
                'orgId': orgId,
                'callback': callback,
                'handleListener': handleListener
            })
            function callback(data, id) {
                orgsRef.current = {...orgsRef.current, ...{[id]: data}}
                setOrgs(orgsRef.current);
                console.log(orgsRef.current);
            }
            function handleListener(unsub) {
                orgsListener = unsub;
            }
        }
    }

    useEffect(() => {
        if (!screenSizes) {return;}
        getScreenSize();
    }, [screenSizes])

    function getScreenSize() {
        let sizeArr = screenSizes.filter(s => window.innerWidth >= s.minWidth && window.innerWidth <= s.maxWidth);
        let size;
        if (!sizeArr || !sizeArr.length) {
            size = 'desktop'
        } else {
            size = sizeArr[0].name;
        }
        dispatch(setScreenSize(size));
    }
    
    // function signInUser() {
    //     const href = window.location.href
    //     const currentlySignedInUser = auth.currentUser;
    //     console.log(currentlySignedInUser);
        
    //     onAuthStateChanged(auth, async (user) => {
    //         if (user) {
    //             // console.log(user)
    //             console.log('app.jsx')
    //             saveUserToState(user)
    //             if (href.includes('/signin')) {
    //                 // navigate('/loading') 
    //             }
    //             // navigate('/loading')  
    //         } else {
    //             if (currentUser) {
    //                 navigate('/logout')
    //             }
    //             if (!href.includes('/signin') && !href.includes('/register') && !href.includes('/info') && !href.includes('/join')) {
    //                 navigate('/')
    //             }
    //         }
    //     });
    //     if (!currentlySignedInUser) {
    //         navigate('/logout');
    //         return;
    //     }
    // }

    // function saveUserToState(user) {
    //     if (!user) {
    //         navigate('/logout');
    //         return;
    //     }
    //     const newUser = {
    //         displayName: user.displayName,
    //         email: user.email,
    //         metadata: {
    //         createdAt: Number(user.metadata.createdAt),
    //         lastLogInAt: user.metadata.lastLogInAt
    //         },
    //         uid: user.uid,
    //         photoURL: user.photoURL,
    //         provider: user.providerData[0].providerId
    //     }
    //     dispatch(setCurrentUser({...newUser}));
    // }

    return (
        <div>
            <Routes>
                <Route path='' element={<LandingPage />}></Route>
                <Route path='/signin' element={<SignIn />}></Route>
                <Route path='/signin/:joinCode' element={<SignIn />}></Route>
                <Route path='/register' element={<Register />}></Route>
                <Route path='/register/:joinCode' element={<Register />}></Route>
                <Route path='/logout' element={<LogoutPage />}></Route>
                <Route path='/loading' element={<LoadingPage />}></Route>
                <Route path='/loading/:joinCode' element={<LoadingPage />}></Route>
                <Route path='/profile' element={<ProfilePage />}></Route>
                <Route path='/profile/:joinCode' element={<ProfilePage />}></Route>
                <Route path='/home/*' element={
                    <HomePage 
                        orgs={orgs}
                        managingList={managingList}
                        enrolledList={enrolledList} />
                }></Route>
                <Route path='/myorgs/*' element={<MyOrgs orgs={orgs} />}></Route>
                <Route path='/join' element={<JoinPage />}></Route>
                <Route path='/join/:joinCode/*' element={<JoinPage />}></Route>
                <Route path='/manage/:gamePath/*' element={
                    <ManageGamePage 
                        orgs={orgs}
                        managingList={managingList} />
                }></Route>
                <Route path='/play/:gamePath/*' element={
                    <PlayGamePage
                        enrolledList={enrolledList} />
                }></Route>
                <Route path='/myplan' element={<MyPlan />}></Route>
                <Route 
                    path='printlogins/:gamePath'
                    element={<PrintLogins />}
                />
                <Route path='/admin/*' element={<AdminPage />}></Route>
                <Route path='/changelog' element={<ChangeLog />}></Route>
                <Route path='/live/:livePath' element={<LeaderboardPage />}></Route>
                <Route path='/pricing' element={<PricingPage />}></Route>
                <Route path='/quote' element={<QuotePage />}></Route>
                <Route path='/terms' element={<TermsOfService />}></Route>
                <Route path='/privacy' element={<PrivacyPolicy />}></Route>
                <Route path='/coppa' element={<Coppa />}></Route>
                <Route path='/404' element={<PageNotFound />}></Route>
                <Route path='/construction' element={<UnderConstruction />}></Route>
                <Route path="*" element={<Navigate replace to="/404" />} />
                <Route path="/noaccess" element={<Navigate replace to="/404" />} />
            </Routes>
        
        </div>
    );
}

export default App
