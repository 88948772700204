import React, { useRef, useState } from 'react';
import './distribute-individual.styles.scss';
import PlayerNameIcon from '@/components/PLAYER/player-blocks/components/status-elements/player-name-icon.component';
import RewardsForm from '../create-elements/blocks/rewards-form.component';
import { useNavigate } from 'react-router-dom';
import { ModalConfirm } from '@/components/modal/modal.component';
import FullPageLoader from '@/components/loader/full-page-loader.component';
import { UseCloudGameplay } from '@/utils/firebase.utils';

const DistributeIndividual = ({ gameData, gameElements, playerData, setAlertMessage }) => {

    const [ formData, setFormData ] = useState({});
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const dataRef = useRef();
    const approveRef = useRef();
    const navigate = useNavigate();

    function saveData (key, value) {
        dataRef.current = {...dataRef.current, ...{[key]: value}};
        setFormData(dataRef.current);
    }

    function cancelDistribution() {
        dataRef.current = {};
        setFormData(dataRef.current);
        navigate(-1);
    }

    function distributeRewards() {
        if (!formData.title) {
            setAlertMessage('Please give this reward a title so your players know why they are receiving it.');
            return;
        }
        setConfirmMessage('Are you sure you are ready to distribute these rewards? This action cannot be undone.');
        approveRef.current = () => {
            proceedWithDistribution()
        }

        async function proceedWithDistribution() {
            
            setLoading(true);
            let tempData = {...formData};
            tempData.ts = new Date().getTime();
            tempData.playerId = playerData.playerId;
            let recipient = tempData;
            const res = await UseCloudGameplay('distributeRewards', {
                'gameId': gameData.gameId, 
                'recipient': recipient, 
                'title': tempData.title, 
                'info': tempData.info, 
                'ts': tempData.ts ,
                'playerId': playerData.playerId,
            })
            console.log(res);
            if (res.error) {
                setAlertMessage('Something went wrong, please try again. Err: ' + res.error)
                return;
            }
            setAlertMessage('Rewards distributed successfully!');
            cancelDistribution();
        }
    }

    

    return (
        <div className='distribute-individual'>
            <div className='card-title split'>
                Distribute Rewards
                {
                    (playerData) &&
                    <PlayerNameIcon player={playerData} />
                }
            </div>
            <div className='g-space-1'></div>
            <div className='reward-info g-list-item grey-background'>
                <h3>Reward Information</h3>
                <hr />
                <div className='field'>
                    <label>Reward Title</label>
                    <input type='text' value={formData.title ?? ''} onChange={(e) => saveData('title', e.target.value)} />
                </div>
                <div className='field'>
                    <label>Additional Information (optional)</label>
                    <input type='text' value={formData.info ?? ''} onChange={(e) => saveData('info', e.target.value)} />
                </div>
            </div>
            <div className="g-space-2"></div>
            <div className={`earnings-form g-list-item grey-background`}>
                <h3>Choose Rewards</h3>
                <hr />
                <RewardsForm
                    type='distribution' 
                    formData={formData}
                    saveData={saveData} 
                    gameElements={gameElements}
                    gameData={gameData}
                />
                <hr />
            </div>
            <div className="g-space-1"></div>
            <div className='buttons'>
                <button className='g-button' onClick={() => cancelDistribution()}>Cancel</button>
                <button className='g-button primary' onClick={() => distributeRewards()}>Distribute Rewards</button>    
            </div>
            <ModalConfirm show={confirmMessage} cancel={() => setConfirmMessage(null)} message={confirmMessage} onApprove={approveRef.current} />
            <FullPageLoader show={loading} />
        </div>
    )
}

export default DistributeIndividual