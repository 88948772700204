import React, { useEffect, useRef, useState } from 'react';
import './leaders-list.styles.scss';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import { useNavigate } from 'react-router-dom';
import { sortPlayers } from '@/utils/sortPlayers';

const LeadersList = ({ 
    gameData, 
    playerList,
    role 
}) => {
    
    const [ playersToDisplay, setPlayersToDisplay ] = useState(null);
    const [ containerHeight, setContainerHeight ] = useState(0);
    const [ moreLeaders, setMoreLeaders ] = useState(false);
    const navigate = useNavigate();
    const endRef = useRef(20);

    useEffect(() => {
        const height = document.getElementById('leader-card').offsetHeight;
        setContainerHeight(height - 84);
    }, [])

    useEffect(() => {
        if (!gameData || !playerList) return;
        displayLeaders();
    }, [gameData, playerList])

    function displayLeaders() {
        let sort = gameData.sortLeaders ?? 'points';
        const sortedPlayers = sortPlayers({
            'playerList': playerList, 
            'sortBy': sort,
            'filter': 'players'
        });

        let arr = []
        for (let p of sortedPlayers) {
            arr.push({
                'playerId': p.playerId,
                'icon': p.icon,
                'gradient': p.gradient,
                'fName': p.fName,
                'lName': p.lName ?? '',
                'data': p[sort] ?? 0,
                'path': p.path
            })
        }
        setPlayersToDisplay(arr.slice(0, endRef.current)); 
        if (arr.length > endRef.current) {
            setMoreLeaders(true);
        } else {
            setMoreLeaders(false);
        }
    }

    function loadMore() {
        endRef.current += 20;
        displayLeaders();
    }

    return (
        <div className='leaders-list'>
            <div className='list-container' style={{'height': `${containerHeight}px`}}>
            {
                (playersToDisplay) &&
                playersToDisplay.map((p, index) => (
                    <button 
                        key={p.playerId} 
                        className='g-button text-only leaders-list-item'
                        onClick={() => 
                            (role !== 'owner' && role !== 'manager')
                            ?
                            null
                            :
                            navigate(`/manage/${gameData.path}/players/${p.path}`)}
                    >
                        <div className='meta'>{index + 1}</div>
                        <div className='icon-div'>
                        {
                            (p.icon)
                            ?
                            <ImageContainer src={p.icon} />
                            :
                            <div className='gradient' style={{'background': p.gradient}}>{p.fName[0]}</div>
                        }
                        </div>
                        <div className='meta left'>
                            {p.lName ? `${p.fName} ${p.lName[0]}` : p.fName}
                        </div>
                        <div className='meta'>
                            {p.data.toLocaleString()}
                        </div>
                    </button>
                ))
            }
            {
                (moreLeaders) &&
                <div className='center-col'>
                    <button 
                        className='g-button small-btn'
                        onClick={() => loadMore()}
                        >
                        Load More...
                    </button>
                </div>
            }
            </div>
        </div>
    )
}

export default LeadersList