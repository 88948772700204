import React, { useEffect, useRef, useState } from 'react';
import './manage-store-page.styles.scss';
import { faPlus, faTag } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom';
import { ModalConfirm } from '@/components/modal/modal.component';
import { UseCloudFunction } from '@/utils/firebase.utils';
import StoreButtonRow from '@/components/PLAYER/store/store-components/store-buttons.component';
import { useSelector } from 'react-redux';
import SearchBar from '@/components/SHARED/search-bar/search-bar.component';
import ManageTradePortal from './manage-trade-portal.component';
import ItemSearchBar from '@/components/SHARED/search-bar/item-search-bar.component';
import StoreItemCard from '@/components/PLAYER/store/store-components/store-item-card.component';
import ManageItemList from './manage-item-list.component';

const ManageStorePage = ({ gameData, gameElements, gameStats }) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const [ availableItems, setAvailableItems ] = useState(null);
    const [ filteredItems, setFilteredItems ] = useState([]);
    const [ itemsToDisplay, setItemsToDisplay ] = useState([]);
    const [ itemType, setItemType ] = useState('inventory');
    const [ collections, setCollections ] = useState(null);
    const [ status, setStatus ] = useState(null);
    const [ saleList, setSaleList ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const approveRef = useRef();
    const availableRef = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        if (!gameElements) return;
        const now = new Date().getTime();
        const items = Object.values(gameElements)
        .filter(i => 
            i.opt_market &&
            i.opt_visible &&
            i.status === 'active' 
        )
        availableRef.current = [...items];
        console.log(availableRef.current);
        setAvailableItems([...items]);
        let statusObj = {}
        for (let i of items) {
            if (
                i.opt_visible &&
                (!i.opt_dateOpen || i.opt_dateOpen < now) &&
                (!i.opt_dateClose || i.opt_dateClose > now)
            ) {
                statusObj[i.id] = true;
            } else if (statusObj[i.id]) {
                delete statusObj[i.id];
            }
        }
        setStatus({...statusObj})
        setSaleList(
            Object.values(gameElements)
            .filter(e => 
                e.type === 'sale' &&
                (!e.opt_dateOpen || e.opt_dateOpen < now) &&
                (!e.opt_dateClose || e.opt_dateClose > now)
            )
            .sort((a,b) => a.ts > b.ts ? 1 : -1)
        )
        const coll = Object.values(gameElements)
        .filter(e => e.type === 'collection');
        setCollections([...coll])
    }, [gameElements])

    useEffect(() => {
        if (!availableItems || !itemType) return;
        console.log(itemType);
        let items = availableItems.filter(i => i.opt_itemType === itemType);
        setFilteredItems([...items]);
        setItemsToDisplay([...items]);
    },[availableItems, itemType])

    function removeFromStore(item) {
        setConfirmMessage(`Are you sure you want to remove this item from the store? ${
            item.opt_itemType !== 'contribution' ? 
            'This will not remove the price you set for this item, and you can put the item back in the store by clicking "Add Items".' :
            ''
        }`)
        approveRef.current = () => {
            continueRemoval()
        }

        async function continueRemoval() {
            setLoading(item.id);
            console.log(item);
            const res = await UseCloudFunction(
                'changeStoreStatus',
                {
                    'item': item,
                    'appId': userProfile.appId,
                }
            );
            console.log(res);
            setLoading(null);
        }
    }

    function search(text) {
        if (!text) {
            setItemsToDisplay([...availableRef.current])
            return;
        }
        let tempItems = [...availableRef.current]
        let filteredItems = tempItems
        .filter(i => 
            i.name.toLowerCase().includes(text.toLowerCase()) ||
            i.desc.toLowerCase().includes(text.toLowerCase())
        )
        setItemsToDisplay([...filteredItems]);
    }

    return (
        <div className='manage-store-page'>
            <div className='manage-store-buttons'>
                <button 
                    className='g-button med-btn'
                    onClick={() => navigate('additems')}
                >
                    Add Items
                    <FontAwesomeIcon icon={faPlus} />
                </button>
                {
                    (saleList.length > 0)
                    ?
                    <button 
                        className='g-button med-btn disabled'
                        onClick={() => navigate('sales')}
                    >
                        Manage Sales
                        <FontAwesomeIcon icon={faTag} />
                    </button>
                    :
                    <button 
                        className='g-button med-btn disabled'
                        onClick={() => navigate('sales/create')}
                    >
                        Create Sale
                        <FontAwesomeIcon icon={faTag} />
                    </button>
                }
                
                <StoreButtonRow
                    gameData={gameData} 
                    availableItems={availableItems} 
                    setItemType={setItemType} 
                    itemType={itemType}
                />
            </div>
            <div className='manage-store-content'>
                <ItemSearchBar 
                    availableItems={filteredItems}
                    setItemsToDisplay={setItemsToDisplay}
                    collections={collections}
                    itemType={itemType} 
                    noTypes={true}
                />
                <div className="g-space-1"></div>
                {
                    (itemType === 'tradeportal')
                    ?
                    <ManageTradePortal />
                    :
                    (itemsToDisplay.length > 0)
                    ?
                    <ManageItemList
                        itemsToDisplay={itemsToDisplay} 
                        gameElements={gameElements}
                        gameStats={gameStats}
                        saleList={saleList}
                        gameData={gameData}
                    />
                    :
                    <h4>No Items</h4>
                }
            </div>
            <ModalConfirm
                show={confirmMessage}
                cancel={() => setConfirmMessage(null)}
                message={confirmMessage}
                onApprove={approveRef.current}
            />
        </div>
    )
}

export default ManageStorePage