import React, { useEffect } from 'react'
import './player-challenge-item.styles.scss';
import { stripHtml } from '@/utils/sanitize';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import { format } from 'date-fns';

const PlayerChallengeItem = ({ 
    challenge, 
    onClick, 
}) => {

    useEffect(() => {
        console.log(challenge);

    }, [challenge])

    return (
        <div className='player-challenges'>
            {
                (
                    !challenge.opt_preview && 
                    (
                        !challenge.opt_available ||
                        challenge.opt_dateOpen > new Date().getTime()
                    )
                )
                ?
                <div className='player-challenge-item g-list-item'>
                    <ImageContainer 
                        src='/images/icons/mystery-challenge.png' 
                        alt='mystery challenge icon' 
                        className='challenge-icon' />
                    <div className='challenge-name'>
                        Mystery Challenge
                    </div>
                    {
                        (challenge.opt_dateOpen > new Date().getTime()) &&    
                        <div className='meta'>
                            Opens {format(new Date(challenge.opt_dateOpen), "Pp")}
                        </div>
                    }
                </div>
                :
                <div className={`player-challenge-item g-list-item ${
                    (challenge.access || challenge.opt_preview) 
                    ? 'clickable with-shadow'
                    : ''
                }`} 
                onClick={
                    (challenge.access || challenge.opt_preview) ? 
                    onClick : 
                    null
                }>
                {
                    (challenge)
                    ?
                    <>
                        <div className='flags'>
                            {
                                (challenge.flags.length > 0) &&
                                challenge.flags.map(f => {
                                    if (
                                        f.text === 'Upcoming' ||
                                        (
                                            f.text === 'Closed' &&
                                            challenge.flags.length > 1
                                        )
                                    ) return;
                                    return (
                                        <div key={f.text} className={`indicator ${f.class}`}>
                                            {f.text}
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <ImageContainer src={challenge.icon} alt={`${challenge.name} challenge icon`} className='challenge-icon' />
                        <div className='challenge-name'>
                            {challenge.name}
                            {
                                (challenge.opt_dateClose) &&
                                <>
                                <div className='g-space-0-5'></div>
                                <div className='meta'>
                                    {
                                        challenge.opt_dateClose > new Date().getTime() 
                                        ? 'Closes' 
                                        : 'Closed'
                                    } {format(new Date(challenge.opt_dateClose), "Pp")}
                                </div>
                                </>
                            }
                        </div>
                        <div className='challenge-desc'>
                            {
                                challenge.desc && stripHtml(challenge.desc).substring(0,40)
                            }
                            {
                                challenge.desc && stripHtml(challenge.desc).length > 40 ? 
                                '...' : 
                                ''
                            }
                        </div>
                    </>
                    :
                    (challenge)
                    ?
                    <>
                        <ImageContainer src={'/images/icons/win.png'} alt={`${challenge.name} challenge icon`} className='challenge-icon disabled' />
                        <div className='challenge-name'>{challenge.name}</div>
                        <div className='challenge-desc'>You do not have access to this challenge yet.</div>
                    </>
                    :
                    null
                }
                </div>
            }
        </div>
    )
}

export default PlayerChallengeItem