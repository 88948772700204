import React, { useEffect } from 'react'
import './terms-of-service.styles.scss';
import UnauthHeader from '@/components/layout-unauth/unauth-head.component';
import Footer from '@/components/layout-unauth/footer/footer.component';
import { changeDocumentTitle } from '@/utils/useDocumentTitle';


const TermsOfService = () => {

    useEffect(() => {
        changeDocumentTitle();
    }, [])

    return (
        <div className='terms-of-service'>
            <UnauthHeader />
            <div className='terms-container'>
                <div className='g-space-2'></div>
                <div className='info-card g-card'>
                    <div className='card-title'>
                        Terms of Service
                    </div>
                    <hr />
                    <div className='terms-content'>
                        <h4>FOLLOWING TERMS AND CONDITIONS GOVERN YOUR USE OF THE SITE. PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THIS SITE.</h4> 
                        <p>By using the Site you signify your agreement to these terms and conditions (the "Terms").</p>

                        <p>For information on the data we collect and how we use it, read our <a href="/privacy" target="_blank">Privacy Policy</a>.</p>

                        <p>Gamably.com (<b>"Gamably.com"</b>) is an online service offering gamification management tools for use in business, educational, or home environments (the <b>"Service"</b>). This site (including all services and functionality collectively, the <b>"Site"</b>) is owned and operated by Gamably, LLC, (<b>"Gamably"</b>) (collectively, <b>"we," "us," "our"</b>). The Terms set forth herein apply to all information, online communications, services, text, video, audio files, graphics, still images, links, or other material and content that is or becomes available on the Site or is otherwise offered for the Service (collectively, <b>"Content"</b>). By accessing or using the Site, you specifically agree to abide by these Terms and any modifications thereto.</p>

                        <p>Graphics and icons used within the Site are either created by Gamably or used with permission according to Flaticon.com's free license as defined under their <a href="https://www.freepikcompany.com/legal#nav-flaticon" target="_blank">Terms and Conditions</a>. Attribution and license agreements for each of the groups of icons can be found <a href="#icons">here</a>.</p>
                        
                        <h3>Registration</h3>
                        <p>Certain components, portions, features, or services available on the Site may only be accessible and used after registration and creation of an account. When creating your account, you must provide true, accurate, and complete information, and maintain and promptly update this information. If you provide any information that is untrue, inaccurate, or incomplete, or if we have reasonable grounds to suspect that the information is untrue, inaccurate, or incomplete, we have the right to suspend or terminate your account and access to the Site. You may be asked to provide your email address and a password during the registration process. You are responsible for maintaining the confidentiality of your password, and are solely responsible for all activities that occur with your account. You must immediately notify us of any unauthorized use of your account or any other breach of security. Although we will not be liable for your losses caused by any unauthorized use of your account, you may be liable for our losses or the losses of others due to such unauthorized use. You are responsible for obtaining access to the Site, which access may involve third party fees (such as Internet service provider charges). In addition, you must provide and are responsible for all equipment necessary to access the Site.</p>

                        <p>We cannot access passwords associated with a Google account or with your account created within the Site. To retrieve or reset your Google password, you must do that through Google's account services. To reset your password in an account created within the Site, you can click on "Forgot My Password" on the login page. An email will be sent to you with instructions to reset your password. To remove your account from the Site, please contact <a href="mailto:adam@gamably.com" target="_blank">adam@gamably.com</a> and it will be promptly removed.</p>

                        <h3>Restrictions on Use of Site and Content</h3>
                        <p>Non-commercial Use Only. This Site and the Content therein may be used for bona fide non-commercial purposes only. You may not use this Site or its Content in a defamatory or derogatory manner. In addition, you may not use this Site or its Content for or in any commercial or for-profit manner without the prior written consent of Gamably. For clarity, this means the Site or its Content may not be used in any commercial tie-in (i.e., use of the content in connection with marketing merchandise or services and/or in connection with premiums or promotions, or use of the Site or Content in order to gain advertising or subscription revenue), you may not charge anyone for this content (e.g., charge for access to the Site or Content), you may not incorporate this content into a product that you sell for profit (e.g., selling ads on another site targeted to the Site or Content), you may not use the content to advertise or promote a product, nor may you use the content in any other manner that may be construed as an endorsement, express or implied, of any party, product, or other entity. Furthermore, you may not create, recreate, distribute, or advertise an index of any of the Content or Site unless authorized by Gamably in writing. You may not build a business utilizing the Content or Site, whether or not for profit. The Content covered by these restrictions includes without limitation any text, graphics, artwork, layout, interface, logos, links, photographs, audio files, video, and still images.</p>

                        <p>You may not circumvent, disable or otherwise interfere with security- related features of the Site or features that prevent or restrict use or copying of any Content. You further agree not to reverse engineer or jeopardize the correct functioning of the Website or attempt to gain access to secured portions of the Site to which you do not possess access rights.</p>

                        <p>You may not collect or harvest any personally identifiable information from the Site, nor use the communications system provided by the Site (e.g., comments) for any commercial solicitation purposes. You may not use the Site to generate unsolicited email advertisements or spam, or to conduct or promote any illegal activity.</p>

                        <h3>Attribution and Citations</h3>
                        <p>In the event of any permitted copying, redistribution or publication of copyrighted material, you must keep intact all proprietary and copyright notices on the Content, and shall include proper attribution for all Content used in any presentation or derivative work you create with the Content. Watermarks, title names, and producer information within video content may not be changed, edited, removed or obstructed. Unless otherwise noted on the Content, reference to Gamably.com and Gamably, LLC, as the source of the Content is required for all Content including video clips, video stills, images, and lesson plans.</p> 

                        <p>You understand that when using the Site, you will be exposed to User Generated Content (defined below) from a variety of sources, and that we are not responsible for the accuracy, usefulness, safety, or intellectual property rights of or relating to such User Generated Content. Though we expect all users to adhere to our Terms, you understand and acknowledge that you may be exposed to User Generated Content that is inaccurate, offensive, indecent, or objectionable, and you hereby waive any legal or equitable rights or remedies you may have against us with respect thereto, and agree to indemnify and hold Gamably and our employees, officers, trustees, affiliates, and/or licensors, harmless to the fullest extent allowed by law regarding all matters related to your use of the Site.</p>

                        <h3>User Generated Content</h3>
                        <p>This Site permits the submission of video, audio, textual, and other content submitted by you and other users ("User Generated Content") and the hosting, sharing and/or publishing of such User Generated Content. You understand that whether or not such User Generated Content is published, we do not guarantee confidentiality with respect to any such User Generated Content.</p>

                        <p>You shall be solely responsible for your own User Generated Content and the consequences of our posting or publishing them. By submitting your User Generated Content to us, you affirm, represent, and warrant that:

                        <ol><li>you own or have the necessary licenses, rights, consents, and permissions to upload, use, and authorize us to use all patent, trademark, trade secret, copyright, or other proprietary rights, including privacy and publicity rights, in and to any and all User Generated Content to enable inclusion, distribution and use of the User Generated Content in the manner contemplated by the Site and these Terms;</li>

                        <li>you have the written consent, release, and/or permission of each and every identifiable individual person in the User Generated Content to use the name or likeness of each and every such identifiable individual person to enable inclusion, distribution and use of the User Generated Content in the manner contemplated by the Site and these Terms;</li>

                        <li>the User Generated Content is your own original work or that you own or control the necessary rights to enable inclusion, distribution and use of the User Generated Content in the manner contemplated by the Site and these Terms;</li>

                        <li>the User Generated Content does not in any way violate any Federal, state, or local laws or regulations,</li>

                        <li>the User Generated Content is not in any way libelous, slanderous, derogatory, abusive, defamatory, invasive of privacy or publicity rights, vulgar, profane, obscene, offensive, pornographic, threatening, harassing, racist, hateful, nor does it encourage conduct that would be considered a criminal offense, or give rise to civil liability;</li>

                        <li>any and all assertions made in User Generated Content are accurate where they state facts;</li>

                        <li>the User Generated Content is not used to deceive, impersonate any person, business, or entity, or falsely claim (or give the false impression of) an affiliation with any person, business, or entity, including PBS LearningMedia, PBS, and/or WGBH, and their employees and agents;</li>

                        <li>the User Generated Content does not violate or infringe upon the rights of any person or entity whatsoever;</li>

                        <li>the User Generated Content is not an endorsement, express or implied, of any party, product, or other entity;</li>

                        <li>the User Generated Content is not used to make unsolicited offers, advertisements, or proposals to other Users; and</li>

                        <li>the User Generated Content does not contain viruses or any other computer code, files or programs that interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment, or otherwise permit the unauthorized use of a computer or computer network.</li></ol></p>

                        <p>Unless you enter into a separate written agreement with Gamably, we do not claim ownership in User Generated Content you submit; however, by submitting the User Generated Content to us, you hereby waive any and all moral rights in connection with the User Generated Content and you automatically grant us a worldwide, non-exclusive, royalty-free, and transferable license to use, reproduce, distribute, create derivative works of (including without limitation, to rename, edit, shorten, if video, split the videos into different segments, and use the entire User Generated Content or segments thereof), publish, transfer, transmit, publicly display, and publicly perform the User Generated Content on, through or in connection with the Site (including the advertisement and promotion thereof) in any media formats and through any media channels, or for any other non-commercial use of your User Generated Content. You also hereby grant each user of the Site a non-exclusive license to access your User Generated Content through the Site, and to use, reproduce, distribute, create derivative works of, publish, transfer, transmit, publicly display, and publicly perform such User Generated Content (in whole or in segments) as permitted through the functionality of the Site and under these Terms.</p>

                        <p>In connection with your User Generated Content, you further agree that you will not upload content, or distribute anything that may be harmful to minors or vulnerable adults or that violates these Terms.</p>

                        <p>We do not endorse any User Generated Content or any opinion, recommendation, or advice expressed therein, and we expressly disclaim any and all liability in connection with User Generated Content. Opinions on the Site are not to be construed as assertions of fact. User Generated Content on the Site may contain links to third-party websites that are not owned or controlled by us. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third party websites. By using the Site, you expressly relieve us from any and all liability from your use of any third-party website.</p>

                        <p>Your User Generated Content will remain on the Site until such time as you remove or delete such content from the Site. When you do remove your content, the license described above will automatically expire within a commercially reasonable time after you remove or delete your User Generated Content from the Site.</p>


                        <h3>Removal of Content</h3>
                        <p>We do not permit copyright infringing activities and infringement of intellectual property rights on this Site, and we will remove all Content (including User Generated Content) if properly notified - pursuant to the "Notification of Copyright Infringement" section - that such Content infringes on another's intellectual property rights. We reserve the right to remove Content, including User Generated Content without prior notice.</p>

                        <p>We reserve the right to decide whether Content or User Generated Content is appropriate and complies with these Terms for violations other than copyright infringement, such as, but not limited to, pornography, obscene or defamatory material, or excessive length. We may remove such User Generated Content and/or terminate a User's access for uploading such material in violation of these Terms at any time, without prior notice and at our sole discretion.</p>

                        <p>We reserve the right to change or discontinue temporarily or permanently any Content available through the Site at any time without notice. We will not be liable to you or any third party for any modification of the Service.</p>

                        <p>Consistent with California law, registered users who are California residents and are under 18 years old are entitled to request and obtain removal of content and information that they themselves post on the Site by contacting us at <a href="mailto:adam@tamingthetech.com" target="_blank">adam@tamingthetech.com</a>. In your request, please provide a description of the material that you want removed and information reasonably sufficient to permit us to locate the material, and include your name, email address, year of birth, mailing address (including city, state, and zip code), and the phrase “California Removal Request” so that we can process your request. Please note that your request does not ensure complete or comprehensive removal of the material. For example, materials that you have posted may be republished or reposted by another user or third party.</p>


                        
                        <h3>Copyright</h3>
                        <p>As among you, Gamably, all rights including copyright and database rights, in this Site and its contents, including all text, images, software, illustrations, artwork, high resolution photography, video clips, audio clips, any after-sales material, and ancillary materials, but excluding User Generated Content (defined in the User Generated Content section), are owned by or licensed to Gamably, or otherwise used by us as permitted by applicable law or agreement. </p>

                        <a id="icons"></a>
                        <h3>Icon Licenses</h3>
                        <p>Icons and icon sets contained within the Site are either created and owned by Gamably, or are licensed to be used by the Site. Specific attribution and licenses for icon sets can be found below:</p>
                        <ul>
                            <li>Chameleon Icon in Gamably.com logo made by <a href="https://www.flaticon.com/authors/kiranshastry" title="Kiranshastry">Kiranshastry</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>The license agreement can be found <a href="../info/licenses/chameleonLicense.pdf" target="_blank">here</a>.</li>
                            <li>Happy People Icons made by <a href="https://www.flaticon.com/authors/iconixar" title="iconixar">iconixar</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>. The license agreement can be found <a href="../info/licenses/peopleLicense.pdf" target="_blank">here</a>.</li>
                            <li>Badge Icons are made by <a href="https://www.flaticon.com/authors/vectors-market" title="Vectors Market">Vectors Market</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>. The license agreement can be found <a href="../info/licenses/badgeLicense.pdf" target="_blank">here</a>.</li>
                            <li>Level Icons made by <a href="https://www.flaticon.com/authors/dimitry-miroliubov" title="Dimitry Miroliubov">Dimitry Miroliubov</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>. The license agreement can be found <a href="../info/licenses/levelLicense.pdf" target="_blank">here</a>.</li>
                            <li>Secret Code Icon and Landscape Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a>. The license agreement can be found <a href="../info/licenses/codeLicense.pdf" target="_blank">here</a>.</li>
                            <li>Icons made by <a href="https://www.flaticon.com/authors/smashicons" title="Smashicons">Smashicons</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></li>
                            <li>Game Elements Icons made by <a href="https://www.flaticon.com/authors/surang" title="surang">surang</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></li>
                            <li>Game Icons made by <a href="https://www.flaticon.com/authors/smashicons" title="Smashicons">Smashicons</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></li>
                            <li><a href='https://www.freepik.com/vectors/map'>Map vector created by macrovector - www.freepik.com</a></li>
                            <li><a href='https://www.freepik.com/vectors/business'>Business vector created by macrovector - www.freepik.com</a></li>
                            <li><a href='https://www.freepik.com/vectors/water'>Water vector created by upklyak - www.freepik.com</a></li>
                            <li><a href='https://www.freepik.com/vectors/background'>Background vector created by upklyak - www.freepik.com</a></li>
                            <li><a href='https://www.freepik.com/vectors/city'>City vector created by tartila - www.freepik.com</a></li>
                            <li><a href='https://www.freepik.com/vectors/design'>Design vector created by freepik - www.freepik.com</a></li>
                            <li><a href='https://www.freepik.com/vectors/background'>Background vector created by macrovector - www.freepik.com</a></li>
                            <li><a href='https://www.freepik.com/photos/travel'>Travel photo created by freepik - www.freepik.com</a></li>
                    <li><a href="https://www.flaticon.com/free-icons/stamp" title="stamp icons">Stamp icons created by Flowicon - Flaticon</a></li>
                    <li><a href="https://www.flaticon.com/free-icons/box" title="box icons">Box icons created by Becris - Flaticon</a></li>
                        </ul>


                        <h3>Trademarks</h3>
                        <p>Unless otherwise noted, all trademarks, service marks, and trade names are proprietary to Gamably. Nothing contained herein shall be construed as conferring to you any license or right under any trademark or patent of Gamably.</p>


                        <h3>Users' Obligation to Abide By Applicable Law</h3>
                        <p>In connection with the use of the Site, you shall not engage in conduct or publish information which would infringe upon or injure the personal or property rights of any individual, group, or entity, including but not limited to defamation, harassment, invasion of privacy, tort, or disclosure of confidential or trade secrets. Furthermore, you shall not violate any law or treaty or intellectual property rights protected by law (such as copyright, patent and trademark rights). You acknowledge that the Content and materials available on the Site include intellectual property that is protected under the copyright, trademark, and other intellectual property laws of the United States and/or other countries ("Intellectual Property Laws"). Such Intellectual Property Laws generally prohibit the unauthorized reproduction, distribution, or exhibition of all text, photographic and graphic (art and electronic) images, music, sound samplings, and other protected materials. The violation of applicable Intellectual Property Laws may give rise to civil and/or criminal penalties.</p>


                        <h3>Notification of Copyright Infringement</h3>
                        <p>a. Notice: If you are a copyright owner or an agent of a copyright owner and believe that any Content on the Site infringes upon your copyrights, you may submit a notification in writing to our designated agent pursuant to the Digital Millennium Copyright Act ("DMCA") section 17 USC 512 (c) (3). All notifications of claimed copyright infringement should be sent to our designated agent: Adam Geisen, Taming The Tech, LLC; e-mail: <a href="mailto:adam@tamingthetech.com" target="_blank">adam@tamingthetech.com</a>. According to 17 USC section 512 (c)(3)(a), all copyright infringement notifications must include the following information:</p>

                        <ul><li>A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed;</li>
                        <li>Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works at a single online site are covered by a single notification, a representative list of such works at that site;</li>
                        <li>Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit the service provider to locate the material;</li>
                        <li>Information reasonably sufficient to permit the service provider to contact the complaining party, such as an address, telephone number, and, if available, an electronic mail address at which the complaining party may be contacted;</li>
                        <li>A statement that the complaining party has a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and</li>
                        <li>A statement that the information in the notification is accurate, and under penalty of perjury, that the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</li></ul>

                        <p>You acknowledge that if you fail to comply with all of the requirements of Section (a), your notice may not be valid pursuant to the DMCA.</p>
                        
                        <p>b. Counter-Notice: If you believe that your Content that was removed (or to which access was disabled) is not infringing or that you have the authorization from the copyright owner, the copyright owner's agent, or pursuant to law, to post and use the Content, then you may send a counter-notice containing the following information to the designated agent identified in the Notification of Copyright Infringement Section (a):</p>
                        
                        <ul><li>A physical or electronic signature.</li>
                        <li>Identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or access to it was disabled.</li>
                        <li>A statement under penalty of perjury that you have a good faith belief that the material was removed or disabled as a result of mistake or misidentification of the material to be removed or disabled.</li>
                        <li>Your name, address, telephone number, e-mail address, and a statement that you consent to the jurisdiction of the Federal courts in Virginia, and a statement that you will accept service of process from the person who provided notification of the alleged infringement.</li></ul>

                        <h3>Disclosure of Online Communications</h3>
                        <p>You are cautioned that any online communications may not be fully confidential. In addition, you should be aware that federal postal regulations do not protect electronic mail. You should be aware that some administrative personnel of Gamably may, in the course of their regular duties, have access to communications required by law. Gamably may also disclose any communications to the extent permitted or required by law.</p>


                        <h3>Rogue Programming and Viruses</h3>
                        <p>Gamably is not responsible for any computer virus, trojan horse, timebomb, worm, or any other rogue programming ("Rogue Programming") that may be contained in any of the Content, material or software contained on the Site. We have no obligation to detect the presence of any Rogue Programming. Any downloading of software or other materials or any other use of the Content on the Site is at your risk, and you are advised to take adequate precautions to minimize any loss to your system caused by Rogue Programming, including use of anti-virus programs and proper backup of files. You agree not to post, transmit, or make available in any way through the Site any software or other materials that contain Rogue Programming.</p>


                        <h3>Content of Information</h3>
                        <p>You are responsible for the content of any information you put on the Site. We have no obligation to, and do not in the normal course, monitor, prescreen, or control any User Generated Content that is or becomes available on the Site. We reserve the right to review any User Generated Content that is or becomes available on the Site. We also reserve the right to refuse to post, to redact, edit or to move or remove any User Generated Content that is, in our sole discretion, unacceptable, undesirable, or in violation of these Terms. However, we have no obligation to exercise our reservations of rights and are not responsible for any failure or delay in removing such material.</p>


                        <h3>Disclaimer of Warranties</h3>
                        <p>The Site, the Content and other material on the Site are provided on an "As Is" basis without warranties of any kind, either express or implied, including without limitation warranties of title, non-infringement, or implied warranties of merchantability or fitness for a particular purpose. We do not warrant that any Content is complete or accurate, that the Site will be uninterrupted or error free, or that any Content or material is free of Rogue Programming.</p>


                        <h3>Limitation of Damages</h3>
                        <p>Under no circumstance, including negligence, shall we be liable for any direct, indirect, incidental, special, punitive, or consequential damages that may result from the use or inability to use the Site, including without limitation use of or reliance on Content available on the Site, interruptions, errors, defects, mistakes, omissions, deletions of files, delays in operation or transmission, non-delivery of Content, disclosure of communications, or any other failure of performance.</p>


                        <h3>Release and Indemnity</h3>
                        <p>Though we expect all users to adhere to our content policies in these Terms, you may be exposed to content that violates our policies or is otherwise offensive through the use of the Site. Your use of the Site is at your own risk. You agree that we are not liable for content that is provided by others. We take no responsibility for your exposure to content on the Site whether it violates our content policies or not. You understand that the information and opinions in content uploaded by third parties represent solely the thoughts of the author and is neither endorsed by nor does it necessarily reflect our beliefs. You hereby release and waive any and all claims and/or liability against Gamably and our employees, officers, trustees, affiliates, and/or licensors, arising from or in connection with your use of the Site, the Content and/or any other material on the Site. You also agree to defend, indemnify, and hold harmless Gamably and our employees, officers, trustees, affiliates, and/or licensors from and against any and all claims or liability, including costs and attorneys fees, arising from or in connection with your use of the Site, the Content and/or any other material on the Site, or failure to abide by applicable law.</p>


                        <h3>Modifications</h3>
                        <p>These Terms and the Content may be revised, amended, or supplemented by Gamably without notice at any time for any reason. Continuing to use the Site after a change has been made will signify your acceptance of the changes. You should refer back to this page for future updates.</p>


                        <h3>Privacy Policy</h3>
                        <p>The Site's visitors' <a href="/privacy">Privacy Policy</a> provides additional terms and conditions that apply to your use of our Site. If you do not agree to the conditions therein, please do not use the Site.</p>

                        

                        <h3>Other</h3>
                        <p>These Terms will be governed by and interpreted in the English language pursuant to the laws of the State of Illinois, United States of America, without giving effect to any principles of conflicts of law. If any provision of this agreement shall be unlawful, void, or for any reason, unenforceable then that provision shall be deemed severable from this agreement and shall not affect the validity and enforceability of any remaining provisions.</p>
                    </div>
                </div>
                <div className='g-space-2'></div>
            </div>
            <Footer />
        </div>
    )
}

export default TermsOfService