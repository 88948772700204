import React, { useEffect, useState } from 'react';
import './my-badges.styles.scss';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import { useNavigate } from 'react-router-dom';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import { format } from 'date-fns';
import PlayerNameIcon from '../player-name-icon.component';

const MyBadges = ({ playerData, gameElements }) => {

    const [ loading, setLoading ] = useState(true);
    const [ sortedList, setSortedList ] = useState(null);
    const [ visibleBadges, setVisibleBadges ] = useState(null);
    const [ categories, setCategories ] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            if (!sortedList && !visibleBadges ) {
                setLoading(false);
            }
        }, 3000)

        return () => {
            if (timer) clearTimeout(timer);
        }
    }, [])

    useEffect(() => {
        if (!gameElements) return;
        sortBadges();
    }, [gameElements])

    function sortBadges() {
        const activeBadges = Object.values(gameElements)
            .filter(e => e.type === 'badge' && e.status === 'active')
            .sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        setSortedList(activeBadges);
        let catList = [];
        for (let b of activeBadges) {
            if (b.opt_cat && !catList.includes(b.opt_cat)) {
                catList.push(b.opt_cat);
            }
        }
        if (catList.length > 0) {
            catList.push('Uncategorized');
        }
        setCategories(catList.sort());
        setLoading(false);
    }

    return (
        <div className='my-earned-badges-list'>
            <div className='g-card list-card'>
                <BackButton cancel={() => navigate(-1)} />
                <div>
                    <div className='card-title split'>
                        My Badges
                        {
                            (location.pathname.includes('/manage/')) &&
                            <PlayerNameIcon player={playerData} />
                        }
                    </div>
                    <hr />
                </div>
                <div className='badge-list-container'>
                {
                    (loading)
                    ?
                    <div className='center-col'><ThreeDotsLoader /></div>
                    :
                    (sortedList)
                    ?
                    (categories && categories.length > 0)
                    ?
                    categories.map(c => (
                        <div key={c} className='category-list'>
                            <h4>{c}</h4>
                            <hr />
                            <div className='badge-list'>
                                {
                                    sortedList.filter(b => (b.opt_cat === c || c === 'Uncategorized' && !b.opt_cat) && b.opt_visible)
                                    .map(b => (
                                        <div key={b.id}>
                                            <BadgeItem badgeData={b} playerData={playerData} />
                                        </div>
                                    ))
                                }
                            </div>
                            <div className="g-space-2"></div>
                        </div>
                    ))
                    :
                    <div className='badge-list'>
                        {
                        sortedList.map(b => {    
                            return (
                                <div key={b.id} >
                                    <BadgeItem badgeData={b} playerData={playerData} />
                                </div>
                            )
                        })
                        }
                    </div>
                    : 
                    <h4 className='center-col'>
                        No badges yet...
                    </h4>
                }
                </div>
            </div>
            <div className="g-space-2"></div>
        </div>
    )
}

export default MyBadges

const BadgeItem = ({ badgeData, playerData}) => {

    const [ earned, setEarned ] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (!badgeData || !playerData) return;
        if (playerData.badges && playerData.badges[badgeData.id]) {
            setEarned(playerData.badges[badgeData.id].ts);
        }

    }, [badgeData, playerData])

    return (
        <button 
            className={`g-button badge-item g-list-item ${!earned ? 'disabled' : ''}`}
            onClick={() => navigate(badgeData.path)}
        >
            <ImageContainer 
                src={!earned && !badgeData.opt_preview ? '/images/icons/mystery-badge.png' : badgeData.icon} 
                className='icon' 
                alt={`${badgeData.name}'s icon`} 
                grayscale={!earned} 
            />
            <div className='badge-name'>{!earned && !badgeData.opt_preview ? '' : badgeData.name}</div>
            {earned ? <div className='meta'>Earned: {format(new Date(earned), "P")}</div> : null}
        </button>
    )
}