import React, { useEffect, useRef, useState } from 'react'
import './view-action-item.styles.scss';
import { useNavigate, useParams } from 'react-router-dom';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import { useDispatch, useSelector } from 'react-redux';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import { format } from 'date-fns';
import { stripHtml } from '@/utils/sanitize';
import Modal, { ModalAlert, ModalConfirm } from '@/components/modal/modal.component';
import linkifyHtml from 'linkify-html';
import UploadedFileButton from '@/components/SHARED/uploaded-file-button/uploaded-file-button.component';
import RewardsForm from '../create-elements/blocks/rewards-form.component';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import ToggleInput from '@/components/SHARED/toggle-input/toggle-input.component';
import { UseCloudGameplay } from '@/utils/firebase.utils';
import FullPageLoader from '@/components/loader/full-page-loader.component';

const ViewActionItem = ({ 
    gameData, 
    gameElements, 
    playerList,
    actionItems 
}) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const [ currentItem, setCurrentItem ] = useState(null); 
    const [ elementData, setElementData ] = useState(null);   
    const [ playerData, setPlayerData ] = useState(null);
    const [ fullDescription, setFullDescription ] = useState(null);
    const [ formData, setFormData ] = useState({})
    const [ loading, setLoading ] = useState(false);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const { itemPath } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const dataRef = useRef();
    const approveRef = useRef();
    const currentItemRef = useRef();

    useEffect(() => {
        dataRef.current = {'status': 'approved'}
        setFormData({...dataRef.current})
    }, [])
    
    useEffect(() => {
        if (!itemPath || !actionItems || !gameElements || !playerList) return;
        const chosenActionItem = Object.values(actionItems).filter(i => i.path === itemPath)[0];
        console.log(chosenActionItem);
        currentItemRef.current = {...chosenActionItem};
        setCurrentItem(currentItemRef.current);
        setElementData({...gameElements[chosenActionItem.elementId]})
        setPlayerData({...playerList[chosenActionItem.playerId]})
    }, [itemPath, actionItems, gameElements, playerList])

    useEffect(() => {
        if (!elementData) return;
        for (let key of Object.keys(elementData)) {
            if (key.includes('opt_earned')) {
                saveData(key, elementData[key]);
            }
        }
    }, [elementData])

    function saveData(key, value) {
        if (key === 'status') {
            if (value) {
                value = 'approved'
            } else {
                value = 'declined'
            }
        }
        dataRef.current = {...dataRef.current, ...{[key]: value}}
        setFormData(dataRef.current);
    }

    async function handleSubmit() {
        setConfirmMessage(`Are you sure you are ready to <b>${formData.status === 'approved' ? 'APPROVE' : 'DECLINE'}</b> this submission? This action cannot be undone.`)
        approveRef.current = () => {
            continueApproval();
        }
        async function continueApproval() {
            console.log('continue')
            setLoading(true);
            let tempData = {...formData};
            const now = new Date().getTime();
            let approvalObj = {
                'approval_ts': now,
                'approval_name': userProfile.name,
                'approval_appId': userProfile.appId,
                'status': tempData.status ?? 'declined',
                'ts': now,
                'comments': tempData.comments
            }
            tempData.element = elementData;
            tempData.actionItem = currentItemRef.current;
            console.log(tempData);
            const res = await UseCloudGameplay(
                'approveActionItem', 
                {
                    'contentData': tempData, 
                    'approvalData': approvalObj, 
                    'gameId': gameData.gameId, 
                    'playerId': playerData.playerId
                }
            )
            console.log(res);
            if (res.error) {
                setAlertMessage(`Something went wrong. Please try again later. (Error: ${res.error})`);
                setLoading(false);
                return;
            }
            navigate(-1);
        }
    }

    return (
        <div className='action-items-content'>
            <div className='g-card'>
                <BackButton cancel={() => navigate(-1)} /> 
                <div className="g-space-1"></div>
                {
                    (currentItem && elementData) &&
                    <div className='action-item-content'>
                        <div className='card-title'>
                            {
                                currentItem.type === 'challenge' ?
                                'Challenge Submitted' :
                                currentItem.type === 'item' ?
                                'Item Used' : 
                                ''
                            }
                        </div>
                        <hr />
                        <div className='info-head'>
                            <ImageContainer src={elementData.icon} alt={`${elementData.name} icon`} />
                            <div className='text-div'>
                                <div className='card-title'>{elementData.name}</div>
                                {
                                    (currentItem.type === 'challenge') &&
                                    <>
                                    <div className='meta'>Post Date: {format(new Date(elementData.opt_dateOpen ?? elementData.ts), 'Pp')}</div>
                                    <div className="g-space-0-5"></div>
                                    {
                                        (elementData.opt_dateClose) &&
                                        <div className='meta'>Close Date: {format(new Date(elementData.opt_dateClose), 'Pp')}</div>
                                    }
                                    </>
                                }
                                <div className='description'>
                                    {
                                        elementData.desc 
                                        ? 
                                        stripHtml(elementData.desc).substring(0, 200) 
                                        : 
                                        ''
                                    }
                                    {
                                        elementData.desc && stripHtml(elementData.desc).length > 200 
                                        ? 
                                        '...' 
                                        : 
                                        ''
                                    }
                                </div>
                                {
                                    (elementData.desc && stripHtml(elementData.desc).length > 200) &&
                                    <>
                                    <div className='g-space-0-5'></div>
                                    <button className='g-button small-btn' onClick={() => setFullDescription(elementData.desc)}>More...</button>
                                    </>
                                }
                            </div>
                        </div>
                        {/* <hr /> */}
                        <div className="g-space-2"></div>
                        {
                            (playerData) &&
                            <>
                                <div className='player-data'>
                                    <div className='card-title'>
                                        Submitted by {playerData.displayName}
                                    </div>
                                    <div className='date'>{format(new Date(currentItem.ts), 'Pp')}</div>
                                </div>
                                <div className="g-space-1"></div>
                                <div className='submission-data'>
                                    <b>{currentItem.type === 'challenge' ? 'Submission:' : 'Note:'}</b>
                                    <div className="g-space-0-5"></div>
                                    <div className='g-list-item'>
                                        {
                                            (currentItem.inputText) &&
                                            <div dangerouslySetInnerHTML={{__html: linkifyHtml(currentItem.inputText, {'target': '_blank'})}}></div>
                                        }
                                        <div className='g-space-1'></div>
                                        {
                                            (currentItem.filePath) &&
                                            <UploadedFileButton path={currentItem.filePath} name={currentItem.fileName} type={currentItem.fileType} />
                                        }
                                    </div>
                                </div>
                                <div className="g-space-2"></div>
                                <div className='response-data'>
                                    <div className='card-title'>Your Response</div>
                                    <div className="g-space-1"></div>
                                    <div className='field with-toggle'>
                                        <label><b>{currentItem.type === 'challenge' ? 'APPROVE SUBMISSION' : 'APPROVE USED ITEM'}</b></label>
                                        <ToggleInput contentKey='status' checked={formData.status === 'approved'} saveData={saveData} />
                                    </div>
                                    <div className="g-space-1"></div>
                                    <div className='field'>
                                        <label>Your comments</label>
                                        <textarea rows='3' onChange={(e) => saveData('comments', e.target.value)} ></textarea>
                                    </div>
                                    {
                                        (formData.status === 'approved') &&
                                        <>
                                            <div className='g-space-1'></div>
                                            <div className='g-list-item form-section'>
                                                <RewardsForm 
                                                    formData={formData} 
                                                    saveData={saveData} 
                                                    gameElements={gameElements} 
                                                    disabled={false} 
                                                    playerId={currentItem.playerId} 
                                                    gameData={gameData}
                                                    type='actionItem' />
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className="g-space-2"></div>
                                {
                                    (loading)
                                    ?
                                    <div className='buttons'>
                                        <div className='g-button'>Cancel</div>
                                        <div className='g-button'><ThreeDotsLoader /></div>
                                    </div>
                                    :
                                    <div className='buttons'>
                                        <div className='g-button' onClick={() => navigate(-1)}>Cancel</div>
                                        <div className='g-button primary' onClick={() => handleSubmit()}>Save Action</div>
                                    </div>
                                }
                            </>
                        }
                        <Modal show={fullDescription} cancel={() => setFullDescription(false)} text={elementData ? `${elementData.name} - Instructions` : null} closeButton={true}>
                            <div className='modal-children'> 
                            {
                                (fullDescription) &&
                                <div dangerouslySetInnerHTML={{__html: linkifyHtml(fullDescription)}}></div>
                            }
                            </div>
                        </Modal>
                    </div>
                }               
            </div>
            <ModalConfirm show={confirmMessage} cancel={() => setConfirmMessage(null)} message={confirmMessage} onApprove={approveRef.current} />
            <ModalAlert show={alertMessage} cancel={() => setAlertMessage(null)} message={alertMessage} />
            <FullPageLoader show={loading} />
        </div>  
    )
}

export default ViewActionItem