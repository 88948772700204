import React, { useEffect } from 'react'
import './limitations.styles.scss';
import ToggleInput from '@/components/SHARED/toggle-input/toggle-input.component';

const ManageContentLimitations = ({ type, saveData, formData, disabled }) => {
    
    useEffect(() => {
        if (!formData) return;
        if (type === 'challenge' && formData.opt_multiple && !formData.opt_multipleTries) {
            saveData('opt_multiple', false);
        }
        if (type === 'challenge' && formData.opt_multipleTriesLimit && !formData.opt_multipleTries) {
            saveData('opt_multipleTriesLimit', null);
        }
        if (type === 'challenge' && formData.opt_multipleLimit && !formData.opt_multiple) {
            saveData('opt_multipleLimit', null);
        }
    }, [formData])

    return (
        <div className={`manage-content-limitations g-list-item form-section ${disabled}`}>
            <h3>Limitations</h3>
            <hr />
            {
                (type === 'challenge') &&
                <>
                <div className='toggle-with-text'>
                    <ToggleInput contentKey="opt_multipleTries" checked={formData.opt_multipleTries} saveData={saveData} />
                    <div className='toggle-text'>
                        <b>Allow Resubmissions if Declined:</b>
                        <div className='meta'>If the player submission is declined, players can resubmit content for this {type} until it is approved.</div>
                    </div>
                </div>
                <div className='g-space-1'></div>
                <div className={`field small ${!formData.opt_multipleTries ? 'disabled' : ''}`}>
                    <label className='no-flex'>
                        How many times can a Player resubmit content for this Challenge?<br />
                        <span className='meta'>(Leave it blank if you want an infinite number available.)</span>
                    </label>
                    <div>
                        <input 
                            className='short-input' 
                            type='number' 
                            value={formData.opt_multipleTriesLimit ?? ''} 
                            onChange={(e) => saveData('opt_multipleTriesLimit', Number(e.target.value))} 
                        />
                    </div>
                </div>
                <div className='g-space-2'></div>
                <div className={`toggle-with-text ${!formData.opt_multipleTries ? 'disabled' : ''}`}>
                    <ToggleInput contentKey="opt_multiple" checked={formData.opt_multiple} saveData={saveData} />
                    {/* <div className='toggle-button'>
                        <input type="checkbox" id="checkbox" checked={formData.opt_multiple ? true : false} onChange={(e) => saveData('opt_multiple', e.target.checked)} />
                    </div> */}
                    <div className='toggle-text'>
                        <b>Allow Multiple Approvals:</b>
                        <div className='meta'>Players can be approved for this {type} multiple times. Requires Resubmissions to be allowed.</div>
                    </div>
                </div>
                <div className="g-space-1"></div>
                <div className={`field small ${!formData.opt_multiple ? 'disabled' : ''}`}>
                    <label className='no-flex'>
                        How many TOTAL times can a Player be approved for this Challenge?<br />
                        <span className='meta'>(Leave it blank if you want an infinite number available.)</span>
                    </label>
                    <div>
                        <input 
                            className='short-input' 
                            type='number' 
                            value={formData.opt_multipleLimit ?? ''} 
                            onChange={(e) => saveData('opt_multipleLimit', Number(e.target.value))} 
                        />
                    </div>
                </div>
                </>
            }
            {
                (type === 'item') &&
                <div>
                    <div className='field small'>
                        <label className='no-flex'>
                            How many of these items can a Player own at one time?<br />
                            <span className='meta'>(Leave it blank if you want an infinite number available.)</span>
                        </label>
                        <div>
                            <input 
                                className='short-input' 
                                type='number' 
                                value={formData.opt_limitInd ?? ''} 
                                onChange={(e) => saveData('opt_limitInd', Number(e.target.value))} 
                            />
                        </div>
                    </div>
                    <div className="g-space-2"></div>
                    <div className='field small'>
                        <label className='no-flex'>
                            How many TOTAL times can a Player earn or purchase this Item?<br />
                            <span className='meta'>(Leave it blank if you want an infinite number available.)</span>
                        </label>
                        <div>
                            <input 
                                className='short-input' 
                                type='number' 
                                value={formData.opt_limitIndTotal ?? ''} 
                                onChange={(e) => saveData('opt_limitIndTotal', Number(e.target.value))} 
                            />
                        </div>
                    </div>
                    <div className="g-space-2"></div>
                    <div className='field small'>
                        <label className='no-flex'>
                            How many of these items can be owned by ANY PLAYER at any point?<br />
                            <span className='meta'>(Leave it blank if you want an infinite number available.)</span>
                        </label>
                        <div>
                            <input 
                                className='short-input' 
                                type='number' 
                                value={formData.opt_limitTotal ?? ''} 
                                onChange={(e) => saveData('opt_limitTotal', Number(e.target.value))} 
                            />
                        </div>
                    </div>
                </div>
            }
            {
                (type === 'secretCode') &&
                <div>
                    <div className='field small'>
                        <label className='no-flex'>
                            How many times can this Secret Code be Redeemed?<br />
                            <span className='meta'>(Leave it blank if you want each player to be able to redeem it once.)</span>
                        </label>
                        <div>
                            <input 
                                className='short-input' 
                                type='number' 
                                value={formData.opt_limitTotal ?? ''} 
                                onChange={(e) => saveData('opt_limitTotal', Number(e.target.value) ?? null)} 
                            />
                        </div>
                    </div>
                </div>
            }
            <div className='g-space-1'></div>
        </div>
    )
}

export default ManageContentLimitations