import React, { useEffect, useState } from 'react'
import './my-challenges-list.styles.scss';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import { format } from 'date-fns';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import RewardsCard from '../rewards-card/rewards-card.component';
import PlayerNameIcon from '../player-name-icon.component';

const MyChallengesList = ({ gameData, playerData, gameElements, playerActivity }) => {
    
    const [ loading, setLoading ] = useState(true);
    const [ sortedList, setSortedList ] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    
    useEffect(() => {
        const timer = setTimeout(() => {
            if (!playerData || !playerActivity) {
                setLoading(false);
            }
        }, 3000)

        return () => {
            if (timer) clearTimeout(timer);
        }
    }, [])

    useEffect(() => {
        console.log(playerActivity);
        // console.log(playerData);
        // console.log(gameElements);
        if (!playerData || !gameElements || !playerActivity) return;
        const challengeActivity = Object.values(playerActivity)
            .sort((a,b) => a.ts < b.ts ? 1 : -1)
            .filter(a => a.type === 'approvedChallenge')
        setSortedList(challengeActivity);
        setLoading(false);
    }, [playerData, gameElements, playerActivity])

    return (
        <div className='completed-challenges-list'>
            <div className='g-card list-card'>
                <BackButton cancel={() => navigate(-1)} />
                <div>
                    <div className='card-title split'>
                        My Completed Challenges
                        {
                            (location.pathname.includes('/manage/')) &&
                            <PlayerNameIcon player={playerData} />
                        }
                    </div>
                    <div className="g-space-1"></div>
                    <hr className='no-margin'/>
                    <div className="g-space-0-5"></div>
                    {
                        (sortedList) &&
                        <div className='summary meta'>
                            <div className='info'>
                                Completed Challenges: 
                                <ImageContainer src='/images/icons/win.png' className='summary-icon' />
                                <b>{sortedList.length.toLocaleString()}</b>
                            </div>
                        </div>
                    }
                </div>
                {
                    (loading)
                    ?
                    <div className='center-col'><ThreeDotsLoader /></div>
                    :
                    (sortedList)
                    ?
                    sortedList.map(c => {
                        return (
                            <div key={c.ts} className='g-list-item challenge-item'>
                                <ImageContainer src={c.icon} className='icon' />
                                <div className='info'>
                                    <div className='title'>
                                        {c.title}
                                    </div>
                                    <div className='meta'>
                                        {c.event}<br />
                                        {format(new Date(c.ts), "Pp")}
                                    </div>
                                </div>
                                {
                                    (c.comments)
                                    ?
                                    <div className='comment'>
                                        Comments:<br /><b>"{c.comments}"</b>
                                    </div>
                                    :
                                    (c.info)
                                    ?
                                    <div className='comment'>
                                        Info:<br /><b>{c.info}</b>
                                    </div>
                                    :
                                    null
                                }
                                <div className='buttons'>
                                    {
                                        (location.pathname.includes('/play/')) &&
                                        <button 
                                            className='g-button med-btn'
                                            onClick={() => navigate(`/play/${gameData.path}/challenges/${c.elementPath}`)}
                                        >
                                            <FontAwesomeIcon icon={faMagnifyingGlass} />
                                        </button>
                                    }
                                </div>
                                
                                {
                                    (c.data && Object.values(c.data).length > 0) &&
                                    <div className='rewards'>
                                        <b>My Rewards</b>
                                        <div className='g-space-0-5'></div>
                                        {
                                            Object.values(c.data).map((r, index) => (
                                                <div 
                                                    key={r.ts + '_' + index}
                                                    className='reward-card' >
                                                    <RewardsCard reward={r} />
                                                </div>
                                            )) 
                                        }
                                    </div>
                                }
                            </div>
                        )
                    })
                    : 
                    <h4 className='center-col'>
                        No earnings yet...
                    </h4>
                }
            </div>
        </div>
    )
}

export default MyChallengesList