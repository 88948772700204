import React, { useEffect, useRef, useState } from 'react';
import './store-contribution-card.styles.scss';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';

import { useDispatch, useSelector } from 'react-redux';
import { setShoppingCart } from '@/state/slices/userSlice';
import ProgressBar from '@/components/SHARED/progress-bar/progress-bar.component';
import { checkElementAccess } from '@/utils/checkElementAccess';
import { format } from 'date-fns';

const StoreContributionCard = ({ 
    item, 
    playerData,
    gameStats,
    gameElements,
    teamMembers
}) => {

    const shoppingCart = useSelector(state => state.userData.shoppingCart);
    const [ progress, setProgress ] = useState(0);
    const [ contributions, setContributions ] = useState(0);
    const [ access, setAccess ] = useState(null);
    const [ remainingInd, setRemainingInd ] = useState(null);
    const [ remainingTotal, setRemainingTotal ] = useState(null);
    const contributionRef = useRef();
    const intervalRef = useRef();
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            if (intervalRef.current) clearInterval(intervalRef.current);
        }
    }, [])

    useEffect(() => {
        if (!gameElements || !item || !playerData) return;
        checkAccess();
        if (intervalRef.current) clearInterval(intervalRef.current);
        intervalRef.current = setInterval(() => {
            checkAccess();
        }, 10000)
    }, [gameElements, item, playerData])

    function checkAccess() {
        const accessResponse = checkElementAccess({
            'playerData': playerData,
            'gameElements': gameElements,
            'elementId': item.id,
            'gameStats': gameStats
        });
        if (accessResponse) {
            console.log(accessResponse);
            let accessArr = [...accessResponse.arr]
            if (!isNaN(accessResponse.remainingInd) && accessResponse.remainingInd === 0) {
                accessArr.push('You are not able to own any more of this item.')
            } else if (!isNaN(accessResponse.remainingInd)) {
                setRemainingInd(accessResponse.remainingInd);
            }
            if (!isNaN(accessResponse.remainingTotal) && accessResponse.remainingTotal === 0) {
                accessArr.push('There are no more of these items available.')
            } else if (!isNaN(accessResponse.remainingTotal)) {
                setRemainingTotal(accessResponse.remainingTotal);
            }
            setAccess(accessArr)
        };
    }


    useEffect(() => {
        if (!item || !gameStats) return;
        let contCount = gameStats[item.id + '_contributions'] || 0;
        setContributions(contCount);
        setProgress((contCount/item.opt_contributionGoal) * 100)
    }, [gameStats, item])

    function changeContribution(contribution) {
        console.log(contribution);
        if (contribution < 0) return;
        if (contribution === 0) {
            let tempData = {...shoppingCart};
            delete tempData[item.id];
            dispatch(setShoppingCart({...tempData}));
            return;
        }
        contributionRef.current = contribution;
        addItemToCart();
    }

    function addItemToCart() {
        console.log(contributionRef.current);
        let tempCart = {...shoppingCart};
        const obj = {
            "quantity": 1,
            "id": item.id,
            "cost": contributionRef.current,
        }
        console.log(obj);
        tempCart[item.id] = obj;
        console.log(tempCart[item.id]);
        dispatch(setShoppingCart({...tempCart}));
        console.log(tempCart);
    }

    return (
        <div className={`store-contribution-card g-list-item ${
            progress >= 100 ? 'completed' : ''
        }`}>
            <div className='store-contribution-grid'>
                <div className='store-contribution-icon-div'>
                    <ImageContainer src={item.icon} className='item-icon' />
                </div>
                <div className='store-contribution-text-div'>
                    {
                        (progress >= 100) &&
                        <ImageContainer 
                            src='/images/icons/completed-overlay-green.png'
                            className='complete-overlay' />
                    }
                    <div className='item-name'>{item.name}</div>
                    <div className='item-desc'>{item.desc}</div>
                    <div className="g-space-0-5"></div>
                    <div className='contributions'>
                        Contributions: {contributions.toLocaleString()} of {item.opt_contributionGoal.toLocaleString()}
                    </div>
                    <div className='item-progress'>
                        <ProgressBar progressValue={progress} itemId={item.id} />
                    </div>
                    
                    {
                        (item.opt_dateClose && item.opt_dateClose > new Date().getTime()) &&
                        <div className='meta'>
                            Element closes on {format(new Date(item.opt_dateClose), "Pp")}
                        </div>
                    }
                    <div className="g-space-0-5"></div>
                    {
                        (access && access.length > 0)
                        ?
                        <div className='access-list'>
                            {
                                access.map(a => (
                                    <div
                                        key={a} 
                                        className='access-item meta'
                                    >
                                        {a}
                                    </div>
                                ))
                            }
                        </div>
                        :
                        (access && progress < 100)
                        ?
                        <>
                        <div className='item-desc'>Include your total contribution below.</div>
                        <div className='buttons'>
                            <ImageContainer 
                                src="/images/icons/coin.png" 
                                className="coin-icon" 
                            />
                            <div className='field'>
                                <input
                                    className='short-input'
                                    type='number'
                                    value={shoppingCart && shoppingCart[item.id] ? shoppingCart[item.id].cost : 0}
                                    onChange={(e) => changeContribution(Math.round(e.target.value))}
                                />
                            </div>
                        </div>
                        </>
                        :
                        <div></div>
                    }
                </div>
            </div>
        </div>
    )
}

export default StoreContributionCard