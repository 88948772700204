import React, { useEffect, useState } from 'react';
import './lottery-list.styles.scss';
import { useNavigate } from 'react-router-dom';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const LotteryList = ({
    gameData,
    gameElements,
    gameStats,
    playerList
}) => {

    const [ lotteriesToDisplay, setLotteriesToDisplay ] = useState([]);
    const [ playerCounts, setPlayerCounts ] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        if (!gameElements) return;
        const lotteryList = Object.values(gameElements)
        .filter(e => 
            e.opt_itemType === 'lottery' &&
            e.status === 'active'
        )
        setLotteriesToDisplay(
            [...lotteryList]
            .sort((a,b) => 
                a.name.toLowerCase() > b.name.toLowerCase() 
                ? 1 : -1
            )
        );
    }, [gameElements, gameStats])

    useEffect(() => {
        if (!lotteriesToDisplay || !playerList) return;
        let eligiblePlayers = {}
        for (let lottery of lotteriesToDisplay) {
            const playersWithTickets = Object.values(playerList)
            .filter(p => 
                p.items && 
                p.items[lottery.id] &&
                p.items[lottery.id].count > 0
            )
            eligiblePlayers[lottery.id] = playersWithTickets.map(pl => {
                return {
                    'name': pl.displayName,
                    'count': pl.items[lottery.id].count
                }
            })
            setPlayerCounts({...eligiblePlayers});
        }
    }, [lotteriesToDisplay, playerList])

    return (
        <div className='lottery-list'>
            {
                (gameStats && lotteriesToDisplay.length > 0)
                ?
                lotteriesToDisplay.map(l => (
                    <div 
                        key={l.id}
                        className='g-list-item lottery-item'>
                        <div className='lottery-icon-div'>
                            <ImageContainer
                                src={l.icon}
                                className='lottery-icon' />
                        </div>
                        <div className='lottery-text-div'>
                            <div className='lottery-name'>
                                {l.name}
                            </div>
                            {
                                (l.desc) &&
                                <div>{l.desc}</div>
                            }
                            <div>
                                Tickets Collected: <b>{
                                    gameStats && 
                                    gameStats[l.id]
                                    ? gameStats[l.id]
                                    : 0
                                }</b>
                            </div>
                            <div>
                                Players Included: <b>{
                                    playerCounts[l.id] ? playerCounts[l.id].length : 0
                                }</b>
                            </div>
                            <div>
                                Player with most tickets: {
                                    playerCounts[l.id]
                                    ?
                                    <b>
                                    {Object.values(playerCounts[l.id])
                                    .sort((a,b) => a.count < b.count ? 1 : -1)[0].name} (
                                        {
                                        Object.values(playerCounts[l.id])
                                        .sort((a,b) => a.count < b.count ? 1 : -1)[0].count
                                        }   
                                    )
                                    </b>
                                    :
                                    'none'
                                }
                            </div>
                        </div>
                        <div className='buttons'>
                            <button 
                                className='g-button'
                                onClick={() => navigate(l.path)}>
                                Manage
                                <FontAwesomeIcon icon={faArrowRight} />
                            </button>
                        </div>
                    </div>
                ))
                :
                <div className='center-col'>
                    <ThreeDotsLoader />
                </div>
            }
        </div>
    )
}

export default LotteryList